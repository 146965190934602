import { createSlice } from "@reduxjs/toolkit";
import { fetchFAQ, fetchHowItWorks } from "../actions";

const initialState = {
  productSharing: [],
  faq: [],
};

const ProductSharingSlice = createSlice({
  name: "ProductSharingData",
  initialState,
  reducers: {
    resetProductShare(state) {
      state.productSharing = null;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(fetchHowItWorks.fulfilled, (state, { payload }) => {
      state.productSharing = payload;
    });
  },

  extraReducers: (builder) => {
    builder.addCase(fetchFAQ.fulfilled, (state, { payload }) => {
      state.faq = payload;
    });
  },
});

const { reducer } = ProductSharingSlice;

export default reducer;
