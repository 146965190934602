import baseAxios from "../core/api/axios/baseAxios";

class MasterDataService {
  static async addPhoneNumber(data) {
    const res = await baseAxios.post("master-data/addPhonenumber", data);

    return res?.data || res;
  }

  static async verifyPhoneNumber(data) {
    const res = await baseAxios.post("master-data/verifyPhonenumber", data);

    return res?.data || res;
  }

  static async fetchCountryInfo(data) {
    const res = await baseAxios.post("master-data/fetchCountries", data);

    return res?.data || res;
  }

  static async addDelivery(data) {
    const res = await baseAxios.post("master-data/addDelivery", data);

    return res?.data || res;
  }

  static async getDelivery(data) {
    const res = await baseAxios.post("master-data/getDelivery", data);

    return res?.data || res;
  }

  static async addBvn(data) {
    const res = await baseAxios.post("master-data/addBvn", data);

    return res?.data || res;
  }

  static async getBvn(data) {
    const res = await baseAxios.get("master-data/getBvn");

    return res?.data || res;
  }

  static async addNin(data) {
    const res = await baseAxios.post("master-data/addNin", data);

    return res?.data || res;
  }

  static async getNin(data) {
    const res = await baseAxios.post("master-data/getNin", data);

    return res?.data || res;
  }

  static async todoListStatus(data) {
    const res = await baseAxios.post("master-data/todoListStatus", data);

    return res?.data || res;
  }

  static async getUserProfile(data) {
    const res = await baseAxios.post("master-data/userProfileGet", data);

    return res?.data || res;
  }

  static async getUserInfo(data) {
    const res = await baseAxios.post("master-data/userInfoGet", data);

    return res?.data || res;
  }

  static async getUserProfileSet(data) {
    const res = await baseAxios.post("master-data/userProfile", data);

    return res?.data || res;
  }

  static async setTransactionPin(data) {
    const res = await baseAxios.post("master-data/transactionPin", data);

    return res?.data || res;
  }

  static async setUpdatePin(data) {
    const res = await baseAxios.post("master-data/update-password", data);

    return res?.data || res;
  }

  static async verifyTransactionPin(data) {
    const res = await baseAxios.post("master-data/transactionPin/verify", data);

    return res?.data || res;
  }

  static async getDocumentFileId(data) {
    const res = await baseAxios.post("master-data/documentFileId", data);

    return res?.data || res;
  }

  static async getDocumentUpload(data) {
    const res = await baseAxios.post("master-data/documentUpload", data);

    return res?.data || res;
  }

  static async documentUploadGet(data) {
    const res = await baseAxios.post("master-data/documentUploadGet", data);

    return res?.data || res;
  }

  static async getPlans(data) {
    const res = await baseAxios.post("master-data/plans", data);

    return res?.data || res;
  }

  static async getPlan(data) {
    const res = await baseAxios.post("master-data/plan", data);

    return res?.data || res;
  }

  static async getProducts(data) {
    const res = await baseAxios.post("master-data/products", data);

    return res?.data || res;
  }

  static async getProductPlans(data) {
    const res = await baseAxios.post("master-data/productsPlan", data);

    return res?.data || res;
  }

  static async getCartProductPlan(data) {
    const res = await baseAxios.post("master-data/cartProductPlan", data);

    return res?.data || res;
  }

  static async getBillerInfo() {
    const res = await baseAxios.post("master-data/billerInfo");

    return res?.data || res;
  }

  static async activeCustomerPlans(id) {
    const res = await baseAxios.get(`master-data/active/${id}`);

    return res?.data || res;
  }

  static async deliveryData(id) {
    const res = await baseAxios.get(`master-data/deliveryData/${id}`);

    return res?.data || res;
  }

  static async cancelCustomerPlan(id) {
    const res = await baseAxios.post(`master-data/cancelPlan/${id}`);

    return res?.data || res;
  }

  static async reactivateCustomerPlan(id) {
    const res = await baseAxios.post(`master-data/reactivatePlan/${id}`);

    return res?.data || res;
  }

  static async upgradeCustomerPlan(id, data) {
    const res = await baseAxios.post(`master-data/upgradePlan/${id}`, data);

    return res?.data || res;
  }

  static async downgradeCustomerPlan(id, data) {
    const res = await baseAxios.post(`master-data/downgradePlan/${id}`, data);

    return res?.data || res;
  }

  static async bvnVerify(data) {
    const res = await baseAxios.post("auth/bvnVerify", data);

    return res?.data || res;
  }

  static async shoppingRating(data) {
    const res = await baseAxios.post("master-data/shopping/rating", data);

    return res?.data || res;
  }

  static async shoppingRatingSubscription(data) {
    const res = await baseAxios.post(
      "master-data/shopping/rating/subscription",
      data
    );

    return res?.data || res;
  }

  static async shippingRating(data) {
    const res = await baseAxios.post("master-data/shipping/rating", data);

    return res?.data || res;
  }

  static async shoppingVasPaybillsRating(data) {
    const res = await baseAxios.post(
      "master-data/shopping/vas/paybills/rating",
      data
    );

    return res?.data || res;
  }

  static async shoppingVasRating(data) {
    const res = await baseAxios.post("master-data/shopping/vas/rating", data);

    return res?.data || res;
  }

  static async corporateIdUpload(data) {
    const res = await baseAxios.post("master-data/corporate_id/upload", data);

    return res?.data || res;
  }

  static async corporateIdDelete(data) {
    const res = await baseAxios.delete(
      `master-data/corporate_id/delete/${data}`
    );

    return res?.data || res;
  }

  static async handleRecommendationsFetch(customerId, data) {
    const res = await baseAxios.post(
      `master-data/${customerId}/recommendations`,
      data
    );

    return res?.data || res;
  }

  static async handleAdsHide(customerId, adRefNo) {
    const res = await baseAxios.get(
      `master-data/${customerId}/hide_ads/${adRefNo}`
    );

    return res?.data || res;
  }

  static async handleAdsShow(customerId, adRefNo) {
    const res = await baseAxios.get(
      `master-data/${customerId}/show_ads/${adRefNo}`
    );

    return res?.data || res;
  }

  static async handleShowSettingsAds(customerId) {
    const res = await baseAxios.get(
      `master-data/${customerId}/setting_ads_info`
    );

    return res?.data || res;
  }

  static async handleToggleSettingsAds(customerId, visibility_id) {
    const res = await baseAxios.get(
      `master-data/${customerId}/setting_ads_info/${visibility_id}`
    );

    return res?.data || res;
  }

  static async handleFeatureInfo() {
    const res = await baseAxios.get("master-data/product_feature_info");

    return res?.data || res;
  }

  static async handleGrocedyForAll(customer_id) {
    const res = await baseAxios.get(`master-data/grocedy_for_all/${customer_id}`);

    return res?.data || res;
  }
}

export default MasterDataService;