import React, { Fragment, useEffect, useState } from "react";
import { BiArrowBack } from "react-icons/bi";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchGrocedyForAll, productPurchaseSet } from "../../store/actions";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import Addons from "../Addons";
import IsLoadingOne from "../IsLoading_One";
// import { Store } from "react-notifications-component";
// import { notification } from "../../core/helpers";
import Navbar from "../Navbar";
import { Popover, Transition } from "@headlessui/react";
import AddonPopover from "../AddonPopover";
import { BsInfoLg } from "react-icons/bs";
import Modal from "../Ui";
import { v4 as uuidv4 } from "uuid";
import Tippy from "@tippy.js/react";
import "tippy.js/dist/tippy.css";
import SignedInNavbarImproved from "../SignedInNavbarImproved";
import { toast } from "react-hot-toast";
import { authService } from "../../services/authService";

function PlansCartComplete() {
  const copyCartObj = JSON.parse(localStorage.getItem("cart"));
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [fetchedPlan, setFetchedPlan] = useState([]);
  const [planQuantity, setPlanQuantity] = useState(1);
  const [monthNumber, setMonthNumber] = useState(12);
  const [monthlyRecurring, setMonthlyRecurring] = useState("no");
  const [tableArray, setTableArray] = useState([]);
  const [usdRate, setUsdRate] = useState("");
  const [selectedPlanImages, setSelectedPlanImages] = useState([]);
  const [selectedAddons, setSelectedAddons] = useState([]);
  const [subtotalAddon, setSubTotalAddonPrice] = useState(0);
  const [isViewing, setIsViewing] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [selectedModal, setSelectedModal] = useState("");
  const [offlineCart, setOfflineCart] = useState(copyCartObj || []);
  const [isInserted, setIsInserted] = useState(false);
  const [fetchedProducts, setFectchedProducts] = useState({});
  const [selected_plan, setSelected_plan] = useState("");

  const { grocedyForAll } = useSelector((state) => state.masterData);
  const { rate } = useSelector((state) => state.general);
  const { loading } = useSelector((state) => state.error);

  // const selected_plan = "1805109000000067126";
  const customer_id = authService.getCurrentUserCustomerId();
  const userEmail = authService.getCurrentUserEmail();
  const token = authService.getToken();
  const customerId = authService.getCurrentUserCustomerId();

  useEffect(() => {
    if (!grocedyForAll) {
      dispatch(fetchGrocedyForAll(customerId ? customerId : "0"))
        .unwrap()
        .catch((err) => {
          return err;
        });
    }

    if (grocedyForAll) {
      setFectchedProducts(grocedyForAll);
    }
  }, [grocedyForAll, navigate, customerId]);

  useEffect(() => {
    if (fetchedProducts) {
      const selectedPlan =
        fetchedProducts?.product_info?.length &&
        fetchedProducts?.product_info?.filter(
          (sp) => sp.name === "Food Baskets"
        );

      setSelected_plan(selectedPlan?.length ? selectedPlan[0]?.product_id : "");
    }
  }, [fetchedProducts]);

  useEffect(() => {
    if (
      fetchedProducts &&
      params.id &&
      fetchedProducts?.plan_info?.length &&
      fetchedProducts?.Plan_Image?.length
    ) {
      const filteredPlan =
        fetchedProducts?.plan_info?.length &&
        fetchedProducts?.plan_info?.filter(
          (plan) => plan?.plan_code === params.id
        );

      setFetchedPlan(filteredPlan);

      const filteredImages =
        fetchedProducts?.Plan_Image?.length &&
        fetchedProducts?.Plan_Image?.filter(
          (image) => image?.zoho_plan_id === filteredPlan[0]?.plan_id
        );

      setSelectedPlanImages(filteredImages);

      const filteredAddons =
        fetchedProducts?.addon_info?.length &&
        fetchedProducts?.addon_info?.filter(
          (addon) => addon?.product_id === selected_plan
        );

      setSelectedAddons(filteredAddons);
    }
  }, [dispatch, params.id, fetchedProducts, selected_plan]);

  useEffect(() => {
    if (rate.length) {
      setUsdRate(rate[0]?.exchange_rate);
    }
  }, [rate]);
  // Get the total price
  useEffect(() => {
    if (tableArray.length) {
      const totalPrice = tableArray?.map(
        (tArray) => +tArray.addonQuantity * +tArray.selectAddon[0].price
      );

      const subtotalPrice = totalPrice?.reduce((a, b) => a + b);

      setSubTotalAddonPrice(subtotalPrice);
    }

    if (!tableArray.length) {
      setSubTotalAddonPrice(0);
    }
  }, [tableArray]);

  // Update the cart locally
  useEffect(() => {
    localStorage.setItem("cart", JSON.stringify(offlineCart));
  }, [offlineCart]);

  useEffect(() => {
    if (isInserted) {
      setIsInserted(false);
      navigate("/cart");
    }
  }, [isInserted, navigate]);

  // The count setter
  if (planQuantity < 1) {
    setPlanQuantity(1);
  }
  if (monthNumber < 1) {
    setMonthNumber(1);
  }

  const handleMonthlyRecurring = (event) => {
    if (event.target.value === "no") {
      setShowModal(true);
      setSelectedModal("recurr");
      setSelectedOption(event.target.value);
      setMonthlyRecurring(event.target.value);
      return;
    }
    if (event.target.value === "yes") {
      setMonthlyRecurring(event.target.value);
      setSelectedOption(event.target.value);
    }
  };

  const switchToMonthly = () => {
    // const isChnging = "allow";
    setSelectedOption("yes");
    setMonthlyRecurring("yes");
  };

  const SubtotalPlanPrice =
    fetchedPlan && fetchedPlan?.length
      ? fetchedPlan[0]?.recurring_price * planQuantity
      : 0;

  const items =
    fetchedPlan &&
    fetchedPlan?.length &&
    fetchedPlan[0]?.store_markup_description.split("|");

  const addAddon = (data) => {
    const addonPrice =
      data &&
      data?.selectedAddonPlan?.length &&
      +data?.selectedAddonPlan[0]?.price * data?.addonQuantity;

    const addonData = {
      selectAddon:
        data && data?.selectedAddonPlan?.length && data?.selectedAddonPlan,
      selectedAddonPrice: addonPrice,
      addonQuantity: data && data?.addonQuantity,
      addonRecurring: data?.monthlyRecurring,
      addonMonths: data?.addonMonths,
      addonId: data?.addonId,
    };

    // Check if it has an id, which means i am editing..
    if (data?.id) {
      const updatedArray = [...tableArray];
      const foundIndex = updatedArray.findIndex(
        (updatArr) => updatArr?.addonId === data?.id
      );
      updatedArray[foundIndex] = addonData;

      return setTableArray(updatedArray);
    }

    // Check if i have added that before
    const checkExists = tableArray.some(
      (el) =>
        el?.selectAddon[0]?.addon_code ===
        data?.selectedAddonPlan[0]?.addon_code
    );

    if (checkExists) return;

    setTableArray((curr) => [...curr, addonData]);
  };

  // Deleting an Addon
  const handleDelete = (id) => {
    const newArray = tableArray.filter((tArr) => tArr?.addonId !== id);
    setTableArray(newArray);
  };

  const handleOnClose = () => {
    setShowModal(false);
    setSelectedModal("");
  };

  const continueToCart = (e) => {
    e.preventDefault();

    try {
      const data = {
        itemId: uuidv4(),
        sql_ref_no: "25",
        user_email: userEmail,
        JSON_String: {
          customer_id: customer_id?.toString(),
          plan: {
            plan_name:
              fetchedPlan && fetchedPlan?.length && fetchedPlan[0]?.name,
            plan_quantity: planQuantity,
            plan_price:
              fetchedPlan &&
              fetchedPlan?.length &&
              fetchedPlan[0]?.recurring_price,
            plan_code:
              fetchedPlan && fetchedPlan?.length && fetchedPlan[0]?.plan_code,
            isRecurring: monthlyRecurring,
            product_id:
              fetchedPlan && fetchedPlan?.length && fetchedPlan[0]?.product_id,
            interval: "1",
            interval_unit: "months",
            number_of_months: monthlyRecurring === "yes" ? monthNumber : "1",
          },
          addons:
            tableArray &&
            tableArray?.length &&
            tableArray?.map((tA) => {
              const addonsContent = {
                addon_code: tA?.selectAddon[0]?.addon_code,
                name: tA?.selectAddon[0]?.name,

                price_brackets: {
                  quantity: tA?.addonQuantity,
                  isRecurring: tA?.addonRecurring,
                  price: tA?.selectedAddonPrice,
                  totalPrice: +tA?.addonQuantity * +tA?.selectedAddonPrice,
                  number_of_months: tA?.addonMonths,
                },
              };

              return addonsContent;
            }),
        },
      };

      if (!token) {
        if (offlineCart?.length) {
          setOfflineCart((curr) => [...curr, data]);
          setIsInserted(true);
          return;
        }
        if (!offlineCart?.length) {
          localStorage.setItem("cart", JSON.stringify([data]));
          return navigate("/cart");
        }
      }

      dispatch(productPurchaseSet(data))
        .unwrap()
        .then((res) => {
          if (res?.status === 403) {
            toast.error("Something Went Wrong!");
          }
          setPlanQuantity(0);
          setMonthlyRecurring("");
          setMonthNumber(0);
          toast.success("Plan added to cart!");
          navigate("/cart");
        });
    } catch (e) {
      setPlanQuantity(0);
      setMonthlyRecurring("");
      setMonthNumber(0);

      toast.error("Something Went Wrong!");
      return e;
    }
  };

  const handleCurrentView = (id) => {
    setIsViewing(id);
  };

  return (
    <>
      {loading ? (
        <IsLoadingOne message="loading..." />
      ) : (
        <div>
          {token ? <SignedInNavbarImproved /> : <Navbar />}
          <div className="px-4 py-16 sm:px-8 md:px-16">
            <div className="flex items-center space-x-3">
              <button>
                <BiArrowBack className="h-8 w-8 text-gravel-500 sm:hidden" />
              </button>
              <div className="text-3xl font-bold text-gravel-500 sm:ml-0 sm:text-4xl">
                {fetchedPlan && fetchedPlan?.length && fetchedPlan[0]?.name}
              </div>
            </div>
            <div className="mt-8 grid gap-4 grid-auto-fit-sm">
              <div className="rounded-xl border-[1px] border-gray-400 shadow-md shadow-gray-500">
                {selectedPlanImages && selectedPlanImages.length ? (
                  <img
                    src={selectedPlanImages[isViewing]?.plan_image_url}
                    className="row-span-2 aspect-square h-auto w-auto rounded-lg object-cover sm:col-span-2"
                    alt=""
                  />
                ) : (
                  ""
                )}
              </div>
              <div className="grid gap-2 grid-auto-fit-[6rem] md:grid-auto-fit-[9rem]">
                {selectedPlanImages && selectedPlanImages.length
                  ? selectedPlanImages.map((img, idx) => (
                      <img
                        key={idx}
                        src={img.plan_image_url}
                        className="aspect-square h-auto w-auto cursor-pointer rounded-lg object-cover"
                        alt=""
                        onClick={() => handleCurrentView(idx)}
                      />
                    ))
                  : ""}
              </div>
            </div>
            <div className="mt-4 lg:grid lg:grid-cols-3">
              <div className="lg:col-span-1">
                <div className="text-font mt-8 text-2xl font-bold tracking-wider">
                  {fetchedPlan && fetchedPlan?.length
                    ? usdRate.length
                      ? `$${Number(
                          +fetchedPlan[0]?.recurring_price *
                            +rate[0].exchange_rate
                        ).toFixed(2)}/month`
                      : `₦${Number(
                          fetchedPlan[0]?.recurring_price
                        ).toLocaleString()}/month`
                    : ""}
                </div>
                <div className="mt-4">
                  <span className="font-medium text-gray-500">
                    {fetchedPlan && fetchedPlan?.length
                      ? fetchedPlan[0]?.description
                      : ""}
                  </span>
                  <span className="font-bold">
                    (Quantity and <br />
                    price are subject to current market rates <br />
                    and may change)
                  </span>
                </div>
                <div>
                  <div className="text-font mt-4 text-lg font-bold">
                    Content
                  </div>
                  <div className="mt-1 text-sm font-semibold text-gravel-500">
                    {items &&
                      items?.length &&
                      items?.map((item, idx) => (
                        <div
                          key={idx}
                          className="mb-2 w-full rounded-lg border-[1px] border-gray-400 px-2 py-2 sm:w-80 "
                        >
                          {item}
                        </div>
                      ))}
                  </div>
                </div>
              </div>
              <div className="mt-16 whitespace-nowrap lg:col-span-2 lg:pl-12">
                <form>
                  <div className="font-semibold text-gray-700">
                    Plan Information
                  </div>
                  <div className="rounded-md border-[0.5px] border-gray-400 px-3 py-10 xsm:px-6 sm:w-[440px] sm:px-12">
                    <div className="text-xs font-semibold text-gray-600 sm:text-sm">
                      {`How many units of ${
                        fetchedPlan &&
                        fetchedPlan?.length &&
                        fetchedPlan[0]?.name
                      }s do you want?`}
                    </div>
                    <div className="mt-1 flex justify-start">
                      <div className="flex items-center space-x-8 rounded-md border-[0.5px] border-gray-400 bg-gray-50 px-3 py-1 font-semibold text-gray-500 shadow-sm">
                        <div
                          onClick={() => setPlanQuantity((prev) => prev - 1)}
                          className="cursor-pointer rounded-full p-1 hover:bg-gray-200"
                        >
                          <AiOutlineMinus className="h-5 w-5" />
                        </div>
                        <div className="w-8 text-center font-bold text-gray-700">
                          {planQuantity}
                        </div>
                        <div
                          onClick={() => setPlanQuantity((prev) => prev + 1)}
                          className="cursor-pointer rounded-full p-1 hover:bg-gray-200"
                        >
                          <AiOutlinePlus className="h-5 w-5" />
                        </div>
                      </div>
                    </div>
                    <div className="mt-6">
                      <label
                        htmlFor="monthlyRecuring"
                        className="text-xs font-semibold text-gray-600 sm:text-sm"
                      >
                        Do you want your plan to recur monthly?
                      </label>
                      <div className="relative mt-1 flex items-center justify-end rounded-md">
                        <div className="flex-grow">
                          <select
                            onChange={handleMonthlyRecurring}
                            type="text"
                            // value={selectedOption}
                            className="block w-full cursor-pointer rounded-md border-gray-300 bg-gray-50 pl-4 pr-10 text-gray-500 shadow-sm focus:border-olivine-500 focus:ring-olivine-500"
                          >
                            <option
                              key={1}
                              selected={selectedOption === ""}
                              value=""
                            >
                              Select
                            </option>
                            <option
                              key={2}
                              selected={selectedOption === "yes"}
                              value="yes"
                            >
                              Yes
                            </option>
                            <option
                              key={3}
                              selected={selectedOption === "no"}
                              value="no"
                            >
                              No
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                    {monthlyRecurring === "yes" ? (
                      <div className="mt-6">
                        <div className="text-sm font-semibold text-gray-600">
                          Number of months
                        </div>
                        <div className="mt-1 flex justify-start">
                          <div className="flex items-center space-x-8 rounded-md border-[0.5px] border-gray-400 bg-gray-50 px-3 py-1 font-semibold text-gray-500 shadow-sm">
                            <div
                              onClick={() =>
                                setMonthNumber((prev) => {
                                  if (prev === 0) return 0;
                                  return prev - 1;
                                })
                              }
                              className="cursor-pointer rounded-full p-1 hover:bg-gray-200"
                            >
                              <AiOutlineMinus className="h-5 w-5" />
                            </div>
                            <div className="w-8 text-center font-bold text-gray-700">
                              {monthNumber}
                            </div>
                            <div
                              onClick={() => setMonthNumber((prev) => prev + 1)}
                              className="cursor-pointer rounded-full p-1 hover:bg-gray-200"
                            >
                              <AiOutlinePlus className="h-5 w-5" />
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                  {selectedAddons && selectedAddons?.length ? (
                    <div className="mt-6 flex items-center space-x-3">
                      <Popover>
                        {({ open }) => (
                          <>
                            <Popover.Button
                              className={`
                                        ${open ? "" : "text-opacity-90"}
                                        focus:text-olivine-700 focus:outline-none`}
                            >
                              <div className="relative flex w-full transform cursor-pointer items-center space-x-3 rounded-md border-[1px] border-olivine-500 bg-white p-8 px-6 py-2.5 text-sm font-semibold text-olivine-500 transition duration-300 hover:bg-olivine-100 hover:text-olivine-600 focus:outline-none focus:ring-[2px] focus:ring-olivine-500 focus:ring-opacity-60 focus:ring-offset-1 active:bg-olivine-100">
                                <div>
                                  <AiOutlinePlus className="h-5 w-5" />
                                </div>
                                <div>Add add-ons</div>
                              </div>
                            </Popover.Button>
                            <Popover.Overlay className="fixed inset-0 bg-black opacity-30" />
                            <Transition
                              as={Fragment}
                              enter="transition ease-out duration-200"
                              enterFrom="opacity-0 translate-y-1"
                              enterTo="opacity-100 translate-y-0"
                              leave="transition ease-in duration-150"
                              leaveFrom="opacity-100 translate-y-0"
                              leaveTo="opacity-0 translate-y-1"
                            >
                              <Popover.Panel className="absolute inset-x-0 top-[700px] z-30 mx-auto max-w-md px-2 xsm:px-4">
                                {({ close }) => (
                                  <AddonPopover
                                    dataPull={addAddon}
                                    addons={selectedAddons}
                                    closer={close}
                                  />
                                )}
                              </Popover.Panel>
                            </Transition>
                          </>
                        )}
                      </Popover>
                      {/* <button className="p-1 rounded-full bg-gray-600">
                      <BsInfoLg className="h-3 w-3 text-white" />
                    </button> */}
                      <Tippy
                        content="Do you know you can add-on one or more items to your plan?
                              For example, you can add more beans, rice, sphagetti etc. 
                              Click add-on to select the other items you need and continue"
                        className="pb-1 pl-4"
                      >
                        <div className="rounded-full bg-gray-600 p-1">
                          <BsInfoLg className="h-2 w-2 text-white" />
                        </div>
                      </Tippy>
                    </div>
                  ) : null}

                  {tableArray && tableArray?.length > 0 && (
                    <>
                      <div className="mt-16 font-semibold text-gray-700">
                        Add-ons Information
                      </div>
                      <div className="md:pl- mt-1 border-[0.5px] border-gray-400 px-2 sm:px-8 md:px-16 lg:px-16">
                        <div className="mt-12 grid grid-cols-9 text-xs font-bold text-gray-500 sm:grid-cols-12 sm:text-sm">
                          <div className="col-span-3 sm:col-span-6">
                            <div className="grid">
                              <div className="">PRODUCT</div>
                            </div>
                          </div>
                          <div className="col-span-2 flex justify-center sm:col-span-2">
                            <div className="grid justify-end sm:justify-center">
                              <div className="mx-auto">
                                <span className="xsm:hidden">QTY</span>
                                <span className="hidden xsm:inline">
                                  QUANTITY
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="col-span-2 sm:col-span-2 sm:flex sm:justify-end">
                            <div className="grid justify-end">
                              <div className="sm:mx-auto">TOTAL</div>
                            </div>
                          </div>
                          <div className="col-span-2 sm:col-span-2"></div>
                        </div>
                        <hr className="mb-4 mt-2 border-[0.5px] border-gray-300" />
                        <div className="mb-12">
                          {tableArray &&
                            tableArray?.length > 0 &&
                            tableArray?.map((tA, idx) => (
                              <Addons
                                key={idx}
                                selectedId={tA?.addonId}
                                img={tA?.selectAddon[0]?.addon_image_url}
                                addonName={tA?.selectAddon[0]?.name}
                                rate={tA?.selectAddon[0]?.price}
                                quantity={tA?.addonQuantity}
                                amount={tA?.selectAddon[0]?.price}
                                deleter={handleDelete}
                                exchangeRate={+rate[0]?.exchange_rate}
                                addons={fetchedPlan?.addons}
                                noMonths={tA?.addonMonths}
                                isRecurring={tA?.addonRecurring}
                                dataPull={addAddon}
                              />
                            ))}
                        </div>
                      </div>
                    </>
                  )}

                  <div className="mt-8 flex justify-center text-right sm:justify-end sm:pr-8">
                    <div>
                      <div className="flex items-center justify-end space-x-2 pr-4">
                        <div className="space-y-2 font-semibold text-gray-600">
                          <div className="text-xs sm:text-sm">
                            Sub total Plan Price:
                          </div>
                          <div className="text-xs sm:text-sm">
                            Sub Add-on Price:
                          </div>
                          <div className="mt-4 text-base font-bold sm:text-lg">
                            Total Price:
                          </div>
                        </div>
                        <div className="space-y-2 font-semibold text-gray-600">
                          <div className="text-xs sm:text-sm">
                            {fetchedPlan &&
                              fetchedPlan?.length &&
                              (!usdRate.length
                                ? `₦${Number(
                                    fetchedPlan[0]?.recurring_price *
                                      planQuantity
                                  ).toLocaleString()}`
                                : `$${Number(
                                    fetchedPlan[0]?.recurring_price *
                                      +rate[0].exchange_rate *
                                      planQuantity
                                  ).toFixed(2)}`)}
                          </div>
                          <div className="text-xs sm:text-sm">
                            {!usdRate
                              ? subtotalAddon
                                ? `₦${Number(subtotalAddon).toLocaleString()}`
                                : "₦0.00"
                              : null}

                            {usdRate
                              ? subtotalAddon
                                ? `$${Number(
                                    subtotalAddon * +rate[0].exchange_rate
                                  ).toFixed(2)}`
                                : "$0.00"
                              : null}
                          </div>
                          {/* <div>{`₦${Number(
                        SubtotalPlanPrice + subtotalAddon
                      ).toLocaleString()}`}</div> */}
                          <div className="mt-4 text-base font-bold sm:text-lg">
                            {!usdRate
                              ? subtotalAddon || SubtotalPlanPrice
                                ? `₦${Number(
                                    SubtotalPlanPrice + subtotalAddon
                                  ).toLocaleString()}`
                                : "₦0.00"
                              : null}

                            {usdRate
                              ? subtotalAddon || SubtotalPlanPrice
                                ? `$${Number(
                                    SubtotalPlanPrice * +rate[0].exchange_rate +
                                      subtotalAddon * +rate[0].exchange_rate
                                  ).toFixed(2)}`
                                : "$0.00"
                              : null}
                          </div>
                        </div>
                      </div>
                      <div className="mb-40 mt-4 text-center">
                        <button
                          onClick={continueToCart}
                          className="transform rounded-md bg-olivine-500 px-16 py-3 text-sm font-medium tracking-wider text-white transition-all duration-200 hover:-translate-y-0.5 hover:bg-olivine-400 focus:outline-none focus:ring focus:ring-olivine-500 focus:ring-opacity-60 focus:ring-offset-2 active:bg-olivine-600 sm:px-24 sm:text-base"
                        >
                          Continue To Cart
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <Modal
            visible={showModal}
            onClose={handleOnClose}
            selected={selectedModal}
            switcher={switchToMonthly}
          />
        </div>
      )}
    </>
  );
}

export default PlansCartComplete;
