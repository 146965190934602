import React, { useState } from "react";
import Container from "../my_container/Container";
import SignedInNavbarImproved from "../../SignedInNavbarImproved";
import { BiArrowBack } from "react-icons/bi";

const GropoolHistoryDetailPage = () => {
  const [isPending, setIspending] = useState(true);
  return (
    <>
      <SignedInNavbarImproved />
      {!isPending && (
        <Container className="mb-40 pt-6 @container">
          <div className="flex items-center space-x-2 sm:space-x-4 lg:ml-4">
            <div className="rounded-full p-1 hover:bg-gray-200">
              <BiArrowBack className="h-6 w-6 cursor-pointer text-gray-600" />
            </div>

            <div className="text-lg font-bold text-gravel-600 sm:text-2xl">
              Whole Cow X10
            </div>
          </div>
          <div className="mx-auto mt-2 max-w-5xl divide-y-[1px] divide-gray-200">
            <div className="py-2">
              <p className="text-xs font-medium text-gray-500">Product Name</p>
              <p className="mt-1 text-sm font-semibold text-gray-700">
                Whole Cow x10
              </p>
            </div>
            <div className="py-3">
              <p className="text-sm font-semibold text-gray-500">
                Slot Information
              </p>
              <p className="mt-1 text-xs font-medium text-gray-700">
                Slots Purchased by you{" "}
                <span className="text-sm font-normal text-gray-500">4</span>
              </p>
              <p className="mt-1 text-xs font-medium text-gray-700">
                Price Per Slot:{" "}
                <span className="text-sm font-normal text-gray-500">
                  N100,000
                </span>
              </p>
              <p className="mt-1 text-xs font-medium text-gray-700">
                Total Amount for Purchase:{" "}
                <span className="text-sm font-normal text-gray-500">
                  N400,000
                </span>
              </p>
            </div>
            <div className="py-3">
              <p className="text-sm font-semibold text-gray-500">
                Purchase Type
              </p>
              <p className="mt-1 text-xs font-medium text-gray-700">
                Purchase Option:{" "}
                <span className="cursor-pointer text-sm font-normal text-gray-500 hover:underline hover:underline-offset-4">
                  Gift-Edit Recipients Details
                </span>
              </p>
              <p className="my-1 text-base font-bold text-olivine-600">OR</p>
              <p className="mt-1 text-xs font-medium text-gray-700">
                Purchase Option:{" "}
                <span className="text-sm font-normal text-gray-500">
                  Purchase For self
                </span>
              </p>
            </div>
            <div className="py-3">
              <p className="text-sm font-semibold text-gray-500">
                Slot status at time of purchase
              </p>
              <p className="mt-1 text-xs font-medium text-gray-700">
                Total Slots{" "}
                <span className="text-sm font-normal text-gray-500">10</span>
              </p>
              <p className="mt-1 text-xs font-medium text-gray-700">
                Slots Filled:{" "}
                <span className="text-sm font-normal text-gray-500">1</span>
              </p>
              <p className="mt-1 text-xs font-medium text-gray-700">
                Slots yet to be filled:{" "}
                <span className="text-sm font-normal text-gray-500">9</span>
              </p>
            </div>
            <div className="py-3">
              <p className="text-sm font-semibold text-gray-500">
                Deadline Information
              </p>
              <p className="mt-1 text-xs font-medium text-gray-700">
                Slot Filling Deadline:{" "}
                <span className="text-sm font-normal text-gray-500">
                  10-November 2020
                </span>
              </p>
              <p className="mt-1 text-xs font-medium text-gray-700">
                Slot Deadline Option:{" "}
                <span className="text-sm font-normal text-gray-500">
                  Split Remaining slots among buyers
                </span>
              </p>
            </div>
            <div className="py-3">
              <p className="text-sm font-semibold text-gray-500">
                Notifications and Updates
              </p>
              <p className="text-[11px] font-semibold text-gray-500">
                where the user will receive updates about the slot filling
                progress or additional payments.
              </p>
              <p className="mt-1 text-xs font-medium text-gray-700">
                Email Address{" "}
                <span className="text-sm font-normal text-gray-500">
                  kaidoe@gmail.com{" "}
                </span>
              </p>
              <p className="mt-1 text-xs font-medium text-gray-700">
                Phone Number{" "}
                <span className="text-sm font-normal text-gray-500">
                  0801 234 5678
                </span>
              </p>
            </div>
          </div>
        </Container>
      )}
      {isPending && (
        <Container className="mb-40 pt-6 @container">
          <div className="flex flex-row items-center justify-between">
            <div className="flex items-center space-x-2 sm:space-x-4">
              <div className="rounded-full p-1 hover:bg-gray-200">
                <BiArrowBack className="h-6 w-6 cursor-pointer text-gray-600" />
              </div>

              <div className="text-lg font-bold text-gravel-600 sm:text-2xl">
                Whole Cow X10 Pending
              </div>
            </div>

            <button className="flex w-fit items-center justify-center text-[#558223] transition-all duration-300 hover:text-[#558223]/80">
              <div>
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 22 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17.8817 7.22659L7.89499 2.23325C1.18666 -1.12675 -1.56668 1.62659 1.79332 8.33492L2.80832 10.3649C3.09999 10.9599 3.09999 11.6483 2.80832 12.2433L1.79332 14.2616C-1.56668 20.9699 1.17499 23.7233 7.89499 20.3633L17.8817 15.3699C22.3617 13.1299 22.3617 9.46659 17.8817 7.22659ZM14.1133 12.1733H7.81332C7.33499 12.1733 6.93832 11.7766 6.93832 11.2983C6.93832 10.8199 7.33499 10.4233 7.81332 10.4233H14.1133C14.5917 10.4233 14.9883 10.8199 14.9883 11.2983C14.9883 11.7766 14.5917 12.1733 14.1133 12.1733Z"
                    fill="#558223"
                  />
                </svg>
              </div>
              <span className="ml-2 text-base font-medium ">
                Share Group info
              </span>
            </button>
          </div>
          <div className="mt-8 grid grid-cols-1 gap-4 lg:grid-cols-2">
            <div
              className={`min-w-80 w-full flex-1 cursor-pointer rounded-2xl border-[1px] border-[#1F1F1F] bg-[#1F1F1F] p-6`}
            >
              <h3 className="text-center text-xl font-semibold text-white">
                COUNTDOWN TO DEADLINE
              </h3>
              <div className="mx-auto mt-8 grid max-w-sm grid-cols-4">
                <div className="flex flex-col items-center">
                  <p className="text-5xl font-normal text-white">12</p>
                  <p className="mt-1 text-xs font-normal text-white">Days</p>
                </div>
                <div className="flex flex-col items-center">
                  <p className="text-5xl font-normal text-white">11</p>
                  <p className="mt-1 text-xs font-normal text-white">Hours</p>
                </div>
                <div className="flex flex-col items-center">
                  <p className="text-5xl font-normal text-white">55</p>
                  <p className="mt-1 text-xs font-normal text-white">Minutes</p>
                </div>
                <div className="flex flex-col items-center">
                  <p className="text-5xl font-normal text-white">59</p>
                  <p className="mt-1 text-xs font-normal text-white">Seconds</p>
                </div>
              </div>
            </div>
            <div
              className={`min-w-80 w-full flex-1 cursor-pointer rounded-2xl border-[1px] border-[#CED8C4] bg-[#E8EFE1] p-6`}
            >
              <div className="">
                <span className="mr-2 text-sm font-medium text-gray-600">
                  Product Name
                </span>
                <span className="text-base font-semibold text-gray-800">
                  Whole Cow x10
                </span>
              </div>
              <div className="mt-1">
                <span className="mr-2 text-sm font-medium text-gray-600">
                  Status:
                </span>
                <span className="text-base font-semibold text-[#E9840D]">
                  Awaiting slot Completion
                </span>
              </div>
              <div className="mt-1">
                <span className="mr-2 text-sm font-medium text-gray-600">
                  Total Slots
                </span>
                <span className="text-base font-semibold text-gray-500">
                  10
                </span>
              </div>
              <div className="mt-1">
                <span className="mr-2 text-sm font-medium text-gray-600">
                  Slots Filled:
                </span>
                <span className="text-base font-semibold text-gray-500">9</span>
              </div>
              <div className="mt-1">
                <span className="mr-2 text-sm font-medium text-gray-600">
                  Slots yet to be filled:
                </span>
                <span className="text-base font-semibold text-gray-500">1</span>
              </div>
              <div className="mt-1">
                <span className="mr-2 text-sm font-medium text-gray-600">
                  Sharing Date:
                </span>
                <span className="text-base font-semibold text-gray-800">
                  12th November 2024
                </span>
              </div>
              <div className="mt-1">
                <span className="mr-2 text-sm font-medium text-[#E51E26]">
                  Deadline:
                </span>
                <span className="text-base font-semibold text-[#E51E26]">
                  11th November 2024
                </span>
              </div>
            </div>
          </div>
          <div className="mt-6 divide-y-[1px] divide-gray-200 lg:px-4">
            <div className="py-2">
              <p className="text-xs font-medium text-gray-500">Product Name</p>
              <p className="mt-1 text-sm font-semibold text-gray-700">
                Whole Cow x10
              </p>
            </div>
            <div className="py-3">
              <p className="text-sm font-semibold text-gray-500">
                Slot Information
              </p>
              <p className="mt-1 text-xs font-medium text-gray-700">
                Slots Purchased by you{" "}
                <span className="text-sm font-normal text-gray-500">4</span>
              </p>
              <p className="mt-1 text-xs font-medium text-gray-700">
                Price Per Slot:{" "}
                <span className="text-sm font-normal text-gray-500">
                  N100,000
                </span>
              </p>
              <p className="mt-1 text-xs font-medium text-gray-700">
                Total Amount for Purchase:{" "}
                <span className="text-sm font-normal text-gray-500">
                  N400,000
                </span>
              </p>
            </div>
            <div className="py-3">
              <p className="text-sm font-semibold text-gray-500">
                Purchase Type
              </p>
              <p className="mt-1 text-xs font-medium text-gray-700">
                Purchase Option:{" "}
                <span className="cursor-pointer text-sm font-normal text-[#558223] hover:underline hover:underline-offset-4">
                  Gift-Edit Recipients Details
                </span>
              </p>
              <p className="my-1 text-base font-bold text-[#558223]">OR</p>
              <p className="mt-1 text-xs font-medium text-gray-700">
                Purchase Option:{" "}
                <span className="text-sm font-normal text-gray-500">
                  Purchase For self
                </span>
              </p>
            </div>
            <div className="py-3">
              <p className="text-sm font-semibold text-gray-500">
                Slot status at time of purchase
              </p>
              <p className="mt-1 text-xs font-medium text-gray-700">
                Total Slots{" "}
                <span className="text-sm font-normal text-gray-500">10</span>
              </p>
              <p className="mt-1 text-xs font-medium text-gray-700">
                Slots Filled:{" "}
                <span className="text-sm font-normal text-gray-500">1</span>
              </p>
              <p className="mt-1 text-xs font-medium text-gray-700">
                Slots yet to be filled:{" "}
                <span className="text-sm font-normal text-gray-500">9</span>
              </p>
            </div>
            <div className="py-3">
              <p className="text-sm font-semibold text-gray-500">
                Deadline Information
              </p>
              <p className="mt-1 text-xs font-medium text-gray-700">
                Slot Filling Deadline:{" "}
                <span className="text-sm font-normal text-gray-500">
                  10-November 2020
                </span>
              </p>
              <p className="mt-1 text-xs font-medium text-gray-700">
                Slot Deadline Option:{" "}
                <span className="text-sm font-normal text-gray-500">
                  Split Remaining slots among buyers
                </span>
              </p>
            </div>
            <div className="py-3">
              <p className="text-sm font-semibold text-gray-500">
                Notifications and Updates
              </p>
              <p className="text-[11px] font-semibold text-gray-500">
                where the user will receive updates about the slot filling
                progress or additional payments.
              </p>
              <p className="mt-1 text-xs font-medium text-gray-700">
                Email Address{" "}
                <span className="text-sm font-normal text-gray-500">
                  kaidoe@gmail.com{" "}
                </span>
              </p>
              <p className="mt-1 text-xs font-medium text-gray-700">
                Phone Number{" "}
                <span className="text-sm font-normal text-gray-500">
                  0801 234 5678
                </span>
              </p>
            </div>
          </div>
          <div>
            <button className="mx-auto mt-8 flex w-full max-w-xs items-center justify-center rounded-md bg-[#558223] px-8 py-3.5 text-white transition-all duration-300 hover:bg-[#558223]/80">
              <div>
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15.0693 5.50867L6.50929 1.22867C0.759287 -1.65133 -1.60071 0.708669 1.27929 6.45867L2.14929 8.19867C2.39929 8.70867 2.39929 9.29867 2.14929 9.80867L1.27929 11.5387C-1.60071 17.2887 0.749287 19.6487 6.50929 16.7687L15.0693 12.4887C18.9093 10.5687 18.9093 7.42867 15.0693 5.50867ZM11.8393 9.74867H6.43929C6.02929 9.74867 5.68929 9.40867 5.68929 8.99867C5.68929 8.58867 6.02929 8.24867 6.43929 8.24867H11.8393C12.2493 8.24867 12.5893 8.58867 12.5893 8.99867C12.5893 9.40867 12.2493 9.74867 11.8393 9.74867Z"
                    fill="white"
                  />
                </svg>
              </div>
              <span className="ml-2 text-sm font-medium ">
                Share Group info
              </span>
            </button>
          </div>
        </Container>
      )}
    </>
  );
};

export default GropoolHistoryDetailPage;
