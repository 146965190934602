import React, { useState } from "react";
import { BiArrowBack } from "react-icons/bi";
import { MdDateRange } from "react-icons/md";
import { Fragment } from "react";
import { Tab } from "@headlessui/react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import GropoolEmptyImage from "../../../assets/rewardBankHistoryEmptyImage.svg";
import SignedInNavbarImproved from "../../SignedInNavbarImproved";
import Container from "../my_container/Container";
import { useNavigate } from "react-router-dom";

const GropoolHistoryPage = () => {
  const navigate = useNavigate();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [isEmpty, setIsEmpty] = useState(false);

  return (
    <div>
      <SignedInNavbarImproved />
      {isEmpty ? (
        <>
          <Container className="mb-14 pt-6 @container">
            <div className="flex items-center space-x-2 sm:space-x-4">
              <div className="rounded-full p-1 hover:bg-gray-200">
                <BiArrowBack className="h-6 w-6 cursor-pointer text-gray-600" />
              </div>

              <div className="text-lg font-bold text-gravel-600 sm:text-2xl">
                Gropool History
              </div>
            </div>
            <div className="mt-8 flex flex-col items-center justify-center">
              <img src={GropoolEmptyImage} alt="img" className="h-44 w-auto" />
              <h2 className="mt-4 text-xl font-bold text-gravel-600">
                No Gropool Orders Yet
              </h2>
              <p className="mt-2 text-sm font-medium text-gray-500">
                "Oops, it seems like your Gropool history is empty.{" "}
              </p>
            </div>
            <div className="mx-auto mt-8 max-w-xs">
              <button className="w-full transform cursor-pointer rounded-md bg-olivine-500 px-10 py-4 text-xs font-semibold text-white transition-all duration-200 hover:bg-olivine-500/80 focus:outline-none active:bg-olivine-600 xsm:text-sm">
                Go to Gropool Page
              </button>
            </div>
          </Container>
        </>
      ) : (
        <>
          <Container className="mb-40 pt-6 @container">
            <div className="flex items-center space-x-2 sm:space-x-4">
              <div className="rounded-full p-1 hover:bg-gray-200">
                <BiArrowBack className="h-6 w-6 cursor-pointer text-gray-600" />
              </div>

              <div className="text-lg font-bold text-gravel-600 sm:text-2xl">
                Gropool History
              </div>
            </div>

            <div className="mt-10 flex flex-col @[350px]:flex-row @[350px]:items-center @[350px]:space-x-2 ">
              <div className="flex items-center space-x-2">
                <div className="relative">
                  <DatePicker
                    dateFormat="dd/MM/yyyy"
                    placeholderText="Start Date"
                    className="h-10 w-full max-w-[165px] flex-1 cursor-pointer rounded-md border-gray-300 bg-white text-sm text-gray-600 focus:border-olivine-500 focus:ring-olivine-500"
                  />
                  <MdDateRange className="absolute right-1 top-2.5 h-5 w-5 text-olivine-500" />
                </div>
                <div className="relative">
                  <DatePicker
                    dateFormat="dd/MM/yyyy"
                    placeholderText="End Date"
                    className="h-10 w-full max-w-[165px] flex-1 cursor-pointer rounded-md border-gray-300 bg-white text-sm text-gray-600 focus:border-olivine-500 focus:ring-olivine-500"
                  />
                  <MdDateRange className="absolute right-1 top-2.5 h-5 w-5 text-olivine-500" />
                </div>
              </div>

              <div className="mt-4 @[350px]:mt-0">
                <button className="h-10 w-full cursor-pointer rounded-md border-[1px] border-olivine-500 bg-olivine-500 px-8 text-xs font-semibold text-white transition-all hover:bg-olivine-600 @[350px]:w-fit">
                  Search
                </button>
              </div>
            </div>

            <div className="mt-6">
              <Tab.Group>
                {/*   Tab section header */}
                <Tab.List className="flex flex-wrap items-center text-sm">
                  <Tab as={Fragment}>
                    {({ selected }) => (
                      /* Use the `selected` state to conditionally style the selected tab. */
                      <button
                        className={
                          selected
                            ? "mr-4 mt-3 border-b-[2px] border-olivine-500 border-opacity-100 px-4  py-1 text-olivine-500 outline-none"
                            : "mr-4 mt-3 border-b-[2px] border-olivine-500 border-opacity-0 px-4 py-1 text-gray-500"
                        }
                      >
                        <div className="cursor-pointer">
                          <div className=" text-xs font-semibold xsm:text-sm ">
                            All Gropool orders
                          </div>
                        </div>
                      </button>
                    )}
                  </Tab>

                  <Tab as={Fragment}>
                    {({ selected }) => (
                      /* Use the `selected` state to conditionally style the selected tab. */
                      <button
                        className={
                          selected
                            ? "mr-4 mt-3 border-b-[2px] border-olivine-500 border-opacity-100 px-4  py-1 text-olivine-500 outline-none"
                            : "mr-4 mt-3 border-b-[2px] border-olivine-500 border-opacity-0 px-4 py-1 text-gray-500"
                        }
                      >
                        <div className="cursor-pointer">
                          <div className=" text-xs font-semibold xsm:text-sm ">
                            Pending Gropool orders
                          </div>
                        </div>
                      </button>
                    )}
                  </Tab>

                  <Tab as={Fragment}>
                    {({ selected }) => (
                      /* Use the `selected` state to conditionally style the selected tab. */
                      <button
                        className={
                          selected
                            ? "mr-4 mt-3 border-b-[2px] border-olivine-500 border-opacity-100 px-4  py-1 text-olivine-500 outline-none"
                            : "mr-4 mt-3 border-b-[2px] border-olivine-500 border-opacity-0 px-4 py-1 text-gray-500"
                        }
                      >
                        <div className="cursor-pointer">
                          <div className=" text-xs font-semibold xsm:text-sm">
                            Completed Gropool orders
                          </div>
                        </div>
                      </button>
                    )}
                  </Tab>
                </Tab.List>

                {/*  tab Body */}
                <Tab.Panels>
                  {/* All  Plan */}
                  <Tab.Panel className="">
                    <div
                      onClick={() => navigate("/gropool/history/:id")}
                      className="mt-5 max-w-6xl"
                    >
                      <p className="ml-4 text-[11px] font-normal text-gray-600">
                        31 January 2023
                      </p>
                      <div className="mt-1 cursor-pointer rounded-md border-[1px] border-gray-100 bg-[#F9F9F9] px-4 py-3 hover:border-gray-200">
                        <div className="flex flex-row items-center justify-between">
                          <h3 className="text-base font-bold text-gray-700">
                            Whole Cow x10
                          </h3>
                          <p className="text-sm font-semibold text-gray-500">
                            N 23,000.00
                          </p>
                        </div>
                        <p className="mt-1 text-xs font-semibold text-olivine-600">
                          Completed{" "}
                          <span className="ml-1 font-normal text-gray-500">
                            31 January 2023
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className="mt-5 max-w-6xl">
                      <p className="ml-4 text-[11px] font-normal text-gray-600">
                        31 January 2023
                      </p>
                      <div className="mt-1 cursor-pointer rounded-md border-[1px] border-gray-100 bg-[#F9F9F9] px-4 py-3 hover:border-gray-200">
                        <div className="flex flex-row items-center justify-between">
                          <h3 className="text-base font-bold text-gray-700">
                            Whole Cow x10
                          </h3>
                          <p className="text-sm font-semibold text-gray-500">
                            N 23,000.00
                          </p>
                        </div>
                        <p className="mt-1 text-xs font-semibold text-[#D9A41C]">
                          Awaiting slot Completion
                        </p>
                      </div>
                    </div>
                    <div className="mt-5 max-w-6xl">
                      <p className="ml-4 text-[11px] font-normal text-gray-600">
                        31 January 2023
                      </p>
                      <div className="mt-1 cursor-pointer rounded-md border-[1px] border-gray-100 bg-[#F9F9F9] px-4 py-3 hover:border-gray-200">
                        <div className="flex flex-row items-center justify-between">
                          <h3 className="text-base font-bold text-gray-700">
                            Whole Cow x10
                          </h3>
                          <p className="text-sm font-semibold text-gray-500">
                            N 23,000.00
                          </p>
                        </div>
                        <p className="mt-1 text-xs font-semibold text-[#D9A41C]">
                          Awaiting slot Completion
                        </p>
                      </div>
                    </div>
                  </Tab.Panel>

                  {/* plans  */}
                  <Tab.Panel>
                    <div className="mt-5 max-w-6xl">
                      <p className="ml-4 text-[11px] font-normal text-gray-600">
                        31 January 2023
                      </p>
                      <div className="mt-1 cursor-pointer rounded-md border-[1px] border-gray-100 bg-[#F9F9F9] px-4 py-3 hover:border-gray-200">
                        <div className="flex flex-row items-center justify-between">
                          <h3 className="text-base font-bold text-gray-700">
                            Whole Cow x10
                          </h3>
                          <p className="text-sm font-semibold text-gray-500">
                            N 23,000.00
                          </p>
                        </div>
                        <p className="mt-1 text-xs font-semibold text-[#D9A41C]">
                          Awaiting slot Completion
                        </p>
                      </div>
                    </div>
                    <div className="mt-5 max-w-6xl">
                      <p className="ml-4 text-[11px] font-normal text-gray-600">
                        31 January 2023
                      </p>
                      <div className="mt-1 cursor-pointer rounded-md border-[1px] border-gray-100 bg-[#F9F9F9] px-4 py-3 hover:border-gray-200">
                        <div className="flex flex-row items-center justify-between">
                          <h3 className="text-base font-bold text-gray-700">
                            Whole Cow x10
                          </h3>
                          <p className="text-sm font-semibold text-gray-500">
                            N 23,000.00
                          </p>
                        </div>
                        <p className="mt-1 text-xs font-semibold text-[#D9A41C]">
                          Awaiting slot Completion
                        </p>
                      </div>
                    </div>
                    <div className="mt-5 max-w-6xl">
                      <p className="ml-4 text-[11px] font-normal text-gray-600">
                        31 January 2023
                      </p>
                      <div className="mt-1 cursor-pointer rounded-md border-[1px] border-gray-100 bg-[#F9F9F9] px-4 py-3 hover:border-gray-200">
                        <div className="flex flex-row items-center justify-between">
                          <h3 className="text-base font-bold text-gray-700">
                            Whole Cow x10
                          </h3>
                          <p className="text-sm font-semibold text-gray-500">
                            N 23,000.00
                          </p>
                        </div>
                        <p className="mt-1 text-xs font-semibold text-[#D9A41C]">
                          Awaiting slot Completion
                        </p>
                      </div>
                    </div>
                  </Tab.Panel>

                  {/* reoccurring */}
                  <Tab.Panel>
                    <div className="mt-5 max-w-6xl">
                      <p className="ml-4 text-[11px] font-normal text-gray-600">
                        31 January 2023
                      </p>
                      <div className="mt-1 cursor-pointer rounded-md border-[1px] border-gray-100 bg-[#F9F9F9] px-4 py-3 hover:border-gray-200">
                        <div className="flex flex-row items-center justify-between">
                          <h3 className="text-base font-bold text-gray-700">
                            Whole Cow x10
                          </h3>
                          <p className="text-sm font-semibold text-gray-500">
                            N 23,000.00
                          </p>
                        </div>
                        <p className="mt-1 text-xs font-semibold text-olivine-600">
                          Completed{" "}
                          <span className="ml-1 font-normal text-gray-500">
                            31 January 2023
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className="mt-5 max-w-6xl">
                      <p className="ml-4 text-[11px] font-normal text-gray-600">
                        31 January 2023
                      </p>
                      <div className="mt-1 cursor-pointer rounded-md border-[1px] border-gray-100 bg-[#F9F9F9] px-4 py-3 hover:border-gray-200">
                        <div className="flex flex-row items-center justify-between">
                          <h3 className="text-base font-bold text-gray-700">
                            Whole Cow x10
                          </h3>
                          <p className="text-sm font-semibold text-gray-500">
                            N 23,000.00
                          </p>
                        </div>
                        <p className="mt-1 text-xs font-semibold text-olivine-600">
                          Completed{" "}
                          <span className="ml-1 font-normal text-gray-500">
                            31 January 2023
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className="mt-5 max-w-6xl">
                      <p className="ml-4 text-[11px] font-normal text-gray-600">
                        31 January 2023
                      </p>
                      <div className="mt-1 cursor-pointer rounded-md border-[1px] border-gray-100 bg-[#F9F9F9] px-4 py-3 hover:border-gray-200">
                        <div className="flex flex-row items-center justify-between">
                          <h3 className="text-base font-bold text-gray-700">
                            Whole Cow x10
                          </h3>
                          <p className="text-sm font-semibold text-gray-500">
                            N 23,000.00
                          </p>
                        </div>
                        <p className="mt-1 text-xs font-semibold text-olivine-600">
                          Completed{" "}
                          <span className="ml-1 font-normal text-gray-500">
                            31 January 2023
                          </span>
                        </p>
                      </div>
                    </div>
                  </Tab.Panel>
                </Tab.Panels>
              </Tab.Group>
            </div>
          </Container>
        </>
      )}
    </div>
  );
};

export default GropoolHistoryPage;
