import React from "react";
import { BiArrowBack } from "react-icons/bi";
import ProgressBar from "@ramonak/react-progress-bar";
import { useNavigate } from "react-router-dom";

const AvailableSlotsComponent = ({
  category,
  image,
  name,
  completedStatus,
  slotPrice,
  slotDate,
}) => {
  const navigate = useNavigate();
  return (
    <div
      onClick={() => navigate("/gropool/detail")}
      className={`min-w-80 w-full cursor-pointer rounded-xl border-[1px] border-gray-200 p-4 hover:border-gray-300 ${
        category === "vegetable" && "bg-[#F9F9F9]"
      } ${category === "fruit" && "bg-[#D5EBBB]"} ${
        category === "meat" && "bg-[#EDE0C1]"
      }`}
    >
      <div className="mr-4 flex items-center justify-between">
        <div className="flex h-6 w-[85px] items-center justify-center rounded-full bg-[#EDEEEC]">
          <p className="text-[11px] font-medium text-gray-500">{slotDate}</p>
        </div>
        <div>
          <BiArrowBack className="h-5 w-5 rotate-180 text-gray-600" />
        </div>
      </div>
      <div className="mt-2 flex flex-row space-x-2">
        <div>
          <img
            src={image}
            alt="img"
            className="aspect-auto h-12 w-12 object-contain"
          />
        </div>
        <div>
          <p className="text-xs font-medium text-gray-600">
            {name} Sharing x10
          </p>
          <p className="text-lg font-semibold text-gray-700">
            <span className="text-clip align-text-top text-xs">N</span>
            <span className="font-bold">{slotPrice}</span>
            <span className="text-xs">/slot</span>
          </p>
        </div>
      </div>
      <div>
        <div className="mt-1 flex flex-row items-center justify-between text-xs font-normal text-gray-600">
          <p>Slots</p>
          <p>5/10</p>
        </div>
        <div className="mt-1">
          <ProgressBar
            height="8px"
            width="100%"
            bgColor={`${category === "fruit" ? "#558223" : "#45444B"}`}
            maxCompleted={100}
            completed={completedStatus}
            isLabelVisible={false}
            barContainerClassName={`rounded-full ${
              category === "vegetable" && "bg-[#EDEEEC]"
            } ${category === "fruit" && "bg-[#FFFFFF]"} ${
              category === "meat" && "bg-[#FFFFFF]"
            }`}
          />
        </div>
      </div>
    </div>
  );
};

export default AvailableSlotsComponent;
