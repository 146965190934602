import React from "react";
import GropoolNoteImage from "../../../../assets/gropoolNoteImage.svg";

const CreateNewGropool = () => {
  return (
    <div className="mb-20 px-2 sm:px-6">
      <h2 className="mt-6 text-xl font-semibold text-gray-700">
        Create new sharing group
      </h2>
      <div className="mt-6 rounded-md border-[1px] border-[#DE9512]/20 bg-[#FFF5E4] p-3">
        <div className="flex flex-shrink-0 items-center space-x-2">
          <div>
            <img src={GropoolNoteImage} alt="" className="h-5 w-5" />
          </div>
          <h3 className="text-base font-semibold text-[#DE9512]">
            Please Note
          </h3>
        </div>
        <p className="mt-1 text-sm font-normal text-gray-500">
          That By Creating a Group you are automatically Purchasing Slots for
          the Created group
        </p>
      </div>
      <div className="mt-4 rounded-lg border-[1px] border-[#DFE5D1] p-4 shadow-xl shadow-[#F6F6F5] sm:p-6">
        <form>
          <div>
            <label htmlFor="" className="ml-1 text-sm text-gray-500">
              Group Name(Optional)
            </label>
            <div className="rounded-md">
              <input
                type="text"
                placeholder="Please input"
                required
                className="mt-1 block w-full rounded-md border-gray-200 bg-white pl-4 text-base text-gray-700  placeholder:text-sm placeholder:font-normal placeholder:text-gray-400 focus:border-olivine-500 focus:ring-olivine-500"
              />
            </div>
          </div>
          <div className="mt-4">
            <label for="fruits" className="ml-1 text-sm text-gray-500">
              Product Selection
            </label>
            <div className="rounded-md">
              <select
                name="fruits"
                id="fruits"
                className="mt-1 block w-full rounded-md border-gray-200 bg-white pl-4 text-base text-gray-700  placeholder:text-sm placeholder:font-normal placeholder:text-gray-400 focus:border-olivine-500 focus:ring-olivine-500"
              >
                <option value="meat">Meat</option>
                <option value="goat">Goat</option>
              </select>
            </div>
          </div>
          <div className="mt-4">
            <label for="fruits" className="ml-1 text-sm text-gray-500">
              Number of Slots
            </label>
            <div className="rounded-md">
              <select
                name="fruits"
                id="fruits"
                className="mt-1 block w-full rounded-md border-gray-200 bg-white pl-4 text-base text-gray-700  placeholder:text-sm placeholder:font-normal placeholder:text-gray-400 focus:border-olivine-500 focus:ring-olivine-500"
              >
                <option value="meat">Meat</option>
                <option value="goat">Goat</option>
              </select>
            </div>
          </div>
          <div className="mt-4">
            <label htmlFor="" className="ml-1 text-sm text-gray-500">
              Slot Price(Automatically Generated)
            </label>
            <div className="rounded-md">
              <input
                type="text"
                placeholder="Prefilled"
                required
                className="mt-1 block w-full rounded-md border-gray-200 bg-white pl-4 text-base text-gray-700  placeholder:text-sm placeholder:font-normal placeholder:text-gray-400 focus:border-olivine-500 focus:ring-olivine-500"
              />
            </div>
          </div>
          <div className="mt-4">
            <label for="fruits" className="ml-1 text-sm text-gray-500">
              How many Slots are you puchasing?
            </label>
            <div className="rounded-md">
              <select
                name="fruits"
                id="fruits"
                className="mt-1 block w-full rounded-md border-gray-200 bg-white pl-4 text-base text-gray-700  placeholder:text-sm placeholder:font-normal placeholder:text-gray-400 focus:border-olivine-500 focus:ring-olivine-500"
              >
                <option value="meat">5</option>
                <option value="goat">10</option>
              </select>
            </div>
          </div>
        </form>
      </div>
      <div className="mt-5">
        <div className="flex flex-row items-center">
          <input
            type="checkbox"
            className="h-5 w-5 cursor-pointer rounded border-[1px] border-gray-300 checked:text-[#558223] checked:ring-0 checked:ring-[#558223] focus:ring-0 focus:ring-[#558223]"
          />
          <span className="ml-2 text-sm  text-gray-600">
            I agree with the{" "}
            <span className="cursor-pointer font-medium text-[#558223] hover:underline hover:underline-offset-4">
              Terms and Conditions
            </span>
          </span>
        </div>
        <div className="mt-3 flex flex-row items-center">
          <input
            type="checkbox"
            className="h-5 w-5 cursor-pointer rounded border-[1px] border-gray-300 checked:text-[#558223] checked:ring-0 checked:ring-[#558223] focus:ring-0 focus:ring-[#558223]"
          />
          <span className="ml-2 text-sm text-gray-600">
            Notify me when someone joins my Groups
          </span>
        </div>
      </div>
      <div className="mx-auto mt-8 max-w-sm">
        <button className="w-full transform cursor-pointer rounded-md bg-olivine-500 px-10 py-4 text-xs font-semibold text-white transition-all duration-200 hover:bg-olivine-500/80 focus:outline-none active:bg-olivine-600 xsm:text-sm">
          Proceed
        </button>
      </div>
    </div>
  );
};

export default CreateNewGropool;
