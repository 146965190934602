import React from "react";
import { BiArrowBack } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import RecommendedSlotsComponent from "./RecommendedSlotsComponent";

const RecommendedSlots = () => {
  const navigate = useNavigate();
  return (
    <div>
      <div className="mt-10 flex flex-row items-center justify-between">
        <h2 className="text-xl font-bold text-gray-700 sm:text-xl">
          Recommended Slots
        </h2>

        <button className="border-b-[1.5px] border-b-white text-base font-semibold text-[#73964C] hover:border-b-[#73964C]">
          See All
        </button>
      </div>
      <div className="mt-6 grid gap-4 grid-auto-fit-xs">
        <RecommendedSlotsComponent />
        <RecommendedSlotsComponent />
        <RecommendedSlotsComponent />
        <RecommendedSlotsComponent />
        <RecommendedSlotsComponent />
        <RecommendedSlotsComponent />
        <RecommendedSlotsComponent />
      </div>
    </div>
  );
};

export default RecommendedSlots;
