import React, { useCallback, useEffect, useState } from "react";
import useEmblaCarousel from "embla-carousel-react";
import Autoplay from "embla-carousel-autoplay";
import FirstBanner from "../../../../assets/firstBanner.png";
import SecondBanner from "../../../../assets/secondBanner.png";
import ThirdBanner from "../../../../assets/thirdBanner.png";
import { useDispatch } from "react-redux";
import { authService } from "../../../../services/authService";
import { hideAdsInfo, showAdsInfo } from "../../../../store/actions";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

const AdsContents = ({ adsInfo }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [fetchedAdsInfo, setFetchedAdsInfo] = useState([]);
  const [scrollSnaps, setScrollSnaps] = useState([]);
  const [emblaRef, emblaApi] = useEmblaCarousel({ loop: true }, [Autoplay()]);

  const customerId = authService.getCurrentCustomerId();

  useEffect(() => {
    if (adsInfo?.length) {
      const updateArray = adsInfo.map((arr) => ({
        ...arr,
        isClicked: false,
      }));

      setFetchedAdsInfo(updateArray);
    }
  }, [adsInfo]);

  const onDotButtonClick = useCallback(
    (index) => {
      if (!emblaApi) return;
      emblaApi.scrollTo(index);
    },
    [emblaApi]
  );

  const onInit = useCallback((emblaApi) => {
    setScrollSnaps(emblaApi.scrollSnapList());
  }, []);

  const onSelect = useCallback((emblaApi) => {
    setSelectedIndex(emblaApi.selectedScrollSnap());
  }, []);

  useEffect(() => {
    if (!emblaApi) return;

    onInit(emblaApi);
    onSelect(emblaApi);

    emblaApi.on("reInit", onInit).on("reInit", onSelect).on("select", onSelect);
  }, [emblaApi, onInit, onSelect]);

  const handleAdsActions = (refNo) => {
    try {
      const update = fetchedAdsInfo?.map((ad) =>
        ad?.ref_no === refNo ? { ...ad, isClicked: !ad?.isClicked } : ad
      );

      setFetchedAdsInfo(update);

      const selectedAd = update?.filter((sel) => sel?.ref_no === refNo).pop();

      const payload = {
        customer_id: customerId,
        adRefNo: refNo,
      };

      if (selectedAd?.isClicked) {
        return dispatch(hideAdsInfo(payload))
          .unwrap()
          .then((res) => {
            if (res?.message_id === 1) {
              toast.success(res?.message_text);
            }
          })
          .catch((err) => err);
      }

      if (!selectedAd?.isClicked) {
        dispatch(showAdsInfo(payload))
          .unwrap()
          .then((res) => {
            if (res?.message_id === 1) {
              toast.success(res?.message_text);
            }
          })
          .catch((err) => err);
      }
    } catch (err) {
      return err;
    }
  };

  return (
    <div className="overflow-hidden @container" ref={emblaRef}>
      <div className="flex">
        {fetchedAdsInfo.map((slide, slideIndex) => (
          <div
            key={slideIndex}
            className="w-full min-w-0 flex-shrink-0 flex-grow-0"
          >
            <div className="mt-8 @xl:grid @xl:grid-cols-2 @xl:gap-6">
              <div className="ml-1">
                <h2 className="text-4xl font-bold text-gray-700">
                  {slide.ad_title}
                </h2>
                <p className="mt-4 font-medium text-gray-700">
                  {slide.ad_description}
                </p>
                <div className="mt-6 @xl:hidden">
                  <img
                    src={slide.ad_image_url}
                    alt="banner"
                    className="h-auto w-full"
                  />
                </div>
                <div className="mt-10 w-full @xl:mt-6">
                  <button
                    onClick={() => navigate(slide?.web_action_page)}
                    type="button"
                    className="w-full transform cursor-pointer rounded-md bg-olivine-500 px-10 py-3 text-sm font-medium text-white transition-all duration-200 hover:bg-olivine-500/80 focus:outline-none active:bg-olivine-600 @xl:w-fit"
                  >
                    {slide?.ad_button_text}
                  </button>
                </div>
                <div className="mb-4 mt-3 flex flex-row items-center">
                  <input
                    onClick={() => handleAdsActions(slide.ref_no)}
                    type="checkbox"
                    checked={slide?.isClicked}
                    className="h-18px w-18px che cursor-pointer rounded border-[1px] border-gray-300 text-olivine-500 checked:outline-olivine-500 focus:outline-olivine-500"
                  />
                  <span className="ml-2 text-sm font-medium text-gray-700">
                    Do not show me this banner again.
                  </span>
                </div>
              </div>
              <div className="hidden @xl:inline">
                <img
                  src={slide.ad_image_url}
                  alt="banner"
                  className="aspect-auto h-80 w-full object-contain"
                />
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="top-4 flex justify-center pt-4">
        {fetchedAdsInfo.map((slide, slideIndex) => (
          <div
            key={slideIndex}
            onClick={() => onDotButtonClick(slideIndex)}
            className="cursor-pointer"
          >
            <div
              className={`ml-1 h-[5px]  rounded-full ${
                slideIndex === selectedIndex
                  ? "w-10 bg-[#E5B35D]"
                  : " w-5 bg-[#D7CEBF]"
              }`}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default AdsContents;

{
  /* <div className="overflow-hidden @container" ref={emblaRef}>
<div className="flex">
  <div className="w-full min-w-0 flex-shrink-0 flex-grow-0">
    <div className="mt-8 @xl:grid @xl:grid-cols-2 @xl:gap-6">
      <div className="ml-1">
        <h2 className="text-4xl font-bold text-gray-700">
          Introducing Grocedy Reward Bank 🎉🎉
        </h2>
        <p className="mt-4 font-medium text-gray-700">
          Earn Cowries and redeem them for cash value. By subscribing
          early, referring friends, or simply saving extra in your wallet,
          Cowries are your ticket to great rewards.{" "}
        </p>
        <div className="mt-6 @xl:hidden">
          <img src={FirstBanner} alt="banner" className="h-auto w-full" />
        </div>
        <div className="mt-10 w-full @xl:mt-6">
          <button
            type="button"
            className="w-full transform cursor-pointer rounded-md bg-olivine-500 px-10 py-3 text-sm font-medium text-white transition-all duration-200 hover:bg-olivine-500/80 focus:outline-none active:bg-olivine-600 @xl:w-fit"
          >
            Go to Reward Bank
          </button>
        </div>
        <div className="mb-4 mt-3 flex flex-row items-center">
          <input
            type="checkbox"
            className="h-18px w-18px che cursor-pointer rounded border-[1px] border-gray-300 text-olivine-500 checked:outline-olivine-500 focus:outline-olivine-500"
          />
          <span className="ml-2 text-sm font-medium text-gray-700">
            Do not show me this banner again.
          </span>
        </div>
      </div>
      <div className="hidden @xl:inline">
        <img
          src={FirstBanner}
          alt="banner"
          className="aspect-auto h-auto w-full object-contain"
        />
      </div>
    </div>
  </div>
  <div className="w-full min-w-0 flex-shrink-0 flex-grow-0">
    <div className="mt-8 @xl:grid @xl:grid-cols-2 @xl:gap-6">
      <div className="ml-1">
        <h2 className="text-4xl font-bold text-gray-700">
          Share the Joy: Refer & Earn!
        </h2>
        <p className="mt-4 font-medium text-gray-700">
          Share your REFERRAL CODES, Spread the love and earn Cowries for
          every friend you bring to Grocedy. More friends, more rewards.
          It’s a win-win! 👯‍♀️🎁
        </p>
        <div className="mt-6 @xl:hidden">
          <img
            src={SecondBanner}
            alt="banner"
            className="h-auto w-full"
          />
        </div>
        <div className="mt-10 w-full @xl:mt-6">
          <button
            type="button"
            className="w-full transform cursor-pointer rounded-md bg-olivine-500 px-10 py-3 text-sm font-medium text-white transition-all duration-200 hover:bg-olivine-500/80 focus:outline-none active:bg-olivine-600 @xl:w-fit"
          >
            Go to Reward Bank
          </button>
        </div>
        <div className="mb-4 mt-3 flex flex-row items-center">
          <input
            type="checkbox"
            className="h-18px w-18px che cursor-pointer rounded border-[1px] border-gray-300 text-olivine-500 checked:outline-olivine-500 focus:outline-olivine-500"
          />
          <span className="ml-2 text-sm font-medium text-gray-700">
            Do not show me this banner again.
          </span>
        </div>
      </div>
      <div className="hidden @xl:inline">
        <img
          src={SecondBanner}
          alt="banner"
          className="aspect-auto h-auto w-full object-contain"
        />
      </div>
    </div>
  </div>
  <div className="w-full min-w-0 flex-shrink-0 flex-grow-0">
    <div className="mt-8 @xl:grid @xl:grid-cols-2 @xl:gap-6">
      <div className="ml-1">
        <h2 className="text-4xl font-bold text-gray-700">
          Earn Cowries with Every Purchase
        </h2>
        <p className="mt-4 font-medium text-gray-700">
          Grocedy Reward Bank is here to make your shopping more
          rewarding. Earn Cowries with every purchase. Save money, earn
          rewards, and enjoy exclusive benefits! 💰🛒
        </p>
        <div className="mt-6 @xl:hidden">
          <img src={ThirdBanner} alt="banner" className="h-auto w-full" />
        </div>
        <div className="mt-10 w-full @xl:mt-6">
          <button
            type="button"
            className="w-full transform cursor-pointer rounded-md bg-olivine-500 px-10 py-3 text-sm font-medium text-white transition-all duration-200 hover:bg-olivine-500/80 focus:outline-none active:bg-olivine-600 @xl:w-fit"
          >
            Go to Reward Bank
          </button>
        </div>
        <div className="mb-4 mt-3 flex flex-row items-center">
          <input
            type="checkbox"
            className="h-18px w-18px che cursor-pointer rounded border-[1px] border-gray-300 text-olivine-500 checked:outline-olivine-500 focus:outline-olivine-500"
          />
          <span className="ml-2 text-sm font-medium text-gray-700">
            Do not show me this banner again.
          </span>
        </div>
      </div>
      <div className="hidden @xl:inline">
        <img
          src={ThirdBanner}
          alt="banner"
          className="aspect-auto h-auto w-full object-contain"
        />
      </div>
    </div>
  </div>
</div>
</div> */
}
