import React, { useEffect, useRef, useState } from "react";
import Container from "../my_container/Container";
import SignedInNavbarImproved from "../../SignedInNavbarImproved";
import { Link, useNavigate } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";
import { IoClose } from "react-icons/io5";
import { Fragment } from "react";
import { Tab } from "@headlessui/react";
import { Dialog, Transition } from "@headlessui/react";
import AvailableSlotsComponent from "./AvailableSlotsComponent";
import GropoolFruitImage from "../../../assets/gropoolFruitImage.png";
import GropoolVegetableImage from "../../../assets/gropoolVegetableImage.png";
import GropoolDisclosureComponent from "./GropoolDisclosureComponent";
import { FaPlus } from "react-icons/fa";
import CreateNewGropool from "./create_gropool/CreateNewGropool";
import SelectSlotPurchaseOptions from "./create_gropool/SelectSlotPurchaseOptions";
import { useDispatch } from "react-redux";
import { fetchFAQ, fetchHowItWorks } from "../../../store/actions";

const availableSlotLists = [
  {
    category: "vegetable",
    name: "Vegetable ",
    image: GropoolVegetableImage,
    completedStatus: 40,
    slotPrice: "120,000",
    slotDate: "12 October",
  },
  {
    category: "vegetable",
    name: "Vegetable",
    image: GropoolVegetableImage,
    completedStatus: 70,
    slotPrice: "100,000",
    slotDate: "12 November",
  },
  {
    category: "fruit",
    name: "Fruit ",
    image: GropoolFruitImage,
    completedStatus: 10,
    slotPrice: "90,000",
    slotDate: "1st February",
  },
  {
    category: "fruit",
    name: "Fruit",
    image: GropoolFruitImage,
    completedStatus: 60,
    slotPrice: "120,000",
    slotDate: "12 October",
  },
  {
    category: "meat",
    name: "Goat ",
    image: GropoolVegetableImage,
    completedStatus: 80,
    slotPrice: "120,000",
    slotDate: "25 October",
  },
  {
    category: "meat",
    name: "Cow",
    image: GropoolVegetableImage,
    completedStatus: 100,
    slotPrice: "120,000",
    slotDate: "29 April",
  },
];

const GropoolPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const gropoolRef = useRef();
  const [openGropoolModal, setOpenGropoolModal] = useState(false);
  const [openSelectSlotModal, setOpenSelectSlotModal] = useState(false);
  const [fetchedHowItWorks, setFetchedHowItWorks] = useState([]);
  const [fetchedFAQ, setFetchedFAQ] = useState([]);

  useEffect(() => {
    if (!fetchedHowItWorks?.length) {
      handleFetchHowItWorks();
    }

    if (!fetchedFAQ?.length) {
      handleFaq();
    }
  }, [fetchedHowItWorks, fetchedFAQ]);

  const handleFetchHowItWorks = async () => {
    try {
      dispatch(fetchHowItWorks())
        .unwrap()
        .then((res) => {
          if (!res?.working_flow) {
            setFetchedHowItWorks([]);
          }

          setFetchedHowItWorks(res?.working_flow);
        })
        .catch((err) => err);
    } catch (err) {
      return err;
    }
  };

  const handleFaq = async () => {
    try {
      dispatch(fetchFAQ())
        .unwrap()
        .then((res) => {
          if (!res?.faq_info) {
            setFetchedFAQ([]);
          }

          setFetchedFAQ(res?.faq_info);
        })
        .catch((err) => err);
    } catch (err) {
      return err;
    }
  };

  return (
    <>
      <SignedInNavbarImproved />
      <div className="bg-white">
        <Container className="mb-40">
          <div className="mt-10 flex flex-row items-center justify-between">
            <div className="flex items-center space-x-2">
              <div className="rounded-full p-2 hover:bg-gray-200 active:bg-gray-100">
                <BiArrowBack
                  onClick={() => navigate(-1)}
                  className="h-5 w-5 cursor-pointer text-gray-600"
                />
              </div>
              <h2 className="text-xl font-bold text-gray-700 sm:text-2xl">
                Gropool
              </h2>
            </div>
            <Link to="/gropool/history">
              <button className="border-b-[1.5px] border-b-white text-base font-semibold text-[#73964C] hover:border-b-[#73964C]">
                Gropool History
              </button>
            </Link>
          </div>
          <div className="mt-8 rounded-xl border-[1px] border-gray-200 px-6 py-8 shadow-xl shadow-[#F6F6F5]">
            <h3 className="text-base font-bold text-gray-600 sm:text-lg">
              Get More by Sharing!
            </h3>
            <p className="mt-1 text-sm font-normal text-gray-500">
              Team up with others to buy large or high-value products at a
              fraction of the cost. Whether it's purchasing a whole cow or a
              bulk package of products, now you can easily split the cost and
              enjoy your portion without the hefty price tag!
            </p>

            <button
              onClick={() =>
                gropoolRef.current?.scrollIntoView({ behavior: "smooth" })
              }
              className="mt-4 rounded-md border-[1px] border-[#558223]/40 bg-[#F6FBEE] px-5 py-3 text-sm font-medium text-[#558223] transition-all duration-300 hover:border-[#558223]/80"
            >
              See Gropool Groups
            </button>
          </div>
          <div className="mt-10">
            <Tab.Group>
              {/*   Tab section header */}
              <Tab.List className="flex w-fit flex-row flex-wrap items-center text-sm">
                <Tab as={Fragment}>
                  {({ selected }) => (
                    /* Use the `selected` state to conditionally style the selected tab. */
                    <button
                      className={
                        selected
                          ? "mt-3 w-fit border-b-[2px] border-olivine-500 border-opacity-100 px-4 py-2 text-olivine-500 outline-none sm:px-10"
                          : "mt-3 w-fit border-b-[2px] border-olivine-500 border-opacity-0 px-4 py-2 text-gray-500 sm:px-10"
                      }
                    >
                      <p className="cursor-pointer font-semibold">
                        Available Slots
                      </p>
                    </button>
                  )}
                </Tab>

                <Tab as={Fragment}>
                  {({ selected }) => (
                    /* Use the `selected` state to conditionally style the selected tab. */
                    <button
                      className={
                        selected
                          ? "mt-3 w-fit border-b-[2px] border-olivine-500 border-opacity-100 px-4 py-2 text-olivine-500 outline-none sm:px-10"
                          : "mt-3 w-fit border-b-[2px] border-olivine-500 border-opacity-0 px-4 py-2 text-gray-500 sm:px-10"
                      }
                    >
                      <p className="cursor-pointer font-semibold">
                        How it Works
                      </p>
                    </button>
                  )}
                </Tab>

                <Tab as={Fragment}>
                  {({ selected }) => (
                    /* Use the `selected` state to conditionally style the selected tab. */
                    <button
                      className={
                        selected
                          ? "mt-3 w-fit border-b-[2px] border-olivine-500 border-opacity-100 px-4 py-2 text-olivine-500  outline-none sm:px-10"
                          : "mt-3 w-fit border-b-[2px] border-olivine-500 border-opacity-0 px-4 py-2 text-gray-500 sm:px-10"
                      }
                    >
                      <p className="cursor-pointer font-semibold">
                        Frequently Asked Questions
                      </p>
                    </button>
                  )}
                </Tab>
              </Tab.List>

              <Tab.Panels>
                <Tab.Panel>
                  <div
                    ref={gropoolRef}
                    className="mt-10 grid gap-4 grid-auto-fit-md"
                  >
                    <div
                      onClick={() => setOpenGropoolModal(true)}
                      className="min-w-80 w-full cursor-pointer rounded-xl border-[1px] border-gray-200 bg-[#F9F9F9] p-4 hover:border-gray-300"
                    >
                      <div className="mt-3 flex items-center justify-between">
                        <div className="w-fit flex-shrink-0 rounded-full bg-[#558223] p-2">
                          <FaPlus className="h-5 w-5 text-white" />
                        </div>
                        <div>
                          <BiArrowBack className="h-5 w-5 rotate-180 text-gray-600" />
                        </div>
                      </div>
                      <div className="mt-4">
                        <p className="text-base font-medium text-gray-600">
                          Can’t Find a suitable group?
                        </p>
                        <p className="mt-1 text-xl font-semibold text-[#558223]">
                          Create a New Group now
                        </p>
                      </div>
                    </div>
                    {availableSlotLists.map((availableSlotList, index) => {
                      return (
                        <AvailableSlotsComponent
                          key={index}
                          category={availableSlotList.category}
                          name={availableSlotList.name}
                          image={availableSlotList.image}
                          completedStatus={availableSlotList.completedStatus}
                          slotPrice={availableSlotList.slotPrice}
                          slotDate={availableSlotList.slotDate}
                        />
                      );
                    })}
                  </div>
                </Tab.Panel>

                <Tab.Panel>
                  <div className="mt-10 max-w-5xl space-y-6">
                    {fetchedHowItWorks && fetchedHowItWorks?.length
                      ? fetchedHowItWorks?.map((hiw, idx) => (
                          <div
                            key={idx}
                            className="mt-3 flex w-full flex-shrink-0 flex-col rounded-lg border-[1px] border-[#558223]/10 bg-[#E8F3D4] p-4 sm:flex-row sm:p-6"
                          >
                            <div className="flex h-8 w-8 flex-shrink-0 items-center justify-center rounded-full bg-[#558223]">
                              <p className="text-sm font-semibold text-white">
                                {hiw?.sharing_step}
                              </p>
                            </div>
                            <div className="mt-1 sm:ml-3 sm:mt-0">
                              <h3 className="text-lg font-bold text-gray-600">
                                {hiw?.sharing_title}
                              </h3>
                              <p className="mt-1 text-sm font-normal text-gray-600">
                                {hiw?.sharing_description}
                              </p>
                            </div>
                          </div>
                        ))
                      : null}
                  </div>
                </Tab.Panel>

                <Tab.Panel>
                  <div className="mt-10 space-y-6">
                    {fetchedFAQ && fetchedFAQ.length
                      ? fetchedFAQ?.map((faq) => (
                          <GropoolDisclosureComponent
                            question={faq?.question}
                            answer1={faq?.answer}
                          />
                        ))
                      : null}
                    {/* <GropoolDisclosureComponent
                      question="What is Gropool?"
                      answer1="Gropool is a feature that allows Grocedy users to split the cost of expensive or bulk products by purchasing “slots.” Each slotrepresents a share of the product. Once all slots are sold, the product is distributed among buyers."
                    />
                    <GropoolDisclosureComponent
                      question="What is a “slot” in Gropool?"
                      answer1={`A "slot" is a portion of the product. For example, if a cow has 10 slots, buying one  slot means you own 1/10th of the cow.s.`}
                    />
                    <GropoolDisclosureComponent
                      question="How do slots work?"
                      answer1={`Each product is divided into a set number of slots. You can buy one or more slots, and each slot  represents a portion of the product. For example, if a cow is divided into 10 slots, buying 2 slots means you’ll own 20% of the cow.`}
                    />
                    <GropoolDisclosureComponent
                      question="What happens if not all slots are fillled?"
                      answer1={`If all the slots aren't filled by the deadline, you’ll have two options: `}
                      answer2={`1. The cost of the unsold slots is split among the buyers, and you’ll be asked to cover the difference.`}
                      answer3={`2. If you don’t want to pay the extra cost, the transaction will be canceled, and you’ll get a full refund.`}
                    />
                    <GropoolDisclosureComponent
                      question="What happens if not all slots are fillled?"
                      answer1={`If some slots are not purchased by the deadline: `}
                      answer2={`1. Grocedy will redistribute the cost of the unfilled slots among the buyers who have already purchased.`}
                      answer3={`2. If buyers do not want to pay extra, the transaction may be canceled, and you will be refunded..`}
                    />
                    <GropoolDisclosureComponent
                      question="Can I gift a slot to someone else?"
                      answer1={`Yes! You can buy a slot (or more) as a gift for someone else. Just choose the “Gift a Slot” option when selecting your slots. `}
                    />
                    <GropoolDisclosureComponent
                      question="How will I know if my purchase is  successful?"
                      answer1={`You will receive notifications at every stage:`}
                      answer2={` -When you purchase your slot(s)`}
                      answer3={`-As the deadline approaches`}
                      answer4={`-If there are additional costs due to unfilled slots`}
                      answer5={`-When the product is ready for distribution.`}
                    />
                    <GropoolDisclosureComponent
                      question="Can I gift a slot to someone else?"
                      answer1={`Yes! You can buy a slot (or more) as a gift for someone else. Just choose the “Gift a Slot” option when selecting your slots. `}
                    />
                    <GropoolDisclosureComponent
                      question="How do I track the status of my  purchase?"
                      answer1={`After purchasing, you’ll receive notifications and updates about how many slots are left and if additional payments are needed. You can also track your purchase in the Gropool  section of your Grocedy account.`}
                    />
                    <GropoolDisclosureComponent
                      question="What if I don’t want to pay extra for unfilled slots?"
                      answer1={`No problem! You will be notified if additional payments are required. If you choose not to pay, the transaction will be canceled, and you will receive a full refund.`}
                    />
                    <GropoolDisclosureComponent
                      question="Is there a time limit for filling all  the slots?"
                      answer1={`Yes, each Gropool pool has a deadline. You will be informed of the time limit when purchasing your slots, and you will receive reminders as the deadline approaches.`}
                    />
                    <GropoolDisclosureComponent
                      question="When do I receive my portion of the product?"
                      answer1={`Once all slots are filled and the purchase is finalized, your portion will be prepared and distributed to you. You’ll be notified when it’s ready for delivery `}
                    />
                    <GropoolDisclosureComponent
                      question="Can I cancel my slot purchase?"
                      answer1={`Slot purchases are non-refundable unless the Gropool deadline passes and not all slots are filled. In this case, you will receive a full refund if you choose not to cover the remaining cost. `}
                    /> */}
                  </div>
                </Tab.Panel>
              </Tab.Panels>
            </Tab.Group>
          </div>
          <>
            <Transition appear show={openGropoolModal} as={Fragment}>
              <Dialog
                as="div"
                className="relative z-50"
                onClose={() => setOpenGropoolModal(false)}
              >
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="fixed inset-0 bg-black/25" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                  <div className="flex min-h-full items-center justify-center p-4 text-center">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-out duration-300"
                      enterFrom="opacity-0 scale-95"
                      enterTo="opacity-100 scale-100"
                      leave="ease-in duration-200"
                      leaveFrom="opacity-100 scale-100"
                      leaveTo="opacity-0 scale-95"
                    >
                      <Dialog.Panel className="w-full max-w-xl transform overflow-hidden rounded-xl bg-white text-left align-middle shadow-xl transition-all">
                        <div className="scrollbar-thumb-[#1a3517] scrollbar-track-[#265426] h-full max-h-[525px] overflow-y-scroll p-2 scrollbar-thin">
                          <div
                            onClick={() =>
                              setOpenGropoolModal(!openGropoolModal)
                            }
                            className="fixed right-4 top-2 w-fit cursor-pointer rounded-full bg-[#558223] p-1.5 text-white"
                          >
                            <IoClose className="h-5 w-5" />
                          </div>

                          <CreateNewGropool />
                        </div>
                      </Dialog.Panel>
                    </Transition.Child>
                  </div>
                </div>
              </Dialog>
            </Transition>

            <Transition appear show={openSelectSlotModal} as={Fragment}>
              <Dialog
                as="div"
                className="relative z-50"
                onClose={() => setOpenSelectSlotModal(false)}
              >
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="fixed inset-0 bg-black/25" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                  <div className="flex min-h-full items-center justify-center p-4 text-center">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-out duration-300"
                      enterFrom="opacity-0 scale-95"
                      enterTo="opacity-100 scale-100"
                      leave="ease-in duration-200"
                      leaveFrom="opacity-100 scale-100"
                      leaveTo="opacity-0 scale-95"
                    >
                      <Dialog.Panel className="w-full max-w-xl transform overflow-hidden rounded-xl bg-white text-left align-middle shadow-xl transition-all">
                        <div className="scrollbar-thumb-[#1a3517] scrollbar-track-[#265426] h-full max-h-[525px] overflow-y-scroll p-2 scrollbar-thin">
                          <div
                            onClick={() =>
                              setOpenSelectSlotModal(!openSelectSlotModal)
                            }
                            className="fixed right-4 top-2 w-fit cursor-pointer rounded-full bg-[#558223] p-1.5 text-white"
                          >
                            <IoClose className="h-5 w-5" />
                          </div>
                          <SelectSlotPurchaseOptions />
                        </div>
                      </Dialog.Panel>
                    </Transition.Child>
                  </div>
                </div>
              </Dialog>
            </Transition>
          </>
        </Container>
      </div>
    </>
  );
};

export default GropoolPage;
