import React, { useEffect, useState } from "react";
import Logo from "../assets/logo.svg";
import Ospan from "../assets/ospan.jpg";
import { Link, useNavigate } from "react-router-dom";
import { BiMenuAltRight } from "react-icons/bi";
import { CgClose } from "react-icons/cg";
import { IoLogOut } from "react-icons/io5";
import {
  IoMdWallet,
  IoMdNotifications,
  IoMdHome,
  IoMdCart,
} from "react-icons/io";
import { RiSettings4Fill } from "react-icons/ri";
import SignedInHeaderItems from "./SignedInHeaderItems";
import SignedInMobileNavigation from "./SignedInMobileNavigation";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../store/slices/authSlice";
import { getProducts, getUserInfo, productPurchaseGet } from "../store/actions";
// import { GoogleLogout} from 'react-google-login';
import { Menu } from "@headlessui/react";
import { authService } from "../services/authService";

function SignedInNavbar() {
  let navigate = useNavigate();
  const [fectcheUserProfile, setFectcheUserProfile] = useState({});
  const [fetchedProducts, setFectchedProducts] = useState([]);
  const [fetchedCart, setFetchedCart] = useState();
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const verification_code = localStorage.getItem("grocedy_verification_code");
  const user_email = localStorage.getItem("grocedy_user_email");

  const { userInfo, products } = useSelector((state) => state.masterData);
  const { cart } = useSelector((state) => state.cart);
  const corporateId = authService.getCustomerCorporateId();
  const customerId = authService.getCurrentUserCustomerId();

  useEffect(() => {
    if (!userInfo) {
      const data = {
        sql_ref_no: "18",
        user_email_address: user_email,
        verification_code,
        sql_option: "1",
      };

      dispatch(getUserInfo(data));
    }

    if (userInfo) setFectcheUserProfile(userInfo);
  }, [dispatch, userInfo, verification_code, user_email]);

  useEffect(() => {
    if (!products) {
      const data = {
        sql_ref_no: "46",
        corporateId: corporateId ? +corporateId : 1,
        customer_id: customerId?.toString(),
        option: "1",
      };

      dispatch(getProducts(data))
        .unwrap()
        .catch((err) => {
          navigate("/login");

          return err;
        });
    }

    if (products) setFectchedProducts(products);
  }, [dispatch, products, navigate]);

  useEffect(() => {
    if (!cart && user_email) {
      const data = {
        user_email,
      };

      dispatch(productPurchaseGet(data))
        .unwrap()
        .catch((err) => {
          navigate("/login");

          return err;
        });
    }
  }, [dispatch, cart, user_email, navigate]);

  useEffect(() => {
    if (cart) {
      setFetchedCart(cart);
    }
  }, [cart]);

  const handleLogout = () => {
    dispatch(logout());
    navigate("/login");
  };

  const handleRedirect = (location, productId) => {
    switch (location) {
      case "plan":
        navigate(`/home/plans/${productId}`);
        break;
      case "cart":
        navigate("/cart");
        break;
      case "home":
        navigate("/home");
        break;
      default:
        return;
    }
  };

  return (
    <header className="sticky top-0 z-40 flex h-24 items-center justify-between bg-white text-xs shadow-sm ">
      <div className="pl-4 sm:pl-8">
        <Link to="/home">
          <img src={Logo} className="h-20 w-28 cursor-pointer" alt="logo" />
        </Link>
      </div>
      <div className="">
        <div className="hidden items-center space-x-2 pr-2 md:flex lg:space-x-4 lg:pr-8">
          <SignedInHeaderItems
            onClick={() => handleRedirect("home")}
            Icon={<IoMdHome className="h-5 w-5" />}
            title="HOME"
          />
          <div class="relative inline-block text-left">
            <Menu>
              <div>
                <Menu.Button class="inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-xs font-bold text-gray-500 shadow-sm hover:bg-gray-200 hover:text-olivine-500 focus:text-olivine-500 focus:outline-none focus:ring-2 focus:ring-olivine-500 focus:ring-offset-2 focus:ring-offset-gray-100">
                  BUY
                </Menu.Button>
              </div>
              <Menu.Items class="absolute right-0 mt-2 flex w-52 origin-top-right flex-col divide-y divide-gray-200 rounded-md bg-gray-100 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                {fetchedProducts &&
                  fetchedProducts?.map((fp, idx) => (
                    <div
                      key={idx}
                      className="cursor-pointer rounded-t-md px-4 py-2 hover:bg-gray-200"
                    >
                      <span
                        onClick={() => handleRedirect("plan", fp?.product_id)}
                        key={idx}
                        // href="#"
                        class="block text-sm font-semibold text-gray-500 hover:text-gray-600"
                      >
                        {fp?.name}
                      </span>
                    </div>
                  ))}
              </Menu.Items>
            </Menu>
          </div>
          <SignedInHeaderItems
            Icon={<IoMdWallet className="h-4 w-4" />}
            title="WALLET"
          />
          <div className="relative">
            <div onClick={() => handleRedirect("cart")}>
              <SignedInHeaderItems
                Icon={<IoMdCart className="h-4 w-4" />}
                title="MY CART"
              />
            </div>
            {fetchedCart && fetchedCart?.items?.length > 0 ? (
              <div className="absolute -top-1 ml-4 grid h-4 w-5 rounded-full bg-red-500 text-white">
                <div className="m-auto text-xs font-semibold">
                  {fetchedCart &&
                    fetchedCart?.items?.length > 0 &&
                    fetchedCart?.items?.length}
                </div>
              </div>
            ) : null}
          </div>
          <div className="relative">
            <div>
              <SignedInHeaderItems
                Icon={<IoMdNotifications className="h-4 w-4" />}
                title="NOTIFICATIONS"
              />
            </div>
            <div className="absolute -top-1 ml-3 grid h-4 w-5 rounded-full bg-red-500 text-white">
              <div className="m-auto text-xs font-semibold">10</div>
            </div>
          </div>
          <SignedInHeaderItems
            Icon={<RiSettings4Fill className="h-4 w-4" />}
            title="SETTINGS"
          />
          <SignedInHeaderItems
            onClick={handleLogout}
            Icon={<IoLogOut className="h-4 w-4 text-gravel-400" />}
            title="LOG OUT"
          />
          <span className="hidden md:flex">
            {fectcheUserProfile &&
            fectcheUserProfile[0]?.profile_image_refno ? (
              <img
                src={fectcheUserProfile[0]?.profile_image_refno}
                className="aspect-auto h-20 w-20 rounded-full object-cover"
                alt=""
              />
            ) : (
              <img
                src={Ospan}
                className="aspect-auto h-20 w-20 rounded-full object-cover"
                alt=""
              />
            )}
          </span>
        </div>
        <div onClick={() => setOpen(!open)} className="pr-4 md:hidden">
          {open ? (
            <CgClose className=" h-8 w-10 cursor-pointer text-gravel-400" />
          ) : (
            <BiMenuAltRight className=" h-10 w-14 cursor-pointer text-gravel-400" />
          )}
        </div>
      </div>
      {open && <SignedInMobileNavigation />}
    </header>
  );
}

export default SignedInNavbar;
