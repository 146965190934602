import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { CgClose } from "react-icons/cg";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import RxjsStoreContext from "../../../context/RxjsStoreContext";
import { authService } from "../../../services/authService";
import OtpInput from "react-otp-input";
import {
  activePlans,
  checkoutBasket,
  createSubscriptionNote,
  fetchGrocedyForAll,
  getProducts,
  getWalletBalance,
  giftCheckoutBasket,
  verifyTransactionPinCode,
} from "../../../store/actions";

const OrderSummaryPopover = ({ onClose, handleOrder, customGiftMyBasket }) => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const [fetchedUserBalance, setFetchedUserBalance] = useState();
  const [fetchedDeliveryCharge, setFetchedDeliveryCharge] = useState("");
  const [customBasket, setCustomBasket] = useState({});
  const [otp, setOtp] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const customerId = authService.getCurrentUserZohoCustomerId();
  const userId = authService.getCurrentUserID();
  const verification_code = authService.getCurrentUserVerificationCode();
  const delgroCustomerId = authService.getCurrentCustomerId();
  const zohoCustomerId = authService.getCurrentUserZohoCustomerId();
  // const [deliveryFees, setDeliveryFees] = useState(0);
  // const token = authService.getToken();

  const rxjsStore = useContext(RxjsStoreContext);
  const [basketProductMap, setBasketProductMap] = useState(new Map());
  const { giftProfileDetails, giftDelivery, comments } = useSelector(
    (state) => state.modal
  );
  const { coupon } = useSelector((state) => state.cart);
  const { grocedyForAll } = useSelector((state) => state.masterData);
  const { totalBasketAmount } = useSelector((state) => state.modal);
  const { walletBalance } = useSelector((state) => state.user);

  useEffect(() => {
    if (!grocedyForAll) {
      dispatch(fetchGrocedyForAll(customerId ? customerId : "0"))
        .unwrap()
        .catch((err) => {
          navigate("/login");

          return err;
        });
    }

    if (grocedyForAll && grocedyForAll?.delivery_charge?.length) {
      setFetchedDeliveryCharge(grocedyForAll?.delivery_charge[0]);
    }
  }, [grocedyForAll, navigate, customerId]);

  useEffect(() => {
    rxjsStore.getUserSelectedBasket().subscribe((data) => {
      let productSelected = new Map(data);
      setBasketProductMap(productSelected);
    });

    return () => {
      updateBasketList();
      //   rxjsStore.saveBasketTemporarily();
    };
  }, []);

  useEffect(() => {
    if (!walletBalance) {
      const data = {
        sql_ref_no: "31",
        user_id: userId?.toString(),
        customer_id: customerId?.toString(),
        verification_code,
        option: "1",
      };
      dispatch(getWalletBalance(data))
        .unwrap()
        .catch((err) => {
          if (err) {
            authService.logOut();
            return navigate("/login");
          }
        });
    }

    if (walletBalance) setFetchedUserBalance(walletBalance);
  }, [
    dispatch,
    userId,
    customerId,
    verification_code,
    walletBalance,
    navigate,
  ]);

  //  For custom basket
  useEffect(() => {
    let tempCustomBasket = {
      customer: {
        display_name: `${giftProfileDetails?.lname} ${giftProfileDetails?.fname}`,
        salutation: giftProfileDetails?.title,
        first_name: giftProfileDetails?.fname,
        last_name: giftProfileDetails?.lname,
        email: giftProfileDetails?.email,
        company_name: `${giftProfileDetails?.lname} ${giftProfileDetails?.fname}`,
        shipping_address: {
          attention: `${giftProfileDetails?.lname} ${giftProfileDetails?.fname}`,
          // street: giftDelivery?.addr1,
          // city: giftDelivery?.selectedAreaId,
          state: giftDelivery?.city,
          // zip: +giftDelivery?.zipcode,
          country: giftDelivery?.country,
          // fax: 4527389,
          phone: giftDelivery?.phone,
        },
        payment_terms: 1,
        payment_terms_label: "Due On Receipt",
      },
      custom_fields: [
        {
          label: "IsGift",
          value: "true",
        },
      ],
      plan_name: "Custom Basket",
      notes: comments ? comments : "",
      discount: "00.00%",
      customer_id: authService.getCurrentUserZohoCustomerId(),
      shipping_charge: fetchedDeliveryCharge?.item_rate?.toString(),
      adjustment: 0,
      adjustment_description: "custom basket item",
      exchange_rate: 1,
      totalAmount: 0,
      line_items: [],
    };
    rxjsStore.getUserSelectedBasket().subscribe((productsListMap) => {
      for (let [key, product] of productsListMap) {
        const tempItems = {
          item_id: product.item_id,
          name: product.name,
          rate: product.item_rate_zoho,
          quantity: product.qty,
          unit: product.unit,
          item_total: product.qty * Number(product.item_rate_zoho),
        };
        tempCustomBasket.line_items.push(tempItems);
      }
      // setCounter((prev) => {
      //   return prev + 1;
      // });
      setCustomBasket(tempCustomBasket);
    });

    tempCustomBasket.totalAmount =
      tempCustomBasket?.line_items?.length > 0
        ? tempCustomBasket?.line_items?.reduce((a, b) => a + b.item_total, 0)
        : 0;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchedDeliveryCharge?.item_rate]);

  const updateBasketList = () => {
    for (let [_, product] of basketProductMap) {
      rxjsStore.setUserSelectedBasket(product, product.qty);
    }
  };

  function displayProduct() {
    const comps = [];
    basketProductMap.forEach((product, key) =>
      comps.push(
        <div
          key={product?.item_id}
          className="mb-2 mt-1 flex justify-start font-medium text-gray-600"
        >
          {product?.name} (Duration:
          {product?.JSON_String?.plan?.isRecurring === "yes"
            ? `${product?.JSON_String?.plan?.number_of_months} months`
            : "1 month"}
          )
        </div>
      )
    );

    return comps;
  }

  function wait(ms) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve();
      }, ms);
    });
  }

  const checkPin = async () => {
    try {
      const toastId = toast.loading("Checking Pin...");
      setIsLoading(true);

      const verifyData = {
        sql_ref_no: "41",
        user_id: userId?.toString(),
        customer_id: delgroCustomerId?.toString(),
        verification_code,
        pin_number: otp,
        option: "1",
      };

      dispatch(verifyTransactionPinCode(verifyData))
        .unwrap()
        .then((res) => {
          if (res && res.message_id !== 1) {
            setIsLoading(false);
            return toast.error(
              "Pin Incorrect...Please create or reset your pin!",
              { id: toastId }
            );
          }

          handleSubmit();
          setIsLoading(false);

          return toast.success("Pin Correct...", { id: toastId });
        })
        .catch((Err) => {
          toast.error("Pin Incorrect...Please create or reset your pin!", {
            id: toastId,
          });
        });
    } catch (err) {
      return err;
    }
  };

  const handleSubmit = async () => {
    try {
      if (totalBasketAmount > fetchedUserBalance?.available_balance) {
        return toast.error("You have insufficient funds...");
      }

      let yourDate = new Date();

      const toastId = toast.loading("Creating your subscription...");

      const myBaskets = [];
      basketProductMap.forEach((product) => myBaskets.push(product));

      const myPayload = myBaskets?.map((myPay) => {
        const addonsArray = myPay?.JSON_String?.addons;
        let subtotalPrice = 0;
        if (addonsArray && addonsArray.length) {
          const totalPrice = addonsArray?.map(
            (tArray) => tArray.price_brackets.totalPrice
          );

          subtotalPrice = totalPrice?.reduce((a, b) => a + b);
        }

        const payload = {
          customer: {
            display_name: `${giftProfileDetails?.lname} ${giftProfileDetails?.fname}`,
            salutation: giftProfileDetails?.title,
            first_name: giftProfileDetails?.fname,
            last_name: giftProfileDetails?.lname,
            email: giftProfileDetails?.email,
            company_name: `${giftProfileDetails?.lname} ${giftProfileDetails?.fname}`,
            shipping_address: {
              attention: `${giftProfileDetails?.lname} ${giftProfileDetails?.fname}`,
              street: giftDelivery?.addr1,
              city: giftDelivery?.selectedAreaId,
              state: giftDelivery?.city,
              zip: +giftDelivery?.zipcode,
              country: giftDelivery?.country,
              fax: 4527389,
              phone: giftDelivery?.phone,
            },
            payment_terms: 1,
            payment_terms_label: "Due On Receipt",
          },
          custom_fields: [
            {
              label: "IsGift",
              value: "true",
            },
          ],
          customer_id: customerId,
          delgro_customer_id: delgroCustomerId.toString(),
          user_id: userId?.toString(),
          verification_code: verification_code?.toString(),
          starts_at: yourDate.toISOString().split("T")[0],
          auto_collect: false,
          add_to_unbilled_charges: true,
          create_backdated_invoice: true,
          can_charge_setup_fee_immediately: false,
          allow_partial_payments: true,
          amount: (+myPay.rate * myPay.qty + subtotalPrice).toString(),
          plan: {
            plan_code: myPay?.JSON_String?.plan?.plan_code,
            quantity: myPay?.qty?.toString(),
            price: +myPay?.JSON_String?.plan?.plan_price,
            billing_cycles: myPay?.JSON_String?.plan?.isRecurring
              ? +myPay?.JSON_String?.plan?.number_of_months
              : -1,
          },
          addons: myPay?.JSON_String?.addons?.length
            ? myPay?.JSON_String?.addons?.map((addon) => ({
                addon_code: addon?.addon_code,
                price: addon?.price_brackets?.price,
                quantity: addon?.price_brackets?.quantity?.toString(),
              }))
            : [],
          coupon_code: coupon ? coupon?.coupon_code : "",
          deliveryFees: giftProfileDetails?.totalDeliveryFee,
        };

        return payload;
      });

      await Promise.all(
        myPayload?.map(async (payL, index) => {
          wait(5000 * index)
            .then(async () => {
              return await dispatch(checkoutBasket(payL))
                .unwrap()
                .then((res) => {
                  if (res && res?.status === 403) {
                    return toast.error(res?.response?.error, {
                      id: toastId,
                    });
                  }

                  if (!res || !res?.Result) {
                    return toast.error(
                      "Gift Subscriptions Could not be created...",
                      {
                        id: toastId,
                      }
                    );
                  }

                  toast.success("Gift Subscription Created...", {
                    id: toastId,
                  });

                  dispatch(activePlans(zohoCustomerId));

                  if (comments) {
                    const data = {
                      description: comments,
                      subscription_refno: res.Message_Ref_No,
                    };

                    dispatch(createSubscriptionNote(data));
                  }

                  rxjsStore.clearCustomBasket();

                  const data = {
                    sql_ref_no: "31",
                    user_id: userId.toString(),
                    customer_id: customerId.toString(),
                    verification_code,
                    option: "1",
                  };

                  dispatch(getWalletBalance(data));

                  navigate("/home");

                  return res;
                })
                .catch((err) => {
                  toast.error("Gift Subscriptions Could not be created...", {
                    id: toastId,
                  });
                  return err;
                });
            })
            .catch((err) => err);
        })
      );

      onClose();
      handleOrder();
    } catch (err) {
      return err;
    }
  };

  const handleCustomGiftSubmit = async (CustomBasket) => {
    try {
      const toastId = toast.loading("Checking Pin...");
      setIsLoading(true);

      const verifyData = {
        sql_ref_no: "41",
        user_id: userId?.toString(),
        customer_id: customerId?.toString(),
        verification_code,
        pin_number: otp,
        option: "1",
      };
      dispatch(verifyTransactionPinCode(verifyData))
        .unwrap()
        .then((res) => {
          if (res && res.message_id !== 1) {
            setIsLoading(false);
            return toast.error(
              "Pin Incorrect...Please create or reset your pin!",
              { id: toastId }
            );
          }

          toast.success("Pin Correct...", { id: toastId });

          dispatch(giftCheckoutBasket(CustomBasket))
            .unwrap()
            .then((res) => {
              if (res?.status !== 200) {
                return toast.error(res.message, { id: toastId });
              }
              toast.success("Successfully Submitted Custom Gift Basket", {
                id: toastId,
              });
              return res;
            })
            .catch((err) => {
              toast.error("Failed to Submit Custom Gift Basket", {
                id: toastId,
              });
              return err;
            });

          // paymentProceed();
          setIsLoading(false);
          // return toast.success("Pin Correct...", { id: toastId });
        })
        .catch((Err) => {
          toast.error("Pin Incorrect...Please create or reset your pin!", {
            id: toastId,
          });
        });
    } catch (err) {
      return err;
    }
  };

  return (
    <div className="mt-28 h-min w-full max-w-md rounded-2xl bg-white px-3 py-3 text-gray-500 xsm:px-6 xsm:py-4">
      <div className="flex items-center justify-between">
        <div className="font-semibold text-gray-600 sm:text-xl">
          Order Summary (3/3)
        </div>
        <button
          onClick={() => {
            onClose();
            handleOrder();
          }}
          className="rounded-full p-1 hover:bg-gray-100 active:bg-gray-200"
        >
          <CgClose className="h-5 w-5 text-gray-600" />
        </button>
      </div>
      <div className="mt-2 flex justify-start text-sm">Your order summary</div>
      <div className="mt-6 text-sm font-medium">
        <div className="flex justify-start text-gray-600 opacity-60">
          You are gifting:
        </div>
        <div className="text-gray-800">{displayProduct()}</div>
      </div>
      <div className="mt-4 text-sm font-semibold">
        <div className="flex justify-start text-gray-600 opacity-60">
          Recipient:
        </div>
        <div className="flex justify-start font-medium text-gray-600">{`${giftProfileDetails?.fname} ${giftProfileDetails?.lname}`}</div>
      </div>
      <div className="mt-6 text-sm font-semibold">
        <div className="flex justify-start text-gray-600 opacity-60">
          Delivery Address:
        </div>
        <div className="flex justify-start font-medium text-gray-600">
          {giftDelivery?.addr1}
        </div>
      </div>
      <div className="mt-5">
        <label className="text-start text-xs font-semibold text-gray-600">
          Please input your transaction PIN
        </label>
        <OtpInput
          inputStyle={{
            margin: 3,
            height: 30,
            width: 35,
            borderRadius: 4,
            borderColor: "#BDBDBD",
            outline: "none",
            border: "1px solid #BDBDBD",
          }}
          value={otp}
          onChange={setOtp}
          numInputs={4}
          renderInput={(props) => <input {...props} />}
        />
      </div>
      <div className="mt-6 flex justify-between pb-4 text-center tracking-widest">
        <button
          onClick={() => {
            onClose();
            handleOrder();
          }}
          className="rounded-md border-[1px] border-olivine-500  px-6 py-2.5 text-sm text-olivine-500 transition duration-300 hover:bg-olivine-100 active:ring-1 active:ring-olivine-500 sm:px-8"
        >
          BACK
        </button>
        {!customGiftMyBasket ? (
          <button
            onClick={() => checkPin()}
            disabled={otp?.length === 4 ? false : true}
            className="rounded-md bg-olivine-500 px-8 py-2.5 text-sm text-white transition duration-300 hover:bg-olivine-400 active:bg-olivine-600 disabled:opacity-30 disabled:hover:bg-olivine-500 sm:px-10"
          >
            SUBMIT
          </button>
        ) : (
          <button
            onClick={() => handleCustomGiftSubmit(customBasket)}
            className="rounded-md bg-olivine-500 px-8 py-2.5 text-sm text-white transition duration-300 hover:bg-olivine-400 active:bg-olivine-600 disabled:opacity-30 disabled:hover:bg-olivine-500 sm:px-10"
          >
            NEXT
          </button>
        )}
      </div>
    </div>
  );
};

export default OrderSummaryPopover;
