import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import SignedInNavbarImproved from "../../SignedInNavbarImproved";
import { useDispatch, useSelector } from "react-redux";
import { fetchGrocedyForAll } from "../../../store/actions";
import { setRate } from "../../../store/slices/generalSlice";
import GiftAPlanPageCards from "./GiftAPlanPageCards";
import IsLoadingOne from "../../IsLoading_One";
import { setIsGifting } from "../../../store/slices/modalSlice";
import { authService } from "../../../services/authService";

function GiftAPlan() {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const [fetchedPlans, setFetchedPlans] = useState([]);
  const [fetchedImages, setFetchedImages] = useState([]);
  const [fetchedPlansUsd, setFetchedPlansUsd] = useState([]);
  const [usdPlan, setUsdPlan] = useState({});
  const [isShowingNGR, setIsShowingNGR] = useState(true);
  const [isShowingUSD, setIsShowingUSD] = useState(false);
  const [productId, setProductId] = useState("");
  const [fetchedProducts, setFectchedProducts] = useState({});

  const { grocedyForAll } = useSelector((state) => state.masterData);
  const { loading } = useSelector((state) => state.error);
  const customerId = authService.getCurrentUserCustomerId();

  useEffect(() => {
    if (!grocedyForAll) {
      dispatch(fetchGrocedyForAll(customerId ? customerId : "0"))
        .unwrap()
        .catch((err) => {
          return err;
        });
    }

    if (grocedyForAll) {
      setFectchedProducts(grocedyForAll);
    }
  }, [grocedyForAll, navigate, customerId]);

  useEffect(() => {
    if (fetchedProducts) {
      const selectedPlan =
        fetchedProducts?.product_info?.length &&
        fetchedProducts?.product_info?.filter(
          (sp) => sp.name === "Food Baskets"
        );

      setProductId(selectedPlan?.length ? selectedPlan[0]?.product_id : "");
    }
  }, [fetchedProducts]);

  useEffect(() => {
    if (
      productId &&
      fetchedProducts?.plan_info?.length &&
      fetchedProducts?.Plan_Image?.length
    ) {
      const filteredPlans =
        fetchedProducts?.plan_info?.length &&
        fetchedProducts?.plan_info?.filter(
          (plan) => plan?.product_id === productId
        );

      setFetchedPlans(filteredPlans);

      const filteredImages =
        fetchedProducts?.Plan_Image?.length &&
        fetchedProducts?.Plan_Image?.filter(
          (image) => image?.product_id === productId
        );

      setFetchedImages(filteredImages);

      localStorage.setItem("selected_plan", productId);
    }
  }, [dispatch, productId, fetchedProducts]);

  useEffect(() => {
    if (fetchedProducts) setFetchedPlansUsd(fetchedProducts?.currency);
  }, [fetchedProducts]);

  useEffect(() => {
    if (fetchedPlansUsd && fetchedPlansUsd?.length) {
      const usdConvert = fetchedPlansUsd?.filter(
        (fpu) => fpu.currency_code === "USD"
      );
      setUsdPlan(usdConvert);
    }
  }, [fetchedPlansUsd]);

  useEffect(() => {
    if (usdPlan && isShowingUSD) {
      dispatch(setRate(usdPlan));
    }

    if (isShowingNGR) dispatch(setRate(""));
  }, [usdPlan, isShowingNGR, dispatch, isShowingUSD]);

  const handleSwitch = (currency) => {
    switch (currency) {
      case "NGR":
        setIsShowingUSD(false);
        setIsShowingNGR(true);
        break;
      case "USD":
        setIsShowingNGR(false);
        setIsShowingUSD(true);
        break;
      default:
        return;
    }
  };

  const handleView = (location) => {
    dispatch(setIsGifting(true));

    switch (location) {
      case "GROBP":
        return navigate(`/home/plan/${location}`);
      case "GROSP":
        return navigate(`/home/plan/${location}`);
      case "GROGP":
        return navigate(`/home/plan/${location}`);
      case "GRODP":
        return navigate(`/home/plan/${location}`);
      case "GROPP":
        return navigate(`/home/plan/${location}`);
      case "home":
        return navigate("/home");
      default:
        return;
    }
  };

  const handleBasket = (id) => {
    navigate(`/basket/my-basket/${id}`);
  };

  return (
    <>
      {loading ? (
        <IsLoadingOne message="loading..." />
      ) : (
        <>
          <SignedInNavbarImproved />
          <div className="relative mx-auto max-w-[1600px] px-3 pb-8 pt-10 xsm:px-4 sm:px-8 md:px-10 lg:px-16">
            <div className="text-2xl font-bold text-gray-600">Plans</div>
            {/* <div className="mt-6 flex cursor-pointer font-sans font-medium">
              <div
                onClick={() => handleSwitch("NGR")}
                className={
                  isShowingNGR
                    ? "rounded-l-md bg-olivine-500 px-5 py-3 text-white"
                    : "rounded-l-md bg-caper-500 px-5 py-3 text-gravel-500"
                }
              >
                NGN
              </div>
              <div
                onClick={() => handleSwitch("USD")}
                className={
                  !isShowingUSD
                    ? "rounded-r-md bg-caper-500 px-5 py-3 text-gravel-500"
                    : "rounded-r-md bg-olivine-500 px-5 py-3 text-white"
                }
              >
                USD
              </div>
            </div> */}
            <div className="">
              <div className="mt-8 grid gap-4 pb-64 grid-auto-fit-xs">
                {fetchedPlans &&
                  isShowingNGR &&
                  !isShowingUSD &&
                  fetchedPlans?.map((plan, idx) => (
                    <GiftAPlanPageCards
                      key={idx}
                      clicked={() => handleView(plan.plan_code)}
                      title={plan.name}
                      plan_id={plan.plan_id}
                      price={plan.recurring_price}
                      quantity={plan.description}
                      goToBasket={() => handleBasket(plan.plan_code)}
                      images={fetchedImages}
                    />
                  ))}

                {fetchedPlans &&
                  !isShowingNGR &&
                  isShowingUSD &&
                  fetchedPlans?.map((plan, idx) => (
                    <GiftAPlanPageCards
                      key={idx}
                      clicked={() => handleView(plan.plan_code)}
                      title={plan.name}
                      plan_id={plan.plan_id}
                      price={plan.recurring_price}
                      quantity={plan.description}
                      rate={usdPlan[0]?.exchange_rate}
                      goToBasket={() => handleBasket(plan.plan_code)}
                      images={fetchedImages}
                    />
                  ))}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default GiftAPlan;
