import React from "react";
import GropoolNoteImage from "../../../../assets/gropoolNoteImage.svg";
import GropoolSuccessImage from "../../../../assets/gropoolsuccessImage.svg";

const DeadlineApproachingPopup = () => {
  return (
    <div className="mb-8 px-2 sm:px-6">
      <div className="mx-auto mt-8 flex max-w-sm flex-shrink-0 flex-col items-center justify-center">
        <div>
          <svg
            width="64"
            height="65"
            viewBox="0 0 64 65"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2.66797 56.1383L32.0013 5.47168L61.3346 56.1383H2.66797ZM32.0013 48.1383C32.7569 48.1383 33.3906 47.8823 33.9026 47.3703C34.4146 46.8583 34.6697 46.2255 34.668 45.4717C34.668 44.7161 34.412 44.0823 33.9 43.5704C33.388 43.0583 32.7551 42.8032 32.0013 42.805C31.2457 42.805 30.612 43.061 30.1 43.573C29.588 44.085 29.3329 44.7179 29.3346 45.4717C29.3346 46.2272 29.5906 46.861 30.1026 47.373C30.6146 47.885 31.2475 48.1401 32.0013 48.1383ZM29.3346 40.1383H34.668V26.805H29.3346V40.1383Z"
              fill="#F73C47"
            />
          </svg>
        </div>
        <h2 className="mt-1 text-center text-lg font-semibold text-gray-700">
          Action Required: Deadline Approaching for Your Group
        </h2>
        <p className="mt-2 text-center text-xs font-medium text-gray-600">
          The deadline for your Cow Group is nearing. Currently, 6 out of 10
          slots are filled, leaving 4 unfilled slots.
        </p>
      </div>

      <div className="mx-auto mt-8 flex max-w-sm flex-col">
        <h3 className="text-center text-xl font-bold text-gray-700">
          Share Group Info
        </h3>
        <p className="mx-auto mt-1 max-w-sm text-center text-xs font-normal text-gray-600">
          When you split the costs, each of the 6 participants will pay an
          additional{" "}
          <span className="font-semibold text-olivine-600">[N50,000 Each]</span>{" "}
          to cover the 4 unfilled slots. This will increase your total number of
          slots to{" "}
          <span className="font-semibold text-olivine-600">
            2 slots each/per buyer.
          </span>
        </p>
        <div className="mx-auto mt-4 max-w-[270px]">
          <button className="w-full transform cursor-pointer rounded-md bg-olivine-500 px-4 py-3.5 text-xs font-semibold text-white transition-all duration-200 hover:bg-olivine-500/80 focus:outline-none active:bg-olivine-600 xsm:text-sm">
            Proceed and pay N50,000
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeadlineApproachingPopup;
