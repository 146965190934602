import React, { useContext, useEffect, useRef, useState } from "react";
import SignedInNavbarImproved from "../../SignedInNavbarImproved";
import { debounce } from "lodash";
import { useNavigate } from "react-router-dom";
import Container from "../my_container/Container";
import FeaturedCarousel from "./FeaturedCarousel";
import { Menu } from "@headlessui/react";
import ProductCarousel from "./ProductCarousel";
import toast from "react-hot-toast";
import { GoSearch } from "react-icons/go";
import { FiChevronDown } from "react-icons/fi";
import { authService } from "../../../services/authService";
import RxjsStoreContext from "../../../context/RxjsStoreContext";
import BasketService from "../../../services/basketService";
import TopDeals from "../create_basket_page/TopDeals";
import AllItems from "../create_basket_page/AllItems";
import SearchPageEmpty from "../create_basket_page/SearchPageEmpty";
import { useDispatch, useSelector } from "react-redux";
import { fetchProductFeatureInfo } from "../../../store/actions";
import RewardCenterAdsPopup from "../reward_center/reward_center_ads/RewardCenterAdsPopup";

const SignedInHomeWelcome = () => {
  const rxjsStore = useContext(RxjsStoreContext);
  const [isLoading, setIsLoading] = useState(false);
  const [basketGroupList, setBasketGroupList] = useState([]);
  const [searchResult, setSearchResult] = useState([]);
  const [currentSection, setCurrentSection] = useState("");
  const [allProductList, setAllProductList] = useState([]);
  const [searchTextInput, setSearchTextInput] = useState("");
  const [searchInputValue, setSearchInputValue] = useState("");
  const [activeindex, setActiveIndex] = useState(0);
  const [width, setWidth] = useState(0);
  const searchRef = React.createRef();
  const carouselTwo = useRef();
  const [fetchedAdsInfo, setFetchedAdsInfo] = useState([]);

  const { products } = useSelector((state) => state.masterData);

  useEffect(() => {
    if (authService.isLogin()) {
      callApiForAuth();
    }
    rxjsStore.getAllBasketProductList().subscribe((allProduct) => {
      setAllProductList(allProduct);
      setSearchResult(allProduct);
    });

    rxjsStore.getBasketGroupList().subscribe((data) => {
      if (data?.length < 1) {
        callApi();
      } else {
        setBasketGroupList(data);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (products?.ad_info?.length) {
      setFetchedAdsInfo(products?.ad_info);
    }
  }, [products]);

  const debouncedSearch = React.useRef(
    debounce(async (event) => {
      processSearch(event);
    }, 1500)
  ).current;

  function callApiForAuth() {
    setIsLoading(true);
    BasketService.getBasketListAuth()
      .then((data, failed) => {
        if (failed) {
          // return toast.error("error getting products");
        }

        setIsLoading(false);
        const { item_group, items, delivery_charge } = data;
        if (item_group?.length) {
          rxjsStore.setBasketGroupList(item_group);
          rxjsStore.setAllBasketProductList(items);
          let charge = {
            item_id: delivery_charge[0]?.item_id,
            item_total: delivery_charge[0]?.item_rate,
            rate: delivery_charge[0]?.item_rate,
            quantity: 1,
            name: "Delivery charge",
          };
          rxjsStore.setDeliveryChargeBasket(charge);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        // toast.error("Error getting product");
      });
  }

  function callApi() {
    setIsLoading(true);
    BasketService.getBasketList()
      .then((data, failed) => {
        if (failed) {
          return toast.error("error getting products");
        }

        setIsLoading(false);
        const { item_group, items, delivery_charge } = data;
        if (item_group?.length) {
          rxjsStore.setBasketGroupList(item_group);
          rxjsStore.setAllBasketProductList(items);

          let charge = {
            item_id: delivery_charge[0]?.item_id,
            item_total: delivery_charge[0]?.item_rate,
            rate: delivery_charge[0]?.item_rate,
            quantity: 1,
            name: "Delivery charge",
          };
          rxjsStore.setDeliveryChargeBasket(charge);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error("Error getting product");
      });
  }

  const resetProductGroup = (groupId) => {
    setActiveIndex(groupId);
    const newList = rxjsStore.getProductByGroup(groupId);
    // setAllProductList(newList);
    setCurrentSection(groupId);
    setSearchResult(newList);
  };

  function processSearch(event) {
    const search = event.target.value;
    setSearchTextInput(search);

    setCurrentSection(search);

    if (search.length < 2) {
      setCurrentSection("");
      rxjsStore.getAllBasketProductList().subscribe((allProduct) => {
        setAllProductList(allProduct);
        setSearchResult(allProduct);
      });
      return;
    } else if (search.length > 2) {
      var tempResult = rxjsStore.handleSearchProduct(search);
      setSearchResult(tempResult);
    }
  }

  return (
    <>
      <SignedInNavbarImproved />
      <div className="bg-[#F5F5F5] pb-60">
        <Container className="pt-8">
          <div>
            <h1 className="text-xl font-bold text-gray-800">Featured</h1>
            <FeaturedCarousel />
          </div>
          <div className="mt-6">
            <h2 className="text-xl font-bold text-gray-800">Buy</h2>
            <ProductCarousel />
          </div>
          <div className="mt-6 rounded-2xl border-[1px] border-gray-100 bg-white px-4 py-6 shadow-md">
            <div>
              <div className="mt-6 flex items-center justify-between">
                <h2 className="text-xl font-bold text-gray-800 ">Items</h2>
                <div className="z-20">
                  <Menu>
                    <Menu.Button className="flex flex-row items-center space-x-1 text-olivine-500">
                      <p className="font-medium">Categories</p>
                      <div className="p- rounded-full p-1 transition duration-200 hover:bg-[#F0F4E7] hover:ring-[1px] hover:ring-gray-200">
                        <FiChevronDown className="h-4 w-4 cursor-pointer" />
                      </div>
                    </Menu.Button>
                    <Menu.Items className="absolute right-14 flex h-full max-h-60 w-60 origin-top-right flex-col divide-y-[1px] divide-gray-200 overflow-y-scroll rounded-lg border-[1px] border-gray-200 bg-white shadow-xl focus:outline-none">
                      {basketGroupList?.length &&
                        basketGroupList.map((category, index) => (
                          <div
                            key={index}
                            onClick={() =>
                              resetProductGroup(category?.group_id)
                            }
                            className="cursor-pointer p-1"
                          >
                            <p className="rounded-md px-3 py-2 pl-8 text-right text-sm text-gray-500 transition duration-200 hover:bg-[#F0F4E7] hover:text-olivine-500">
                              {category?.group_name}
                            </p>
                          </div>
                        ))}
                    </Menu.Items>
                  </Menu>
                </div>
              </div>
              <div className="relative z-10 mx-auto mt-4 w-full max-w-xl lg:-mt-8">
                <input
                  ref={searchRef}
                  onChange={(ev) => {
                    debouncedSearch(ev);
                    setSearchInputValue(ev.target.value);
                  }}
                  type="text"
                  placeholder="Quick search for anything"
                  className="w-full rounded-lg border-gray-300 bg-[#F9F9F9] pl-4 pr-14 text-gray-700 outline-none ring-0 placeholder:font-normal placeholder:text-gray-400 focus:border-olivine-600 focus:outline-none focus:ring-1 focus:ring-olivine-600"
                />
                <div className="absolute bottom-0 right-0 top-0 my-[1.5px] mr-[1.5px] w-11 rounded-r-md bg-gray-200" />
                <div className="absolute right-3 top-3">
                  <GoSearch className="h-5 w-5 cursor-pointer rounded-full text-olivine-500" />
                </div>
              </div>
            </div>
            {/* <div className="mt-6 grid gap-6 grid-auto-fit-sm"> */}
            <div className="mt-6 flex flex-col">
              {/* the cards are coming here */}
              {!currentSection && (
                <>
                  <div>
                    <TopDeals allProductList={allProductList} />
                  </div>
                </>
              )}
              <div>
                {searchResult?.length ? (
                  <AllItems allProductList={searchResult} />
                ) : (
                  <SearchPageEmpty searchResult={searchResult} />
                )}
              </div>
            </div>
            {fetchedAdsInfo?.length ? (
              <RewardCenterAdsPopup adsInfo={fetchedAdsInfo} />
            ) : null}
          </div>
        </Container>
      </div>
    </>
  );
};

export default SignedInHomeWelcome;
