import baseAxios from "../core/api/axios/baseAxios";

function getProductList(data) {
  return baseAxios.post("master-data/products", data);
}

function getGrocedyForAllProductList(customer_id) {
  return baseAxios.get(`master-data/grocedy_for_all/${customer_id}`);
}

export const productService = {
  getProductList,
  getGrocedyForAllProductList,
};
