import React, { useEffect, useState } from "react";
import { AiOutlineCheck, AiOutlineInfo } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { authService } from "../services/authService";
import {
  createAccountNumber,
  documentUploadGet,
  getDelivery,
  getNin,
  getUserInfo,
  todoListStatus,
} from "../store/actions";
import SignedInNavbarImproved from "./SignedInNavbarImproved";
import Modal from "./Ui";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";

function HomeTodoWelcome() {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const [fetchedStatuses, setfetchedStatuses] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [selectedModal, setSelectedModal] = useState("");
  const [statusPercent, setStatusPercent] = useState("");
  const [fectcheUserProfile, setFectcheUserProfile] = useState({});
  // const [isComplete, setIsComplete] = useState(false);
  const {
    todoListStatusData,
    userInfo,
    getDeliveryItems,
    nin,
    uploadedDocuments,
  } = useSelector((state) => state.masterData);

  const { isPastLogin } = useSelector((state) => state.modal);

  const verification_code = authService.getCurrentUserVerificationCode();
  const userId = authService.getCurrentUserID();
  const customerId = authService.getCurrentUserCustomerId();
  const token = authService.getCurrentAuthToken();

  const akant =
    fectcheUserProfile &&
    fectcheUserProfile?.customer_info?.length &&
    fectcheUserProfile?.customer_info[0]?.providus_bank_account_number;

  useEffect(() => {
    if (!akant && fectcheUserProfile?.customer_info?.length) {
      handleCreate();
    }
  }, [akant, fectcheUserProfile?.customer_info?.length]);

  // useEffect(() => {
  //   if (statusPercent && statusPercent === 100) navigate("/login/home");
  // }, [statusPercent, navigate]);

  useEffect(() => {
    if (
      fetchedStatuses?.user_profile === "True" &&
      fetchedStatuses?.phone_number === "True" &&
      fetchedStatuses?.transaction_pin === "True" &&
      fetchedStatuses?.delivery_address === "True" &&
      !isPastLogin
    ) {
      navigate("/home-welcome");
    }
  }, [fetchedStatuses, navigate]);

  useEffect(() => {
    if (!todoListStatusData) {
      const data = {
        sql_ref_no: "20",
        user_id: userId?.toString(),
        verification_code: verification_code?.toString(),
        option: "1",
      };

      dispatch(todoListStatus(data));
    }

    if (todoListStatusData) setfetchedStatuses(todoListStatusData);
  }, [dispatch, todoListStatusData, verification_code, userId]);

  useEffect(() => {
    if (!uploadedDocuments) {
      const data = {
        sql_ref_no: "16",
        user_id: userId?.toString(),
        verification_code,
        customer_id: customerId?.toString(),
        option: "1",
      };

      dispatch(documentUploadGet(data));
    }
  }, [dispatch, uploadedDocuments, verification_code, userId, customerId]);

  useEffect(() => {
    if (!nin) {
      const data = {
        sql_ref_no: "11",
        user_id: userId?.toString(),
        customer_id: customerId?.toString(),
        option: "8",
      };

      dispatch(getNin(data));
    }
  }, [dispatch, customerId, userId, nin]);

  useEffect(() => {
    if (fetchedStatuses) {
      const results = [];
      for (let key in fetchedStatuses) {
        results.push(fetchedStatuses[key]);
      }

      // Return the number of times an array element appears in the array
      const getOccurrence = (array, value) => {
        return array?.filter((v) => v === value)?.length;
      };

      const getPercentage = (array, occ) => {
        return Math.round((occ / array?.length) * 100);
      };

      const yesOcc = getOccurrence(results, "True");

      const yesPercent = getPercentage(results, yesOcc);

      setStatusPercent(yesPercent);
    }
  }, [fetchedStatuses]);

  useEffect(() => {
    if (!userInfo) {
      const data = {
        sql_ref_no: "11",
        user_id: userId?.toString(),
        customer_id: customerId?.toString(),
        option: "7",
      };

      dispatch(getUserInfo(data));
    }

    if (userInfo) setFectcheUserProfile(userInfo);
  }, [dispatch, userInfo, customerId, userId, verification_code]);

  useEffect(() => {
    if (!getDeliveryItems && token) {
      const data = {
        sql_ref_no: "11",
        user_id: userId?.toString(),
        customer_id: customerId?.toString(),
        option: "6",
      };

      dispatch(getDelivery(data));
    }
  }, [getDeliveryItems, dispatch, userId, customerId, token]);

  function handleCreate() {
    const data = {
      sql_ref_no: "28",
      user_id: userId?.toString(),
      customer_id: customerId?.toString(),
      verification_code,
      account_name: `${fectcheUserProfile?.customer_info[0]?.customer_first_name} ${fectcheUserProfile?.customer_info[0]?.customer_last_name}`,
      // bvn: `${fetchedUser?.bank_verification_number}`,
      option: "1",
    };

    dispatch(createAccountNumber(data))
      .unwrap()
      .then((res) => {
        if (res.requestSuccessful === false) {
          return res.responseMessage;
        }

        if (res.message_id !== 1) {
          return res.message_text;
        }

        const data = {
          sql_ref_no: "11",
          user_id: userId?.toString(),
          customer_id: customerId?.toString(),
          option: "7",
        };

        dispatch(getUserInfo(data));
      })
      .catch((err) => {
        return err;
      });
  }

  const handleRedirect = (location) => {
    setShowModal(true);

    switch (location) {
      case "profile":
        setSelectedModal("profile");
        break;
      // return navigate("/login/addPhoneNumber");
      case "addDescription":
        setSelectedModal("addDescription");
        break;
      case "addBvn":
        setSelectedModal("addBvn");
        break;
      case "addNin":
        setSelectedModal("addNin");
        break;
      case "phone":
        setSelectedModal("phone");
        break;
      case "pin":
        setSelectedModal("pin");
        break;
      case "addId":
        setSelectedModal("addId");
        break;
      default:
        return;
    }
  };

  const handleOnClose = () => {
    setShowModal(false);
    setSelectedModal("");
  };

  return (
    <div className="relative">
      {/* <div className="filter blur-sm bg-slate-200"> */}
      <SignedInNavbarImproved />
      <div className="px-2 py-16 tracking-wider xsm:px-3 sm:px-8 md:px-16 lg:px-28">
        <div className="mx-auto max-w-4xl rounded-md border-[1px] border-gray-400 px-2 pt-8 sm:px-4 md:px-8">
          <div>
            <div className="text-lg font-semibold text-gravel-500 sm:text-2xl">
              To-Do List
            </div>
            <div className="mt-2 text-xs font-normal text-gray-500 sm:mt-4 sm:text-sm">
              Complete your to-do to start using Grocedy
            </div>
            <div className="mt-4 text-sm text-gray-500 sm:text-base">
              Your Progress
            </div>
            <div className="mt-1">
              <div className="pl-1 text-lg font-semibold text-gravel-500 sm:text-2xl">
                {statusPercent ? `${statusPercent}% Complete` : "0%"}
              </div>
              <div className="relative mt-1">
                <div className="h-3 w-full rounded-full bg-[#F2FAD3]"></div>
                <div
                  style={{ width: statusPercent + "%" }}
                  className="absolute top-0 h-3 rounded-full bg-olivine-500"
                ></div>
              </div>
            </div>
          </div>
          <div
            onClick={() => handleRedirect("profile")}
            className="mt-8 cursor-pointer rounded-md border-[1px] border-gray-400 text-gray-600 hover:ring-1 hover:ring-olivine-500"
          >
            <div className="flex items-center justify-between px-2 py-3 text-xs font-semibold sm:text-base">
              <div>
                <div>
                  1. Edit your profile details
                  <span className="ml-1 italic text-[#F5A893] sm:ml-2">
                    *Required
                  </span>
                </div>
              </div>
              {fetchedStatuses.user_profile === "True" ? (
                <div className="flex items-center rounded-full bg-[#F2FAD3] px-2 py-2 ring-1 ring-gray-200 xsm:px-4">
                  <div>
                    <AiOutlineCheck className="mr-1 h-3 w-3 rounded-full bg-olivine-500 p-0.5 text-white" />
                  </div>
                  <div className="text-xs text-olivine-500">
                    <div className="text-xs text-olivine-500">Done</div>
                  </div>
                </div>
              ) : (
                <div className="flex items-center space-x-1 rounded-full bg-[#FDECD1] px-2 py-2 ring-1 ring-gray-200 xsm:px-4">
                  <div className="h-3 w-3 rounded-full bg-[#F79E1B] p-0"></div>
                  <div className="text-xs text-[#F79E1B]">Pending</div>
                </div>
              )}
            </div>
          </div>
          <div
            onClick={() => handleRedirect("phone")}
            className="mt-4 cursor-pointer rounded-md border-[1px] border-gray-400 text-gray-600 hover:ring-1 hover:ring-olivine-500"
          >
            <div className="flex items-center justify-between px-2 py-3 text-xs font-semibold sm:text-base">
              <div>
                <div>
                  2. Add your phone number
                  <span className="ml-1 italic text-[#F5A893] sm:ml-2">
                    *Reqired
                  </span>
                </div>
              </div>
              {fetchedStatuses.phone_number === "True" ? (
                <div className="flex items-center rounded-full bg-[#F2FAD3] px-2 py-2 ring-1 ring-gray-200 xsm:px-4">
                  <div>
                    <AiOutlineCheck className="mr-1 h-3 w-3 rounded-full bg-olivine-500 p-0.5 text-white" />
                  </div>
                  <div className="text-xs text-olivine-500">
                    <div className="text-xs text-olivine-500">Done</div>
                  </div>
                </div>
              ) : (
                <div className="flex items-center space-x-1 rounded-full bg-[#FDECD1] px-2 py-2 ring-1 ring-gray-200 xsm:px-4">
                  <div className="h-3 w-3 rounded-full bg-[#F79E1B] p-0"></div>
                  <div className="text-xs text-[#F79E1B]">Pending</div>
                </div>
              )}
            </div>
          </div>
          <div
            onClick={() => handleRedirect("pin")}
            className="mt-4 cursor-pointer rounded-md border-[1px] border-gray-400 text-gray-600 hover:ring-1 hover:ring-olivine-500"
          >
            <div className="flex items-center justify-between px-2 py-3 text-xs font-semibold sm:text-base">
              <div>
                <div>
                  3. Add Transaction Pin
                  <span className="ml-2 italic text-[#F5A893]">*Reqired</span>
                </div>
              </div>
              {fetchedStatuses.transaction_pin === "True" ? (
                <div className="flex items-center rounded-full bg-[#F2FAD3] px-2 py-2 ring-1 ring-gray-200 xsm:px-4">
                  <div>
                    <AiOutlineCheck className="mr-1 h-3 w-3 rounded-full bg-olivine-500 p-0.5 text-white" />
                  </div>
                  <div className="text-xs text-olivine-500">
                    <div className="text-xs text-olivine-500">Done</div>
                  </div>
                </div>
              ) : (
                <div className="flex items-center rounded-full bg-[#FDECD1] px-2 py-2 ring-1 ring-gray-200 xsm:px-4">
                  <div className="mr-1 h-3 w-3 rounded-full bg-[#F79E1B] p-0"></div>
                  <div className="text-xs text-[#F79E1B]">Pending</div>
                </div>
              )}
            </div>
          </div>
          <div
            onClick={() => handleRedirect("addDescription")}
            className="mt-4 cursor-pointer rounded-md border-[1px] border-gray-400 text-gray-600 hover:ring-1 hover:ring-olivine-500"
          >
            <div className="flex items-center justify-between px-2 py-3 text-xs font-semibold sm:text-base">
              <div>
                <div>
                  4. Add your delivery address
                  <span className="ml-1 italic text-[#F5A893] sm:ml-2">
                    *Reqired
                  </span>
                </div>
              </div>
              {fetchedStatuses.delivery_address === "True" ? (
                <div className="flex items-center rounded-full bg-[#F2FAD3] px-2 py-2 ring-1 ring-gray-200 xsm:px-4">
                  <div>
                    <AiOutlineCheck className="mr-1 h-3 w-3 rounded-full bg-olivine-500 p-0.5 text-white" />
                  </div>
                  <div className="text-xs text-olivine-500">
                    <div className="text-xs text-olivine-500">Done</div>
                  </div>
                </div>
              ) : (
                <div className="flex items-center rounded-full bg-[#FDECD1] px-2 py-2 ring-1 ring-gray-200 xsm:px-4">
                  <div className="mr-1 h-3 w-3 rounded-full bg-[#F79E1B] p-0"></div>
                  <div className="text-xs text-[#F79E1B]">Pending</div>
                </div>
              )}
            </div>
          </div>
          <div
            onClick={() => handleRedirect("addBvn")}
            className="mt-4 cursor-pointer rounded-md border-[1px] border-gray-400 text-gray-600 hover:ring-1 hover:ring-olivine-500"
          >
            <div className="flex items-center justify-between px-2 py-3 text-xs font-semibold sm:text-base">
              <div className="items-center sm:flex">
                <div>5. Add your Bank Verification Number (BVN)</div>
                <Tippy
                  content={
                    <div
                      className="max-w-sm rounded-md bg-neutral-600/95 px-3 py-2 text-xs text-slate-200"
                      interactive={true}
                      interactiveBorder={20}
                      delay={100}
                    >
                      <div>
                        To be eligible for our financial services products
                        (coming soon), including loans, buy now pay later,
                        pensions and health insurance
                      </div>
                    </div>
                  }
                >
                  <button className="sm:ml-3">
                    <AiOutlineInfo className="h-4 w-4 rounded-full bg-[#F5A893] text-white" />
                  </button>
                </Tippy>
              </div>
              {fetchedStatuses.bank_verification_number === "True" ? (
                <div className="flex items-center rounded-full bg-[#F2FAD3] px-2 py-2 ring-1 ring-gray-200 xsm:px-4">
                  <div>
                    <AiOutlineCheck className="mr-1 h-3 w-3 rounded-full bg-olivine-500 p-0.5 text-white" />
                  </div>
                  <div className="text-xs text-olivine-500">
                    <div className="text-xs text-olivine-500">Done</div>
                  </div>
                </div>
              ) : (
                <div className="flex items-center rounded-full bg-[#FDECD1] px-2 py-2 ring-1 ring-gray-200 xsm:px-4">
                  <div className="mr-1 h-3 w-3 rounded-full bg-[#F79E1B] p-0"></div>
                  <div className="text-xs text-[#F79E1B]">Pending</div>
                </div>
              )}
            </div>
          </div>
          <div
            onClick={() => handleRedirect("addNin")}
            className="mt-4 cursor-pointer rounded-md border-[1px] border-gray-400 text-gray-600 hover:ring-1 hover:ring-olivine-500"
          >
            <div className="flex items-center justify-between px-2 py-3 text-xs font-semibold sm:text-base">
              <div className="items-center sm:flex">
                <div>6. Add your National Identification Number (NIN)</div>
                <Tippy
                  content={
                    <div
                      className="max-w-sm rounded-md bg-neutral-600/95 px-3 py-2 text-xs text-slate-200"
                      interactive={true}
                      interactiveBorder={20}
                      delay={100}
                    >
                      <div>
                        Helps to verify your identity and generate a permanent
                        unique bank account number for easy wallet top up
                      </div>
                    </div>
                  }
                >
                  <button className="sm:ml-3">
                    <AiOutlineInfo className="h-4 w-4 rounded-full bg-[#F5A893] text-white" />
                  </button>
                </Tippy>
              </div>
              {fetchedStatuses.national_id === "True" ? (
                <div className="flex items-center rounded-full bg-[#F2FAD3] px-2 py-2 ring-1 ring-gray-200 xsm:px-4">
                  <div>
                    <AiOutlineCheck className="mr-1 h-3 w-3 rounded-full bg-olivine-500 p-0.5 text-white" />
                  </div>
                  <div className="text-xs text-olivine-500">
                    <div className="text-xs text-olivine-500">Done</div>
                  </div>
                </div>
              ) : (
                <div className="flex items-center rounded-full bg-[#FDECD1] px-2 py-2 ring-1 ring-gray-200 xsm:px-4">
                  <div className="mr-1 h-3 w-3 rounded-full bg-[#F79E1B] p-0"></div>
                  <div className="text-xs text-[#F79E1B]">Pending</div>
                </div>
              )}
            </div>
          </div>
          <div
            onClick={() => handleRedirect("addId")}
            className="mt-4 cursor-pointer rounded-md border-[1px] border-gray-400 text-gray-600 hover:ring-1 hover:ring-olivine-500"
          >
            <div className="flex items-center justify-between px-2 py-3 text-xs font-semibold sm:text-base">
              <div className="items-center sm:flex">
                <div>7. Upload a Valid Identification Card</div>
                <Tippy
                  content={
                    <div
                      className="max-w-sm rounded-md bg-neutral-600/95 px-3 py-2 text-xs text-slate-200"
                      interactive={true}
                      interactiveBorder={20}
                      delay={100}
                    >
                      <div>
                        To verify your identity and be eligible for our
                        financial services products (coming soon), including
                        loans, buy now pay later, pensions and health insurance
                      </div>
                    </div>
                  }
                >
                  <button className="sm:ml-3">
                    <AiOutlineInfo className="h-4 w-4 rounded-full bg-[#F5A893] text-white" />
                  </button>
                </Tippy>
              </div>
              {fetchedStatuses.document_upload === "True" ? (
                <div className="flex items-center rounded-full bg-[#F2FAD3] px-2 py-2 ring-1 ring-gray-200 xsm:px-4">
                  <div>
                    <AiOutlineCheck className="mr-1 h-3 w-3 rounded-full bg-olivine-500 p-0.5 text-white" />
                  </div>
                  <div className="text-xs text-olivine-500">
                    <div className="text-xs text-olivine-500">Done</div>
                  </div>
                </div>
              ) : (
                <div className="flex items-center rounded-full bg-[#FDECD1] px-2 py-2 ring-1 ring-gray-200 xsm:px-4">
                  <div className="mr-1 h-3 w-3 rounded-full bg-[#F79E1B] p-0"></div>
                  <div className="text-xs text-[#F79E1B]">Pending</div>
                </div>
              )}
            </div>
          </div>

          <div className="my-8 text-center">
            <button
              onClick={() => navigate("/home")}
              className="rounded-md bg-olivine-500 px-8 py-3 text-sm font-semibold text-white transition-all duration-300 hover:-translate-y-0.5 hover:bg-olivine-400 focus:outline-none focus:ring-[2px] focus:ring-olivine-500 focus:ring-opacity-60 focus:ring-offset-2 active:bg-olivine-600 sm:px-16 sm:text-base"
            >
              GO TO YOUR HOMEPAGE
            </button>
          </div>
        </div>
        {/* </div> */}
      </div>
      <Modal
        visible={showModal}
        onClose={handleOnClose}
        selected={selectedModal}
      />
    </div>
  );
}

export default HomeTodoWelcome;
