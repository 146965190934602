import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  cartItemDelete,
  cartItemUpdate,
  checkout,
  getDelivery,
  productPurchaseGet,
  productPurchaseSet,
  verifyCoupon,
  getWalletBalance,
  activePlans,
  getTransactions,
  todoListStatus,
  stockCheck,
  fetchRecommendations,
  fetchGrocedyForAll,
} from "../store/actions";
import Navbar from "./Navbar";
import { fetchCountries } from "../store/actions";
import Modal from "./Ui";
import SignedInNavbarImproved from "./SignedInNavbarImproved";
import { authService } from "../services/authService";
import { toast } from "react-hot-toast";
import RxjsStoreContext from "../context/RxjsStoreContext";
import BasketService from "../services/basketService";
import { resetCoupon } from "../store/slices/cartSlice";
import { Modal as MUIModal, Box } from "@mui/material";
import { setIsSubmiting } from "../store/slices/modalSlice";
import FeedBackSubRatingVasPopup from "./Rating/FeedBackSubRatingPopup";
import FeedBackRatingPopup from "./Rating/FeedBackRatingPopup";
import { stockCheckHandler, updateCart, wait } from "../core/utils/CartHelpers";
import CartDetails from "./cartPages/CartDetails";
import OrderDetails from "./cartPages/OrderDetails";
import UpdatePhoneNumber from "./pages/cartPage/UpdatePhoneNumber";
import AddYourBVNNumber from "./AddYourBVNNumber";
import RecommendationsPage from "./recommendations/RecommendationsPage";

function CartPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const copyCartObj = JSON.parse(localStorage.getItem("cart"));
  const [fetchedCart, setFetchedCart] = useState({});
  const [fetchedPlans, setFetchedPlans] = useState([]);
  const [customBasket, setCustomBasket] = useState({});
  const [subTotalPlanPrice, setSubTotalPlanPrice] = useState(0);
  const [subTotalOfflinePlanPrice, setSubTotalOfflinefPlanPrice] = useState(0);
  const [subTotalAddonPrice, setSubTotalAddonPrice] = useState(0);
  const [subTotalOfflineAddonPrice, setSubTotalOfflineAddonPrice] = useState(0);
  const [_, setCounter] = useState(0);
  const [fetchedCountries, setFetchedCountries] = useState();
  const [countries, setCountries] = useState();
  const [existingDeliveryInfo, setExistingDeliveryInfo] = useState();
  const [couponCode, setCouponCode] = useState("");
  const [fetchedCouponCode, setFetchedCouponCode] = useState(0);
  const [updatedLocalCart, setUpdatedLocalCart] = useState(copyCartObj);
  const [modifiedLocalCart, setModifiedLocalCart] = useState([]);
  const [fetchedUserBalance, setFetchedUserBalance] = useState();
  const [fetchedDeliveryCharge, setFetchedDeliveryCharge] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [selectedModal, setSelectedModal] = useState("");
  const [deliveryFees, setDeliveryFees] = useState(0);
  const [cartAmount, setCartAmount] = useState(0);
  const [fetchedStatuses, setfetchedStatuses] = useState("");
  const [open, setOpen] = useState(false);
  const [openRating, setOpenRating] = useState(false);
  const [openCustomRating, setOpenCustomRating] = useState(false);
  const [totalPlansQty, setTotalPlansQty] = useState(0);
  const [priceAlert, showPriceAlert] = useState(false);
  const [subRef, setSubRef] = useState("");
  const [openUnverifiedPhone, setOpenUnverifiedPhone] = useState(false);
  const [fetchedRecommendations, setFetchedRecommendations] = useState([]);

  const {
    fetchCountriesData,
    getDeliveryItems,
    products,
    todoListStatusData,
    grocedyForAll,
  } = useSelector((state) => state.masterData);
  const { cart, coupon } = useSelector((state) => state.cart);
  const { walletBalance } = useSelector((state) => state.user);

  const userEmail = authService.getCurrentUserEmail();
  const selected_plan = localStorage.getItem("selected_plan");

  const verification_code = authService.getCurrentUserVerificationCode();
  const userId = authService.getCurrentUserID();
  const customerId = authService.getCurrentUserCustomerId();
  const zohoCustomerId = authService.getCurrentUserZohoCustomerId();

  const rxjsStore = useContext(RxjsStoreContext);
  const token = authService.getToken();

  useEffect(() => {
    if (customBasket?.line_items?.length) {
      handleRecommendations(customBasket?.line_items);
    }
  }, [customBasket?.line_items]);

  useEffect(() => {
    if (!todoListStatusData) {
      const data = {
        sql_ref_no: "20",
        user_id: userId.toString(),
        verification_code: verification_code?.toString(),
        option: "1",
      };

      dispatch(todoListStatus(data));
    }

    if (todoListStatusData) setfetchedStatuses(todoListStatusData);
  }, [dispatch, todoListStatusData, verification_code, userId]);

  useEffect(() => {
    if (!grocedyForAll) {
      dispatch(fetchGrocedyForAll(customerId ? customerId : "0"))
        .unwrap()
        .catch((err) => {
          navigate("/login");

          return err;
        });
    }

    if (grocedyForAll && grocedyForAll?.delivery_charge?.length) {
      setFetchedDeliveryCharge(grocedyForAll?.delivery_charge);
    }
  }, [grocedyForAll, navigate, customerId]);

  useEffect(() => {
    if (grocedyForAll && grocedyForAll?.delivery_charge?.length) {
      setFetchedDeliveryCharge(grocedyForAll?.delivery_charge);
    }
  }, [dispatch, grocedyForAll, navigate]);

  // useEffect(() => {
  //   if (!products) {
  //     const data = {
  //       sql_ref_no: "46",
  //       corporateId: corporateId ? +corporateId : 1,
  //       customer_id: customerId?.toString(),
  //       option: "1",
  //     };

  //     dispatch(getProducts(data))
  //       .unwrap()
  //       .catch((err) => {
  //         navigate("/login");

  //         return err;
  //       });
  //   }

  //   if (products && products?.delivery_charge?.length) {
  //     setFetchedDeliveryCharge(products?.delivery_charge[0]);
  //   }
  // }, [dispatch, products, navigate, customBasket]);

  useEffect(() => {
    const data = {
      user_email: userEmail,
    };

    dispatch(productPurchaseGet(data));
  }, [userEmail, dispatch]);

  useEffect(() => {
    if (!walletBalance) {
      const data = {
        sql_ref_no: "31",
        user_id: userId?.toString(),
        customer_id: customerId?.toString(),
        verification_code,
        option: "1",
      };
      dispatch(getWalletBalance(data))
        .unwrap()
        .catch((err) => {
          return err;
        });
    }

    if (walletBalance) setFetchedUserBalance(walletBalance);
  }, [dispatch, userId, customerId, verification_code, walletBalance]);

  useEffect(() => {
    if (customBasket?.line_items?.length) {
      customBasket?.line_items?.map((lineIt) => {
        if (
          lineIt?.item_id === "GROBP" ||
          lineIt?.item_id === "GROSP" ||
          lineIt?.item_id === "GROGP" ||
          lineIt?.item_id === "GRODP" ||
          lineIt?.item_id === "GROPP"
        ) {
          // return rxjsStore.clearCustomBasket();
          return removeCustomBasket();
        }
        return null;
      });
    }
  }, [customBasket?.line_items]);

  useEffect(() => {
    if (!cart?.items?.length) {
      setFetchedCouponCode(0);
      setCouponCode("");
      dispatch(resetCoupon());
      setSubTotalPlanPrice(0);
      setSubTotalAddonPrice(0);
    }
  }, [!cart?.items?.length]);

  // Merge local cart to mongo cart
  useEffect(() => {
    if (token && copyCartObj?.length) {
      localStorage.removeItem("cart");

      const modifiedLocalCartObject =
        updatedLocalCart?.length &&
        updatedLocalCart?.map((cartObj) => ({
          itemId: cartObj?.itemId,
          sql_ref_no: "25",
          user_email: userEmail,
          JSON_String: {
            customer_id: customerId.toString(),
            plan: {
              plan_name: cartObj?.JSON_String?.plan?.plan_name,
              plan_quantity: cartObj?.JSON_String?.plan?.plan_quantity,
              plan_price: cartObj?.JSON_String?.plan?.plan_price,
              plan_code: cartObj?.JSON_String?.plan?.plan_code,
              isRecurring: cartObj?.JSON_String?.plan?.isRecurring,
              product_id: cartObj?.JSON_String?.plan?.product_id,
              interval: "1",
              interval_unit: "months",
              number_of_months: cartObj?.JSON_String?.plan?.number_of_months,
            },
            addons: cartObj?.JSON_String?.addons,
          },
        }));

      setModifiedLocalCart(modifiedLocalCartObject);
    }
  }, [token, copyCartObj, userEmail, customerId, dispatch, updatedLocalCart]);

  // Send the latest cart items
  useEffect(() => {
    if (modifiedLocalCart && modifiedLocalCart?.length) {
      const cartPromise = new Promise((reslove) => {
        modifiedLocalCart.map(async (item) => {
          reslove(dispatch(productPurchaseSet(item)));
        });
      });

      Promise.all([cartPromise])
        .then(() => {
          toast.success("Cart Updated Successfully");
          setModifiedLocalCart([]);
        })
        .catch((err) => {
          toast.error("Something Went Wrong!");

          return err;
        });
    }
  }, [updatedLocalCart, dispatch, modifiedLocalCart]);

  // Fetch the cart information (Authenticated User)
  useEffect(() => {
    if (!cart && userEmail && token) {
      const data = {
        user_email: userEmail,
      };

      dispatch(productPurchaseGet(data));
    }

    if (cart?.items && token) {
      setFetchedCart(cart);
    }

    if (
      !cart?.items?.length &&
      !customBasket?.line_items?.length &&
      !updatedLocalCart?.length
    ) {
      setSubTotalPlanPrice(0);
      setDeliveryFees(0);
      setSubTotalAddonPrice(0);
    }
  }, [
    dispatch,
    cart,
    cart?.items,
    userEmail,
    token,
    customBasket,
    updatedLocalCart,
  ]);

  useEffect(() => {
    if (fetchedCart?.items?.length) {
      const planTotal = calculateTotalPlansQty(fetchedCart?.items);

      setTotalPlansQty(planTotal);
    }
  }, [fetchedCart?.items?.length]);

  useEffect(() => {
    // There is item in cart and custom basket
    if (
      fetchedCart?.items?.length ||
      customBasket?.line_items?.length ||
      updatedLocalCart?.length ||
      totalPlansQty > 0
    ) {
      const totalDeliveryFee = calculateTotalDeliveryFees(
        fetchedDeliveryCharge,
        fetchedCart?.items?.length,
        customBasket?.line_items,
        updatedLocalCart?.length
      );

      setDeliveryFees(totalDeliveryFee);
    }
  }, [
    fetchedCart?.items?.length,
    fetchedDeliveryCharge,
    customBasket?.line_items?.length,
    updatedLocalCart?.length,
    totalPlansQty,
  ]);

  // The total Price of plan
  useEffect(() => {
    if (fetchedCart?.items?.length) {
      const totalBasketPrice = fetchedCart?.items?.map(
        (tArray) =>
          +tArray?.JSON_String?.plan?.plan_price *
          +tArray?.JSON_String?.plan?.plan_quantity
      );

      const subtotalBasketPrice =
        totalBasketPrice &&
        totalBasketPrice?.length &&
        totalBasketPrice?.reduce((a, b) => a + b);

      setSubTotalPlanPrice(subtotalBasketPrice);
    }
  }, [fetchedCart]);

  useEffect(() => {
    if (!fetchedCart?.items?.length && updatedLocalCart?.length) {
      const totalBasketPrice = updatedLocalCart?.map(
        (tArray) =>
          +tArray?.JSON_String?.plan?.plan_price *
          +tArray?.JSON_String?.plan?.plan_quantity
      );

      const subtotalBasketPrice =
        totalBasketPrice &&
        totalBasketPrice?.length &&
        totalBasketPrice?.reduce((a, b) => a + b);

      setSubTotalOfflinefPlanPrice(subtotalBasketPrice);
    }
  }, [fetchedCart?.items, updatedLocalCart]);

  // Total Price for addOns
  useEffect(() => {
    if (fetchedCart?.items?.length) {
      const totalAddonPrice = fetchedCart?.items?.map((tArray) =>
        tArray?.JSON_String?.addons.length
          ? tArray?.JSON_String?.addons?.map(
              (addOn) => addOn?.price_brackets?.totalPrice
            )
          : 0
      );

      const merged = [].concat.apply([], totalAddonPrice);

      const subtotalAddonPrice =
        merged && merged?.length && merged?.reduce((a, b) => a + b);

      setSubTotalAddonPrice(subtotalAddonPrice);
    }
  }, [fetchedCart?.items]);

  useEffect(() => {
    if (!fetchedCart?.items?.length && updatedLocalCart?.length) {
      const totalAddonPrice = updatedLocalCart?.map((tArray) =>
        tArray?.JSON_String?.addons?.length
          ? tArray?.JSON_String?.addons?.map(
              (addOn) => addOn?.price_brackets?.totalPrice
            )
          : []
      );

      const merged = [].concat.apply([], totalAddonPrice);

      const subtotalAddonPrice =
        merged && merged?.length && merged?.reduce((a, b) => a + b);

      setSubTotalOfflineAddonPrice(subtotalAddonPrice);
    }
  }, [fetchedCart?.items, updatedLocalCart]);

  // add the fetched plans to state
  useEffect(() => {
    if (selected_plan) {
      const filteredProductPlans = grocedyForAll?.plan_info?.filter(
        (plan) => plan?.product_id === selected_plan
      );

      setFetchedPlans(filteredProductPlans);
    }
  }, [selected_plan, grocedyForAll?.plan_info]);

  // Fetch the countries
  useEffect(() => {
    if (!fetchCountriesData && token) {
      const data = {
        sql_ref_no: "12",
        user_id: userId.toString(),
        c_code: verification_code,
        option: "19",
      };

      dispatch(fetchCountries(data));
    }

    if (fetchCountriesData)
      setFetchedCountries(fetchCountriesData.location_info);
  }, [fetchCountriesData, userId, verification_code, dispatch, token]);

  // Set the countries into state
  useEffect(() => {
    if (fetchedCountries) {
      const resArr = [];
      fetchedCountries.filter((item) => {
        let i = resArr.findIndex((x) => x === item.country_name);
        if (i <= -1) {
          resArr.push(item.country_name);
        }
        return null;
      });

      setCountries(resArr);
    }
  }, [fetchedCountries]);

  // Get the exist
  useEffect(() => {
    if (!getDeliveryItems && token) {
      const data = {
        sql_ref_no: "11",
        user_id: userId.toString(),
        customer_id: customerId.toString(),
        option: "6",
      };

      dispatch(getDelivery(data));
    }

    if (getDeliveryItems) setExistingDeliveryInfo(getDeliveryItems);
  }, [getDeliveryItems, dispatch, userId, customerId, token]);

  useEffect(() => {
    dispatch(resetCoupon());
  }, [dispatch]);

  // Fetch the coupon code
  useEffect(() => {
    if (coupon) {
      if (coupon?.Result && coupon?.discount_by === "percentage") {
        const calcCoupon =
          (+coupon?.discount_value / 100) *
          (+subTotalPlanPrice + +subTotalAddonPrice);
        setFetchedCouponCode(calcCoupon);
      }

      if (coupon?.Result && coupon?.discount_by === "flat") {
        setFetchedCouponCode(+coupon?.discount_value);
      }

      if (!coupon?.Result) {
        setFetchedCouponCode(0);
      }
    }
  }, [coupon, setFetchedCouponCode, subTotalPlanPrice, subTotalAddonPrice]);

  //  For custom basket
  useEffect(() => {
    let tempCustomBasket = {
      plan_name: "Custom Basket",
      notes: "",
      discount: "00.00%",
      customer_id: authService.getCurrentUserZohoCustomerId(),
      shipping_charge: 0,
      adjustment: 0,
      adjustment_description: "custom basket item",
      exchange_rate: 1,
      line_items: [],
    };
    // setDeliveryFees(deliveryChargeBasket?.rate);
    // setDeliveryFees(deliveryChargeButcher?.rate);
    rxjsStore.getUserSelectedBasket().subscribe((productsListMap) => {
      for (let [key, product] of productsListMap) {
        const tempItems = {
          item_id: product.item_id,
          name: product.name,
          rate: product.item_rate_zoho,
          quantity: product.qty,
          unit: product.unit,
          type: product?.type,
          item_total: product.qty * Number(product.item_rate_zoho),
        };
        tempCustomBasket.line_items.push(tempItems);
      }
      setCounter((prev) => {
        return prev + 1;
      });
      setCustomBasket(tempCustomBasket);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deliveryFees]);

  const handleGeneralCheckout = async () => {
    if (!token) {
      toast.error("You need to be logged in...");
      return navigate("/login");
    }

    if (customBasket?.line_items?.length) {
      const grandTotalPrice = calculateTotalBasket(customBasket);

      if (grandTotalPrice < 15000) {
        return showPriceAlert(true);
      }
    }

    if (
      fetchedStatuses?.phone_number !== "True" ||
      fetchedStatuses?.delivery_address !== "True"
    ) {
      toast.error("Please input all required fields to proceed");
      return setTimeout(() => {
        navigate("/home/todo");
      }, 3000);
    }

    if (fetchedStatuses?.transaction_pin !== "True") {
      toast.error("Please set up transaction pin");
    }

    let amountToPay = 0;

    if (!customBasket?.line_items?.length && !fetchedCart?.items?.length) {
      return toast.error("You have no items in cart...");
    }

    const stockChecker = await stockCheckHandler(
      fetchedCart,
      customBasket?.line_items,
      dispatch,
      stockCheck
    );

    if (
      stockChecker?.plan_price_status[0]?.message_id !== 1 ||
      stockChecker?.item_price_status[0]?.message_id !== 1 ||
      stockChecker?.item_stock_status[0]?.message_id !== 1
    ) {
      if (stockChecker?.plan_price_status[0]?.message_id !== 1) {
        if (stockChecker?.plans?.length === 1) {
          return toast.error(
            `Basket Plan: ${
              stockChecker?.plan_price_status[0]?.message_text
            }. Please update the following item: ${stockChecker?.plans.map(
              (item) => item.name
            )}`
          );
        }

        if (stockChecker?.plans?.length > 1) {
          return toast.error(
            `Basket Plan: ${
              stockChecker?.plan_price_status[0]?.message_text
            }. Please update the following items: ${stockChecker?.plans.map(
              (item) => item.name
            )}`
          );
        }
      }

      if (stockChecker?.item_price_status[0]?.message_id !== 1) {
        if (stockChecker?.items?.length === 1) {
          return toast.error(
            `Custom / Butcher: ${
              stockChecker?.item_price_status[0]?.message_text
            }. Please update the following item: ${stockChecker?.items.map(
              (item) => item.name
            )}`
          );
        }

        if (stockChecker?.items?.length > 1) {
          return toast.error(
            `Custom / Butcher: ${
              stockChecker?.item_price_status[0]?.message_text
            }. Please update the following items: ${stockChecker?.items.map(
              (item) => item.name
            )}`
          );
        }
      }

      if (stockChecker?.item_stock_status[0]?.message_id !== 1) {
        if (stockChecker?.items_stock?.length === 1) {
          return toast.error(
            `Custom / Butcher: ${
              stockChecker?.item_stock_status[0]?.message_text
            }. Please remove the following item: ${stockChecker?.items_stock.map(
              (item) => item.name
            )}`
          );
        }

        if (stockChecker?.items_stock?.length > 1) {
          return toast.error(
            `Custom / Butcher: ${
              stockChecker?.item_stock_status[0]?.message_text
            }. Please remove the following items: ${stockChecker?.items_stock.map(
              (item) => item.name
            )}`
          );
        }
      }
    }

    amountToPay = subTotalPlanPrice + subTotalAddonPrice + deliveryFees;

    if (fetchedCouponCode && customBasket?.line_items?.length) {
      amountToPay =
        subTotalPlanPrice +
        subTotalAddonPrice +
        deliveryFees -
        fetchedCouponCode +
        calculateTotalBasket(customBasket);
    }

    if (
      !fetchedCouponCode &&
      customBasket &&
      customBasket?.line_items?.length
    ) {
      amountToPay =
        subTotalPlanPrice +
        subTotalAddonPrice +
        deliveryFees +
        calculateTotalBasket(customBasket);
    }

    if (fetchedCouponCode && !customBasket?.line_items?.length) {
      amountToPay =
        subTotalPlanPrice +
        subTotalAddonPrice +
        deliveryFees -
        fetchedCouponCode;
    }

    setCartAmount(amountToPay);

    if (amountToPay > fetchedUserBalance?.available_balance) {
      setShowModal(true);
      return setSelectedModal("insufficient");
    }

    dispatch(setIsSubmiting(false));
    setShowModal(true);
    setSelectedModal("sufficient");
  };

  const paymentProceed = () => {
    submitCustomBasket(customBasket);
    handleCheckout();
  };

  const handleOnClose = () => {
    setShowModal(false);
    setSelectedModal("");
  };

  function calculateTotalPlansQty(plans) {
    const sum = plans.reduce(
      (acc, item) => acc + Number(item.JSON_String.plan.plan_quantity),
      0
    );

    return sum;
  }

  function calculateTotalDeliveryFees(
    fetchedDeliveryCharge,
    fetchedCart,
    customBasket,
    updatedLocalCart
  ) {
    if (fetchedDeliveryCharge?.length && grocedyForAll?.product_info?.length) {
      const foodDelProdId = grocedyForAll?.product_info
        ?.filter((gfl) => gfl.name === "Food Baskets")
        .pop().delivery_charge_product_id;
      const deliveryChargeFee = fetchedDeliveryCharge
        ?.filter((bas) => +bas.product_id === foodDelProdId)
        .pop();

      if (fetchedCart && customBasket?.length && !updatedLocalCart) {
        let isBasket = false;
        let isButcher = false;
        let basketDeliveryFee = {};
        let butcherDeliveryFee = {};

        customBasket?.filter((item) => {
          if (item?.type === "BASKET") {
            isBasket = true;
            const basketDelProdId = grocedyForAll?.product_info
              ?.filter((gfl) => gfl.name === "Pick Your Basket")
              .pop().delivery_charge_product_id;
            basketDeliveryFee = fetchedDeliveryCharge
              ?.filter((bas) => +bas.product_id === basketDelProdId)
              .pop();
          }
          if (item?.type === "BUTCHER") {
            isButcher = true;
            const butcherDelProdId = grocedyForAll?.product_info
              ?.filter((gfl) => gfl.name === "GroChill")
              .pop().delivery_charge_product_id;
            butcherDeliveryFee = fetchedDeliveryCharge
              ?.filter((bas) => +bas.product_id === butcherDelProdId)
              .pop();
          }
        });

        if (isBasket && isButcher) {
          return (
            deliveryChargeFee?.item_rate * totalPlansQty +
            basketDeliveryFee?.item_rate * 1 +
            butcherDeliveryFee?.item_rate * 1
          );
        }

        if (!isBasket && isButcher) {
          return (
            deliveryChargeFee?.item_rate * totalPlansQty +
            butcherDeliveryFee?.item_rate * 1
          );
        }

        if (isBasket && !isButcher) {
          return (
            deliveryChargeFee?.item_rate * totalPlansQty +
            basketDeliveryFee?.item_rate * 1
          );
        }
      }

      // There is item in cart and no item in custom basket
      if (fetchedCart && !customBasket?.length && !updatedLocalCart) {
        return deliveryChargeFee?.item_rate * totalPlansQty;
      }

      // There is no item in cart and item in custom basket
      if (!fetchedCart && customBasket?.length && !updatedLocalCart) {
        let isBasket = false;
        let isButcher = false;
        let basketDeliveryFee = {};
        let butcherDeliveryFee = {};
        customBasket?.filter((item) => {
          if (item?.type === "BASKET") {
            isBasket = true;
            const basketDelProdId = grocedyForAll?.product_info
              ?.filter((gfl) => gfl.name === "Pick Your Basket")
              .pop().delivery_charge_product_id;
            basketDeliveryFee = fetchedDeliveryCharge
              ?.filter((bas) => +bas.product_id === basketDelProdId)
              .pop();
          }
          if (item?.type === "BUTCHER") {
            isButcher = true;
            const butcherDelProdId = grocedyForAll?.product_info
              ?.filter((gfl) => gfl.name === "GroChill")
              .pop().delivery_charge_product_id;
            butcherDeliveryFee = fetchedDeliveryCharge
              ?.filter((bas) => +bas.product_id === butcherDelProdId)
              .pop();
          }
        });

        if (isBasket && isButcher) {
          return (
            basketDeliveryFee?.item_rate * 1 + butcherDeliveryFee?.item_rate * 1
          );
        }

        if (!isBasket && isButcher) {
          return butcherDeliveryFee?.item_rate * 1;
        }

        if (isBasket && !isButcher) {
          return basketDeliveryFee?.item_rate * 1;
        }
      }

      // There is item in offline cart and custom basket
      if (!fetchedCart && customBasket?.length && updatedLocalCart) {
        let isBasket = false;
        let isButcher = false;
        let basketDeliveryFee = {};
        let butcherDeliveryFee = {};
        customBasket?.filter((item) => {
          if (item?.type === "BASKET") {
            isBasket = true;
            const basketDelProdId = grocedyForAll?.product_info
              ?.filter((gfl) => gfl.name === "Pick Your Basket")
              .pop().delivery_charge_product_id;
            basketDeliveryFee = fetchedDeliveryCharge
              ?.filter((bas) => +bas.product_id === basketDelProdId)
              .pop();
          }
          if (item?.type === "BUTCHER") {
            isButcher = true;
            const butcherDelProdId = grocedyForAll?.product_info
              ?.filter((gfl) => gfl.name === "GroChill")
              .pop().delivery_charge_product_id;
            butcherDeliveryFee = fetchedDeliveryCharge
              ?.filter((bas) => +bas.product_id === butcherDelProdId)
              .pop();
          }
        });

        if (isBasket && isButcher) {
          return (
            deliveryChargeFee?.item_rate * updatedLocalCart +
            basketDeliveryFee?.item_rate * 1 +
            butcherDeliveryFee?.item_rate * 1
          );
        }

        if (!isBasket && isButcher) {
          return (
            deliveryChargeFee?.item_rate * updatedLocalCart +
            butcherDeliveryFee?.item_rate * 1
          );
        }

        if (isBasket && !isButcher) {
          return (
            deliveryChargeFee?.item_rate * updatedLocalCart +
            basketDeliveryFee?.item_rate * 1
          );
        }
      }

      // There is item in offline cart and no item in custom basket
      if (!fetchedCart && !customBasket?.length && updatedLocalCart) {
        return deliveryChargeFee?.item_rate * updatedLocalCart;
      }
    }
  }

  // Coupon verification
  const handleCouponVerify = (e) => {
    e.preventDefault();

    try {
      const toastId = toast.loading("verifying coupon...");

      const data = {
        coupon_code: couponCode,
        product_id: "1",
      };
      dispatch(verifyCoupon(data))
        .unwrap()
        .then((res) => {
          if (res?.Result === false) {
            return toast.error("Coupon Could not be verified!", {
              id: toastId,
            });
          }
          toast.success("Coupon Verified!", { id: toastId });
          return setCouponCode("");
        })
        .catch((err) => {
          toast.error("Coupon Could not be verified!", { id: toastId });

          return err;
        });
    } catch (e) {
      return e;
    }
  };

  // Return to Product list
  const handleContinue = () => {
    navigate(`/home/plans/${selected_plan}`);
  };

  // Delete Item in a cart
  const handleDelete = (itemId) => {
    try {
      const data = {
        itemId,
        user_email: userEmail,
      };

      dispatch(cartItemDelete(data))
        .unwrap()
        .then((res) => {
          updateCart(
            dispatch,
            productPurchaseGet,
            calculateTotalPlansQty,
            setTotalPlansQty
          );
          toast.success("Plan deleted from cart!");
        })
        .catch((err) => {
          toast.error("Something Went Wrong!");

          return err;
        });
    } catch (e) {
      return e;
    }
  };

  useEffect(() => {
    localStorage.setItem("cart", JSON.stringify(updatedLocalCart));
  }, [updatedLocalCart]);

  //Delete from the local cart
  const handleLocalDelete = (itemId) => {
    try {
      const newUpdatedLocalCart = updatedLocalCart?.filter(
        (cartItem) => cartItem?.itemId !== itemId
      );

      setUpdatedLocalCart(newUpdatedLocalCart);
    } catch (e) {
      return e;
    }
  };

  const handleCartUpdate = (item) => {
    try {
      const selectedPlanDetails = fetchedPlans?.filter(
        (fPlan) => fPlan?.name === item.plan_name
      );

      const data = {
        plan_name: item.plan_name,
        plan_quantity: item.plan_units.toString(),
        plan_price: selectedPlanDetails[0]?.recurring_price,
        plan_code: selectedPlanDetails[0]?.plan_code,
        isRecurring: item.isRecurring,
        product_id: selectedPlanDetails[0]?.product_id,
        interval: "1",
        interval_unit: "months",
        number_of_months: item.months_recurring.toString(),
      };

      const payload = {
        data,
        user_email: userEmail,
        itemId: item.itemId,
      };

      dispatch(cartItemUpdate(payload))
        .unwrap()
        .then(() => {
          updateCart(
            dispatch,
            productPurchaseGet,
            calculateTotalPlansQty,
            setTotalPlansQty
          );
          toast.success("Cart item updated!");

          // handleContinue();
        })
        .catch((err) => {
          toast.error("Something Went Wrong!");

          return err;
        });
    } catch (e) {
      return e;
    }
  };

  // Update local cart
  const handleLocalCartUpdate = (item) => {
    try {
      const selectedPlanDetails = fetchedPlans?.filter(
        (fPlan) => fPlan?.name === item.plan_name
      );

      const data = {
        plan_name: item.plan_name,
        plan_quantity: item.plan_units.toString(),
        plan_price: selectedPlanDetails[0]?.recurring_price,
        plan_code: selectedPlanDetails[0]?.plan_code,
        isRecurring: item.isRecurring,
        product_id: selectedPlanDetails[0]?.product_id,
        interval: "1",
        interval_unit: "months",
        number_of_months: item.months_recurring.toString(),
      };

      const newUpdatedArray = updatedLocalCart;

      const target = newUpdatedArray.find((obj) => obj.itemId === item?.itemId);

      Object.assign(target, data);

      setUpdatedLocalCart(newUpdatedArray);
    } catch (e) {
      return e;
    }
  };

  const handleCheckout = async () => {
    if (!token) {
      toast.error("You need to be logged in...");
      return navigate("/login");
    }

    if (!cart.items?.length) {
      // toast.error("You have no item in your cart!");
      return;
    }

    let amountToPay = 0;

    amountToPay = subTotalPlanPrice + subTotalAddonPrice + deliveryFees;

    if (fetchedCouponCode && customBasket && customBasket?.length) {
      amountToPay =
        subTotalPlanPrice +
        subTotalAddonPrice +
        deliveryFees -
        fetchedCouponCode +
        calculateTotalBasket(customBasket);
    }

    if (customBasket && customBasket?.length) {
      amountToPay =
        subTotalPlanPrice +
        subTotalAddonPrice +
        deliveryFees +
        calculateTotalBasket(customBasket);
    }

    if (fetchedCouponCode && !customBasket?.length) {
      amountToPay =
        subTotalPlanPrice +
        subTotalAddonPrice +
        deliveryFees -
        fetchedCouponCode;
    }

    if (amountToPay > fetchedUserBalance?.available_balance) {
      return toast.error("You have insufficient funds...");
    }

    const toastId = toast.loading("Creating your subscription...");

    let checkoutArray;
    let yourDate = new Date();

    if (updatedLocalCart?.length && !cart) {
      checkoutArray =
        updatedLocalCart &&
        updatedLocalCart.length &&
        updatedLocalCart.map((cartI) => {
          const checkout = {
            customer_id: zohoCustomerId ? zohoCustomerId : "",
            starts_at: yourDate.toISOString().split("T")[0],
            // salesperson_name: "Abosede Adeyemi",
            auto_collect: "false",
            plan: {
              plan_code: cartI?.JSON_String?.plan?.plan_code,
              quantity: cartI?.JSON_String?.plan?.plan_quantity,
              price: cartI?.JSON_String?.plan?.plan_price,
              billing_cycles: "-1",
            },
            addons: cartI?.JSON_String?.addons?.length
              ? cartI?.JSON_String?.addons?.map((addon) => ({
                  addon_code: addon?.addon_code,
                  price: addon?.price_brackets?.price,
                  quantity: addon?.price_brackets?.quantity,
                  billing_cycles: "-1",
                }))
              : [],
            coupon_code: coupon ? coupon?.coupon_code : "",
          };

          return checkout;
        });
    }

    const updatedDeliveryCharge = deliveryFees / cart?.items?.length;
    const foodDelProdId = grocedyForAll?.product_info
      ?.filter((gfl) => gfl.name === "Food Baskets")
      .pop().delivery_charge_product_id;
    const foodBaskDeliveryChargeFee = fetchedDeliveryCharge
      ?.filter((bas) => +bas.product_id === foodDelProdId)
      .pop();

    if (cart) {
      checkoutArray =
        cart &&
        cart?.items?.length &&
        cart?.items?.map((cartI) => {
          const addonsArray = cartI?.JSON_String?.addons;
          let subtotalPrice = 0;
          if (addonsArray && addonsArray.length) {
            const totalPrice = addonsArray?.map(
              (tArray) => tArray.price_brackets.totalPrice
            );

            subtotalPrice = totalPrice?.reduce((a, b) => a + b);
          }

          const checkout = {
            checkoutItem: {
              customer_id: zohoCustomerId,
              starts_at: yourDate.toISOString().split("T")[0],
              auto_collect: "false",
              amount: (
                +cartI.JSON_String?.plan.plan_price *
                  cartI.JSON_String?.plan.plan_quantity +
                subtotalPrice +
                updatedDeliveryCharge
              ).toString(),
              delgro_customer_id: customerId.toString(),
              user_id: userId?.toString(),
              verification_code: verification_code?.toString(),
              plan: {
                plan_code: cartI?.JSON_String?.plan?.plan_code,
                quantity: cartI?.JSON_String?.plan?.plan_quantity,
                price: cartI?.JSON_String?.plan?.plan_price,
                months_recurr: cartI?.JSON_String?.plan?.number_of_months,
                billing_cycles: "-1",
              },
              addons:
                cartI?.JSON_String?.addons && cartI?.JSON_String?.addons?.length
                  ? cartI?.JSON_String?.addons?.map((addon) => ({
                      addon_code: addon?.addon_code,
                      price: addon?.price_brackets?.price,
                      quantity: addon?.price_brackets?.quantity,
                      billing_cycles: "-1",
                    }))
                  : [],
              coupon_code: coupon ? coupon?.coupon_code : "",
              deliveryFees:
                foodBaskDeliveryChargeFee?.item_rate *
                cartI?.JSON_String?.plan?.plan_quantity,
            },
            itemId: cartI?.itemId,
          };

          return checkout;
        });
    }

    const itemNo = checkoutArray?.length;

    await Promise.all(
      checkoutArray?.map(async (payL, index) => {
        wait(5000 * index, setTimeout)
          .then(async () => {
            return await dispatch(checkout(payL?.checkoutItem))
              .unwrap()
              .then((res) => {
                if (res?.status === 403) {
                  dispatch(setIsSubmiting(true));
                  setOpenUnverifiedPhone(true);
                  handleOnClose();
                  return toast.error(res?.message, {
                    id: toastId,
                  });
                }
                if (!res?.Result) {
                  dispatch(setIsSubmiting(true));
                  return toast.error("Subscriptions Could not be created...", {
                    id: toastId,
                  });
                }

                dispatch(activePlans(zohoCustomerId));
                const id = customerId?.toString();
                dispatch(getTransactions(id));

                toast.success("Subscription Created...", {
                  id: toastId,
                });
                dispatch(setIsSubmiting(false));

                const data = {
                  user_email: userEmail,
                  itemId: payL?.itemId,
                };

                dispatch(cartItemDelete(data))
                  .unwrap()
                  .then(() => {
                    const data = {
                      sql_ref_no: "31",
                      user_id: userId.toString(),
                      customer_id: customerId.toString(),
                      verification_code,
                      option: "1",
                    };

                    dispatch(getWalletBalance(data));
                    dispatch(resetCoupon());

                    if (itemNo === 1 && !customBasket?.length) {
                      setSubRef(res?.Message_Ref_No);
                      setShowModal(false);
                      return setOpenRating(true);
                    }

                    navigate("/home");
                  })
                  .catch((err) => err);

                return res;
              })
              .catch((err) => {
                toast.error("Subscriptions Could not be created...", {
                  id: toastId,
                });
                return err;
              });
          })
          .catch((err) => err);
      })
    );
  };

  const removeCustomBasket = () => {
    rxjsStore.clearCustomBasket();
    setCustomBasket({});
    setCounter((prev) => {
      return prev + 1;
    });
  };

  const calculateTotalBasket = (basket) => {
    let total = 0;

    if (basket?.line_items?.length) {
      basket.line_items?.forEach((product) => {
        total += product?.item_total;
      });
    }
    return total;
  };

  function submitCustomBasket(CustomBasket) {
    if (!token) {
      return navigate("/login");
    }

    if (CustomBasket?.line_items?.length < 1) {
      return;
    }

    const toastId = toast.loading("Creating your basket subscription...");
    const deliveryChargeBasket = rxjsStore.getDeliveryChargeBasket();
    const deliveryChargeButcher = rxjsStore.getDeliveryChargeButcher();
    let newLineItems = CustomBasket?.line_items.filter(
      (item) => item.name !== "Delivery charge"
    );
    CustomBasket.line_items = newLineItems;
    if (customBasket?.line_items[0].type === "BASKET") {
      CustomBasket?.line_items.push(deliveryChargeBasket);
    } else {
      CustomBasket?.line_items.push(deliveryChargeButcher);
    }

    BasketService.submitCustomBasket({ ...CustomBasket, coupon })
      .then((response) => {
        if (!response?.Result) {
          return toast.error(response?.message, { id: toastId });
        }
        rxjsStore.clearCustomBasket();
        dispatch(activePlans(zohoCustomerId));
        const id = customerId?.toString();
        dispatch(getTransactions(id));
        dispatch(resetCoupon());
        toast.success("Subscription Created...", { id: toastId });

        if (!cart?.items?.length) {
          setSubRef(response?.Message_Ref_No);
          return setOpenCustomRating(true);
        }

        navigate("/home");
      })
      .catch((err) => {
        const serverMessage = err?.response.data?.message_text;
        return toast.error(
          serverMessage
            ? serverMessage
            : "Basket Subscriptions Could not be created...",
          {
            id: toastId,
          }
        );
      });
  }

  const handlePhoneVerification = () => {
    setShowModal(true);
    setSelectedModal("phone");
  };

  const removeSpacesAndNewlines = (str) => {
    const cleanedXmlString = str.replace(/\n/g, "").replace(/\s/g, "");

    return cleanedXmlString;
  };

  const handleRecommendations = (items) => {
    try {
      if (items?.length) {
        let isBasket = false;
        let isButcher = false;
        let item_type_id;

        items?.filter((item) => {
          if (item?.type === "BASKET") {
            isBasket = true;
          }
          if (item?.type === "BUTCHER") {
            isButcher = true;
          }
        });
        item_type_id =
          isBasket && isButcher
            ? "0"
            : isBasket && !isButcher
            ? "1"
            : !isBasket && isButcher
            ? "2"
            : "0";

        const itemsIds = items?.map((item) => {
          return `
          <a><item_id>${item?.item_id}</item_id></a>
          `;
        });
        const xmlStructure = `<root>${itemsIds}</root>`;
        const removedString = removeSpacesAndNewlines(xmlStructure);

        const data = {
          xml_string_item: removedString,
          item_type_id,
        };

        const payload = {
          data,
          customer_id: customerId,
        };

        dispatch(fetchRecommendations(payload))
          .unwrap()
          .then((res) => {
            if (res?.items?.length) {
              setFetchedRecommendations(res?.items);
            }
          })
          .catch((err) => err);
      }
    } catch (err) {
      return err;
    }
  };

  return (
    <div>
      {token ? <SignedInNavbarImproved /> : <Navbar />}
      <div className="mx-auto max-w-[1600px] px-2 xsm:px-4 sm:px-8 lg:grid lg:grid-cols-10 lg:pl-14 lg:pr-0">
        <CartDetails
          fetchedCart={fetchedCart}
          handleCartUpdate={handleCartUpdate}
          existingDeliveryInfo={existingDeliveryInfo}
          handleDelete={handleDelete}
          calculateTotalBasket={calculateTotalBasket}
          handleContinue={handleContinue}
          handleLocalDelete={handleLocalDelete}
          fetchedPlans={fetchedPlans}
          fetchedCountries={fetchedCountries}
          countries={countries}
          customBasket={customBasket}
          updatedLocalCart={updatedLocalCart}
          handleLocalCartUpdate={handleLocalCartUpdate}
          removeCustomBasket={removeCustomBasket}
        />
        <OrderDetails
          fetchedCart={fetchedCart}
          customBasket={customBasket}
          calculateTotalBasket={calculateTotalBasket}
          updatedLocalCart={updatedLocalCart}
          handleCouponVerify={handleCouponVerify}
          setCouponCode={setCouponCode}
          couponCode={couponCode}
          setOpen={setOpen}
          subTotalOfflinePlanPrice={subTotalOfflinePlanPrice}
          subTotalOfflineAddonPrice={subTotalOfflineAddonPrice}
          fetchedCouponCode={fetchedCouponCode}
          coupon={coupon}
          priceAlert={priceAlert}
          showPriceAlert={showPriceAlert}
          handleGeneralCheckout={handleGeneralCheckout}
          open={open}
          subTotalPlanPrice={subTotalPlanPrice}
          subTotalAddonPrice={subTotalAddonPrice}
          deliveryFees={deliveryFees}
        />
        <MUIModal
          open={openRating}
          onClose={() => setOpenRating(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div className="mt-6 overflow-hidden px-2 xsm:px-4">
            <Box className="mx-auto max-w-lg rounded-md bg-[#FEFEFD] p-4 shadow shadow-gray-500">
              <FeedBackSubRatingVasPopup
                setOpen={setOpenRating}
                subRef={subRef}
              />
            </Box>
          </div>
        </MUIModal>
        <MUIModal
          open={openCustomRating}
          onClose={() => setOpenCustomRating(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div className="mt-6 overflow-hidden px-2 xsm:px-4">
            <Box className="mx-auto max-w-lg rounded-md bg-[#FEFEFD] p-4 shadow shadow-gray-500">
              <FeedBackRatingPopup
                setOpen={setOpenCustomRating}
                type="shopping"
                salesOrderId={subRef}
              />
            </Box>
          </div>
        </MUIModal>

        <MUIModal
          open={openUnverifiedPhone}
          onClose={() => setOpenUnverifiedPhone(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div className="mt-6 overflow-hidden px-2 xsm:px-4">
            <Box className="mx-auto max-w-lg rounded-md bg-[#FEFEFD] p-4 shadow shadow-gray-500">
              <UpdatePhoneNumber
                setOpen={setOpenUnverifiedPhone}
                type="shopping"
                salesOrderId={subRef}
              />
            </Box>
          </div>
        </MUIModal>

        <MUIModal
          open={openUnverifiedPhone}
          onClose={() => setOpenUnverifiedPhone(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div className="mt-6 overflow-hidden px-2 xsm:px-4">
            <Box className="mx-auto max-w-lg rounded-md bg-[#FEFEFD] p-4 shadow shadow-gray-500">
              <AddYourBVNNumber
                setOpen={setOpenUnverifiedPhone}
                handlePhoneVerification={handlePhoneVerification}
              />
            </Box>
          </div>
        </MUIModal>
      </div>
      <div className="mt-6">
        {fetchedRecommendations && fetchedRecommendations?.length && (
          <RecommendationsPage items={fetchedRecommendations} />
        )}
      </div>
      <Modal
        visible={showModal}
        onClose={handleOnClose}
        selected={selectedModal}
        cartAmount={cartAmount}
        paymentProceed={paymentProceed}
      />
    </div>
  );
}

export default CartPage;
