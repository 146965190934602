import React, { useCallback, useEffect, useState } from "react";
import useEmblaCarousel from "embla-carousel-react";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { fetchProductFeatureInfo } from "../../../store/actions";
import BasketImage from "../../../assets/basketImage.png";
import { IoIosHeart } from "react-icons/io";
import { FaStar } from "react-icons/fa";

const ProductCarousel = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [emblaRef, emblaApi] = useEmblaCarousel({ loop: false });

  const [fetchedFeatureProduct, setFetchedFeatureProduct] = useState([]);

  useEffect(() => {
    dispatch(fetchProductFeatureInfo())
      .unwrap()
      .then((res) => {
        if (!res?.product_feature_info?.length) {
          setFetchedFeatureProduct([]);
        }
        setFetchedFeatureProduct(res?.product_feature_info);
      })
      .catch((err) => err);
  }, []);

  const scrollPrev = useCallback(() => {
    if (emblaApi) emblaApi.scrollPrev();
  }, [emblaApi]);

  const scrollNext = useCallback(() => {
    if (emblaApi) emblaApi.scrollNext();
  }, [emblaApi]);

  return (
    <div className="flex items-center justify-between overflow-hidden @container">
      <button
        onClick={scrollPrev}
        className="mr-1 w-fit rounded-full border-[1px] border-gray-200 p-1 text-gray-700 hover:bg-gray-200 active:bg-gray-100 "
      >
        <MdOutlineKeyboardArrowRight className="h-6 w-6 rotate-180" />
      </button>
      <div className="flex-1 overflow-hidden" ref={emblaRef}>
        <div className="flex">
          {fetchedFeatureProduct?.length &&
            fetchedFeatureProduct.map((buyList, buyListIndex) => {
              return (
                <div
                  onClick={() => navigate(buyList?.action_url_web)}
                  className="mr-4 w-full min-w-0 flex-shrink-0 flex-grow-0 basis-full py-2 @lg:basis-1/2 @2xl:basis-1/3 @4xl:basis-1/4"
                >
                  <div
                    key={buyListIndex}
                    style={{
                      borderColor: buyList.text_color,
                      backgroundColor: buyList.background_color,
                    }}
                    className={`flex w-full max-w-[290px] flex-shrink-0 cursor-pointer flex-row justify-between whitespace-nowrap rounded border-[0.05px] border-current border-opacity-10 py-1 pl-3 pr-1 transition-all duration-500 hover:scale-105`}
                  >
                    <div>
                      <div className="mt-2 flex flex-row">
                        <h2
                          style={{ color: buyList.text_color }}
                          className={`${
                            buyList.special_image_name == "Coming Soon"
                              ? ""
                              : "w-[105px]"
                          } mr-2 mt-1 text-sm font-semibold`}
                        >
                          {buyList.product_name}
                        </h2>
                        <div>
                          {buyList.special_image_name == "Heart" && (
                            <div style={{ color: buyList.text_color }}>
                              <IoIosHeart className="h-2.5 w-2.5" />
                              <div className="flex items-center space-x-2">
                                <IoIosHeart className="h-1 w-1" />
                                <IoIosHeart className="h-2 w-2" />
                              </div>
                            </div>
                          )}
                          {buyList.special_image_name == "Star" && (
                            <div style={{ color: buyList.text_color }}>
                              <FaStar className="h-2.5 w-2.5" />
                              <div className="flex items-center space-x-2">
                                <FaStar className="h-1 w-1" />
                                <FaStar className="h-2 w-2" />
                              </div>
                            </div>
                          )}
                          {buyList.special_image_name == "Coming Soon" && (
                            <div
                              style={{
                                color: buyList.text_color,
                                borderColor: buyList.text_color,
                              }}
                              className="w-fit whitespace-nowrap rounded border-[0.3px] bg-white px-1 py-[2px] text-[10px] font-semibold"
                            >
                              Coming soon!
                            </div>
                          )}
                        </div>
                      </div>
                      <p
                        style={{ color: buyList.text_color }}
                        className={`mt-1 w-fit text-[10px] font-normal`}
                      >
                        {buyList.product_description}
                      </p>
                    </div>
                    <div>
                      <img
                        src={buyList.image_url}
                        alt=""
                        className="aspect-auto h-16 w-20 object-contain"
                      />
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
      <button
        onClick={scrollNext}
        className="ml-1 w-fit rounded-full border-[1px] border-gray-200 p-1 text-gray-700 hover:bg-gray-200 active:bg-gray-100 "
      >
        <MdOutlineKeyboardArrowRight className="h-6 w-6" />
      </button>
    </div>
  );
};

export default ProductCarousel;
