import { createAsyncThunk } from "@reduxjs/toolkit";
import { startLoad, stopLoad } from "../slices/appSlice";
import { handleError } from "../slices/errorSlice";
import ProductSharingService from "../../services/productSharingService";

export const fetchHowItWorks = createAsyncThunk(
  "get/fetchHowItWorks",
  async (data, { dispatch }) => {
    dispatch(startLoad());

    try {
      const res = await ProductSharingService.howItWorks(data);

      return res?.data || res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);

export const fetchFAQ = createAsyncThunk(
  "get/fetchFAQ",
  async (data, { dispatch }) => {
    dispatch(startLoad());

    try {
      const res = await ProductSharingService.faq(data);

      return res?.data || res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);
