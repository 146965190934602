import React from "react";

const PurchaseSummaryPopup = () => {
  return (
    <div className="mb-8 px-2 @container sm:px-6">
      <h2 className="mt-6 text-xl font-semibold text-gray-700">
        Purchase Summary
      </h2>
      <div className="mt-2 divide-y-[1px] divide-gray-200">
        <div className="py-2">
          <p className="text-xs font-medium text-gray-500">Product Name</p>
          <p className="mt-1 text-sm font-semibold text-gray-700">
            Whole Cow x10
          </p>
        </div>
        <div className="py-3">
          <p className="text-sm font-semibold text-gray-500">
            Slot Information
          </p>
          <p className="mt-1 text-xs font-medium text-gray-700">
            Slots Purchased by you{" "}
            <span className="text-sm font-normal text-gray-500">4</span>
          </p>
          <p className="mt-1 text-xs font-medium text-gray-700">
            Price Per Slot:{" "}
            <span className="text-sm font-normal text-gray-500">N100,000</span>
          </p>
          <p className="mt-1 text-xs font-medium text-gray-700">
            Total Amount for Purchase:{" "}
            <span className="text-sm font-normal text-gray-500">N400,000</span>
          </p>
        </div>
        <div className="py-3">
          <p className="text-sm font-semibold text-gray-500">Purchase Type</p>
          <p className="mt-1 text-xs font-medium text-gray-700">
            Purchase Option:{" "}
            <span className="cursor-pointer text-sm font-normal text-olivine-600 hover:underline hover:underline-offset-4">
              Gift-Edit Recipients Details
            </span>
          </p>
          <p className="my-1 text-base font-bold text-olivine-600">OR</p>
          <p className="mt-1 text-xs font-medium text-gray-700">
            Purchase Option:{" "}
            <span className="text-sm font-normal text-gray-500">
              Purchase For self
            </span>
          </p>
        </div>
        <div className="py-3">
          <p className="text-sm font-semibold text-gray-500">
            Slot status at time of purchase
          </p>
          <p className="mt-1 text-xs font-medium text-gray-700">
            Total Slots{" "}
            <span className="text-sm font-normal text-gray-500">10</span>
          </p>
          <p className="mt-1 text-xs font-medium text-gray-700">
            Slots Filled:{" "}
            <span className="text-sm font-normal text-gray-500">1</span>
          </p>
          <p className="mt-1 text-xs font-medium text-gray-700">
            Slots yet to be filled:{" "}
            <span className="text-sm font-normal text-gray-500">9</span>
          </p>
        </div>
        <div className="py-3">
          <p className="text-sm font-semibold text-gray-500">
            Deadline Information
          </p>
          <p className="mt-1 text-xs font-medium text-gray-700">
            Slot Filling Deadline:{" "}
            <span className="text-sm font-normal text-gray-500">
              10-November 2020
            </span>
          </p>
          <p className="mt-1 text-xs font-medium text-gray-700">
            Slot Deadline Option:{" "}
            <span className="text-sm font-normal text-gray-500">
              Split Remaining slots among buyers
            </span>
          </p>
        </div>
        <div className="py-3">
          <p className="text-sm font-semibold text-gray-500">
            Notifications and Updates
          </p>
          <p className="text-[11px] font-semibold text-gray-500">
            where the user will receive updates about the slot filling progress
            or additional payments.
          </p>
          <p className="mt-1 text-xs font-medium text-gray-700">
            Email Address{" "}
            <span className="text-sm font-normal text-gray-500">
              kaidoe@gmail.com{" "}
            </span>
          </p>
          <p className="mt-1 text-xs font-medium text-gray-700">
            Phone Number{" "}
            <span className="text-sm font-normal text-gray-500">
              0801 234 5678
            </span>
          </p>
        </div>
      </div>
      <div className="mt-4 rounded-md border-[1px] border-gray-200 bg-[#EDEDED] p-3">
        <h3 className="text-sm font-semibold text-gray-700">Policy Reminder</h3>
        <p className="mt-1 text-xs font-normal text-gray-500">
          If all slots are not filled by the deadline (1 day to the sharing
          date), you will be asked to cover the extra cost with other buyers
        </p>
        <div className="mt-2 flex flex-row items-center">
          <input
            type="checkbox"
            className="h-4 w-4 cursor-pointer rounded border-[1px] border-gray-300 checked:text-[#558223] checked:ring-0 checked:ring-[#558223] focus:ring-0 focus:ring-[#558223]"
          />
          <span className="ml-1 text-xs  text-gray-600">
            I agree with the{" "}
            <span className="cursor-pointer font-medium text-[#558223] hover:underline hover:underline-offset-4">
              Terms and Conditions
            </span>
          </span>
        </div>
      </div>
      <div className="mt-10 flex flex-col @sm:flex-row-reverse @sm:items-center @sm:justify-between">
        <button className="w-full transform cursor-pointer rounded-md bg-[#558223] px-4 py-3.5 text-sm font-medium text-white transition-all duration-200 hover:bg-olivine-500/80 focus:outline-none active:bg-olivine-600 @sm:max-w-[180px]">
          Proceed to Payment
        </button>
        <button className="mt-4 w-full transform cursor-pointer rounded-md border-[1px] border-gray-300 bg-white px-4 py-3.5 text-sm font-medium text-gray-600 transition-all duration-200 focus:outline-none @sm:mt-0 @sm:max-w-[180px]">
          Back
        </button>
      </div>
    </div>
  );
};

export default PurchaseSummaryPopup;
