import React, { useCallback, useEffect, useState } from "react";
import useEmblaCarousel from "embla-carousel-react";
import Autoplay from "embla-carousel-autoplay";
import { useDispatch, useSelector } from "react-redux";
import { userAdvertsGet } from "../../../store/actions";
import { useNavigate } from "react-router-dom";

const FeaturedCarousel = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [fetchedAdvertLinks, setFetchedAdvertLinks] = useState();

  const { adverts } = useSelector((state) => state.user);

  const [emblaRef, emblaApi] = useEmblaCarousel({ loop: true }, [
    Autoplay({ delay: 5000 }),
  ]);

  useEffect(() => {
    if (!adverts?.length) {
      advertsFetcher();
    }

    if (adverts?.length) {
      setFetchedAdvertLinks(adverts);
    }
  }, [adverts]);

  function advertsFetcher() {
    try {
      dispatch(userAdvertsGet());
    } catch (err) {
      return err;
    }
  }

  return (
    <div className="mt-2  w-full overflow-hidden @container" ref={emblaRef}>
      <div className="flex">
        {fetchedAdvertLinks?.length &&
          fetchedAdvertLinks.map((featuredImage, slideIndex) => (
            <div
              key={slideIndex}
              className="w-full min-w-0 flex-shrink-0 flex-grow-0 cursor-pointer sm:basis-1/2 lg:basis-1/3"
            >
              <div className="mr-4 flex-shrink-0">
                <img
                  src={featuredImage?.feature_image_url}
                  alt="banner"
                  className="aspect-auto h-40 w-full flex-shrink-0 object-contain"
                  onClick={() =>
                    navigate(featuredImage?.feature_action_url_web)
                  }
                />
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default FeaturedCarousel;
