import React from "react";
import Banner from "../assets/grocedyBannerTwo.svg";
import GooglePlayImage from "../assets/googlePlayImage.svg";
import AppStoreImage from "../assets/appstoreImage.svg";
import { motion } from "framer-motion";
import WalletImageIcon from "../assets/walletImageIcon.svg";
import BasketImageIcon from "../assets/basketImageIcon.svg";
import { Link } from "react-router-dom";

function WelcomeToGrocedyTwo() {
  return (
    <div className="@container">
      <div className="mx-auto max-w-[1600px] bg-primary_color px-4 @xl:grid @xl:grid-cols-2 @xl:items-center sm:px-8 lg:px-16">
        <div className="mt-6 md:mt-0">
          <h1 className="text-center align-top text-4xl font-bold leading-snug tracking-normal text-gravel-500 @xl:text-left xsm:text-5xl">
            Quality Groceries, Affordable Prices.
          </h1>
          <div className="mt-6 max-w-[1600px] ">
            <p className="text-center text-sm font-normal text-gray-700 md:max-w-lg md:text-left">
              Now you can shop smart, skip the lines and enjoy the cheapest food
              prices. Grocedy makes Grocery Shopping Easy, Fun, Affordable, and
              Rewarding for everyone.
            </p>
          </div>
          <div className="mt-8 whitespace-nowrap @xl:flex @xl:items-center @xl:space-x-2 @2xl:space-x-3">
            <div className="flex flex-row items-center justify-center space-x-2 @xl:justify-start @2xl:space-x-3">
              <a href="https://download.grocedy.com/u/12811202 ">
                <div className="cursor-pointer">
                  <img
                    src={GooglePlayImage}
                    alt="image"
                    className="h-14 w-auto"
                  />
                </div>
              </a>
              <a href="https://download.grocedy.com/u/12811202 ">
                <div className="cursor-pointer">
                  <img
                    src={AppStoreImage}
                    alt="image"
                    className="h-14 w-auto"
                  />
                </div>
              </a>
            </div>
            <Link to="/register">
              <button className="mt-5 w-full transform cursor-pointer rounded-md bg-olivine-500 px-3 py-3.5 text-xs font-semibold text-white transition-all duration-200 hover:bg-olivine-400 focus:outline-none focus:ring-[2px] focus:ring-olivine-500 focus:ring-opacity-60 focus:ring-offset-1 active:bg-olivine-600 @xl:mt-0 @xl:h-auto @xl:py-1.5 @2xl:py-2.5 @3xl:py-3.5 @4xl:py-[17px] xsm:px-4 xsm:text-sm sm:px-8">
                Sign Up
              </button>
            </Link>
          </div>
        </div>
        <div>
          <motion.img
            src={Banner}
            className="h-[550px] pt-10 md:pt-12"
            alt="baner"
            animate={{ scale: 1 }}
            initial={{ scale: 0.8 }}
            transition={{ stiffness: 200, type: "spring" }}
          />
        </div>
      </div>
      <div className="mx-auto grid max-w-[1600px] place-items-center px-3 xsm:px-4 sm:px-8 md:mt-16 lg:px-16">
        <div className="font-sans text-2xl font-bold text-gray-600">
          Easy as 1, 2, 3!
        </div>
        <div className="mt-8 grid grid-cols-1 gap-14 sm:gap-14 md:grid-cols-2 md:gap-10 lg:grid-cols-3 lg:gap-8">
          <div className="h-full w-full rounded-lg bg-[#558223] px-4 pb-4 pt-8 sm:px-10">
            <img src={WalletImageIcon} className="h-16 w-auto" alt="" />
            <div className="mt-8 align-top text-xl font-bold text-white">
              Load Your Wallet
            </div>
            <div className="mt-4 align-top text-xs font-semibold leading-4 text-[#DFE5D1]">
              You can fund your wallet manually or set up auto fund to save
              daily, weekly or monthly for your foodstuff
            </div>
          </div>
          <div className="h-full w-full rounded-lg bg-[#558223] px-4 pb-4 pt-8 sm:px-8">
            <img src={BasketImageIcon} className="h-16 w-auto" alt="" />
            <div className="mt-8 align-top text-xl font-bold text-white">
              Select Your Basket
            </div>
            <div className="mt-4 align-top text-xs font-semibold leading-4 text-[#DFE5D1]">
              You can pick from our pre-curated baskets or create your own
              basket with food items you love.
            </div>
          </div>
          <div className="h-full w-full rounded-lg bg-[#558223] px-4 pb-4 pt-8 sm:px-10">
            <img src={WalletImageIcon} className="h-16 w-auto" alt="" />
            <div className="mt-8 align-top text-xl font-bold text-white">
              Get Your Monthly Food Basket
            </div>
            <div className="mb-8 mt-4 align-top text-xs font-semibold leading-4 text-[#DFE5D1]">
              Sit back and we’ll have your food basket delivered to your
              doorstep each month.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WelcomeToGrocedyTwo;
