import React, { useState } from "react";
import { IoClose } from "react-icons/io5";
import OtpInput from "react-otp-input";
import CircularProgress from "@mui/material/CircularProgress";
import { toast } from "react-hot-toast";
import { useForm } from "react-hook-form";

const passwordRegex = /^[A-Za-z0-9@*&^%$!=@]+$/;

function NewPassword(props) {
  const {
    register,
    setValue,
    getValues,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm();
  const [passwordType, setPasswordType] = useState("password");

  function submitNewPassword() {
    const formData = getValues();

    if (formData?.password !== formData?.password2) {
      return toast.error("Password not matched!");
    }

    props.submitNewPassword(formData?.password);
  }

  function onErrors(error) {
    toast.error("Password is required");
  }

  function changeType() {
    if (passwordType === "password") {
      setPasswordType("text");
    }
    if (passwordType === "text") {
      setPasswordType("password");
    }
  }

  return (
    <div className="p-4">
      <div className="flex justify-end">
        <div className="cursor-pointer rounded-full p-1 hover:bg-gray-100 active:bg-gray-200">
          <IoClose onClick={() => props?.closeModal()} className="h-5 w-5" />
        </div>
      </div>
      <form onSubmit={handleSubmit(submitNewPassword, onErrors)}>
        <div className="flex flex-col items-center justify-center space-x-2">
          <h2 className="mt-4 text-xl font-semibold text-gray-700">
            New Password
          </h2>
          <p className="mt-1 text-center text-sm text-gray-600">
            Input your new password
          </p>
          <div className="mx-auto mt-2 w-full max-w-sm space-y-4">
            <div>
              <label htmlFor="password" className="ml-2 text-sm text-gray-600">
                New Password
              </label>
              <div className="relative flex items-center justify-end rounded-md">
                <div className="flex-grow">
                  <input
                    type={passwordType}
                    placeholder="Enter your new password"
                    className="block w-full rounded-md border-gray-300 bg-gray-100 pr-10 text-sm text-gray-600 placeholder:text-sm placeholder:text-gray-400 focus:border-olivine-500 focus:ring-olivine-500"
                    {...register("password", {
                      required: "Password is required!",
                      pattern: {
                        value: passwordRegex,
                        message:
                          "At least one capital letter, one special character is required!",
                      },
                    })}
                  />
                </div>
                <div
                  onClick={() => changeType()}
                  className="absolute mr-3 flex-grow cursor-pointer text-xs font-semibold text-gravel-500 transition-all duration-300 hover:text-olivine-500"
                >
                  view
                </div>
              </div>
            </div>
            <div>
              <label
                htmlFor="confirmPassword"
                className="ml-2 text-sm text-gray-600"
              >
                Confirm Password
              </label>
              <div className="relative flex items-center justify-end rounded-md">
                <div className="flex-grow">
                  <input
                    type={passwordType}
                    placeholder="Confirm your password"
                    className="block w-full rounded-md border-gray-300 bg-gray-100 pr-10 text-sm text-gray-600 placeholder:text-sm placeholder:text-gray-400 focus:border-olivine-500 focus:ring-olivine-500"
                    {...register("password2", {
                      required: "confirm Password is required!",
                      pattern: {
                        value: passwordRegex,
                        message:
                          "At least one capital letter, one special character is required!",
                      },
                    })}
                  />
                </div>
                <div
                  onClick={() => changeType()}
                  className="absolute mr-3 flex-grow cursor-pointer text-xs font-semibold text-gravel-500 transition-all duration-300 hover:text-olivine-500"
                >
                  view
                </div>
              </div>
            </div>
          </div>
          <div className="mx-auto mt-8 w-full max-w-[250px] pb-2">
            <button className="w-full transform cursor-pointer rounded-md border-[1px] border-olivine-500 bg-olivine-500 p-8 py-3 text-sm font-semibold text-white  transition-all duration-200 hover:bg-olivine-400 focus:outline-none focus:ring-[2px] focus:ring-olivine-500 focus:ring-opacity-60 focus:ring-offset-1 active:bg-olivine-600">
              {props?.isLoading === true ? (
                <CircularProgress size={20} color="inherit" />
              ) : (
                "Save Password"
              )}{" "}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default NewPassword;
