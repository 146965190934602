import React from "react";
import Navbar from "../../Navbar";

function TermsAndConditions() {
  return (
    <div className="mx-auto w-full max-w-[2520px] bg-gray-200">
      <Navbar />
      <div className="px-4 pb-40 xsm:px-6">
        <div className="mx-auto mt-10 max-w-3xl rounded-2xl border-[0.5px] border-gray-300 bg-[#F2FAD3]">
          <div className="px-10 py-8 sm:px-20 sm:py-14">
            <h1 className="text-center text-3xl font-bold text-gray-700">
              Acknowledgement of Terms and Conditions for Grocedy Subscription
            </h1>
            <p className="mt-8 text-base font-medium text-gray-700">
              You acknowledge and agree that Grocedy reserves the right to
              decline acceptance into its food subscription service. You
              understand that Grocedy may adjust the delivery date if faced with
              logistics issues beyond its control.
            </p>
            <p className="mt-4 text-base font-medium text-gray-700">
              You agree not to hold Grocedy or its staff liable for any
              misrepresentation of products or policies, except for information
              provided in the official product brochure and on the website
              (www.grocedy.com). You are responsible for completing all
              subscription payments within 27 days each month to be eligible for
              food delivery.
            </p>
            <p className="mt-4 text-base font-medium text-gray-700">
              You understand that all payments are non-refundable. Once Grocedy
              receives your subscription or instalment payment, no refunds will
              be provided. You may alter your subscription category only after
              fully completing and closing your current plan, with changes
              effective the following month.
            </p>
            <p className="mt-4 text-base font-medium text-gray-700">
              You acknowledge that Grocedy may alter subscription fees and
              charges as needed, without prior notice, based on market
              conditions. These adjustments may impact the quantity of items
              delivered to you, which will reflect market rates on the delivery
              date.
            </p>
            <p className="mt-4 text-base font-medium text-gray-700">
              Complaints must be directed solely via email to
              <span className="text-blue-500 underline underline-offset-2">
                {" "}
                customercare@grocedy.com
              </span>{" "}
              or through live chat. If no one is available to receive a
              delivery, it will be returned to the Grocedy office, where you
              must pick it up at your own expense.
            </p>
            <p className="mt-4 text-base font-medium text-gray-700">
              Please note that any damaged or unsatisfactory items must be
              rejected at the time of delivery or reported within 24 hours. Any
              items not rejected on the spot or escalated within this time frame
              will not be eligible for replacement.
            </p>
            <p className="mt-4 text-base font-medium text-gray-700">
              All subscription payments must be completed within 27 days from
              the subscription start date. Delivery will be made to your
              registered address within 5 days of your final payment or within 3
              working days if paid in full.
            </p>
            {/* <ol className="mt-8 list-inside list-decimal space-y-1 text-sm font-medium text-gray-600">
              <li>
                You acknowledge and agree that Grocedy has the right to decline
                acceptance into its food subscription service.
              </li>
              <li>
                You understand that Grocedy may extend the delivery date if
                faced with logistics issues beyond its control.
              </li>
              <li>
                You agree not to hold Grocedy or its staff liable for any
                misrepresentation of products or policies, except for the
                information provided in the product brochure and on the website{" "}
                <span className="text-olivine-600 underline">
                  <a href="https://www.grocedy.com/">(www.grocedy.com).</a>
                </span>
              </li>
              <li>
                You are responsible for completing all your subscription
                instalments and charges within 27 days every month before being
                considered for food delivery.
              </li>
              <li>
                You understand that all payments for subscriptions are
                non-refundable. Once Grocedy has received your subscription
                payment or any instalment payment, no refunds will be provided.
              </li>
              <li>
                You can only alter your subscription category after completing
                and closing an existing subscription plan category which has
                been fully paid for at least one month. Upgrade and downgrade of
                subscription plans will commence from the following month.
              </li>
              <li>
                You acknowledge that Grocedy may alter or review the
                subscription fee and charges if necessary, without prior notice,
                due to present market conditions. These changes may affect the
                quantity of items delivered to you.
              </li>
              <li>
                The items delivered to you will be subject to the market rate on
                the day of delivery.
              </li>
              <li>
                All complaints should be forwarded only by email to
                customercare@grocedy.com or through the live chat options.
              </li>
              <li>
                Any delivery not attended to by your registered receiver or
                yourself will be returned to the Grocedy office, wherein pickup
                will be at your own expense and invitation.
              </li>
              <li>
                All subscription payments must be completed within 27 days from
                the start of the subscription date.
              </li>
              <li>
                {" "}
                Delivery will be made to your registered address within 5 days
                of your last payment date that completed payment for your basket
                or up to 3 working days if you paid in full for your basket.
              </li>
            </ol> */}
            <div className="mt-6">
              <h2 className="text-lg font-medium text-gray-800">
                Acknowledgement
              </h2>
              <p className="mb-16 text-base font-medium text-gray-700">
                I hereby acknowledge that I have read, understood, and agreed to
                the above terms and conditions for my subscription to Grocedy
                and for using its platform.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TermsAndConditions;
