import React, { useState } from "react";
import Container from "../my_container/Container";
import SignedInNavbarImproved from "../../SignedInNavbarImproved";
import { Link, useNavigate } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";
import { IoClose } from "react-icons/io5";
import ProgressBar from "@ramonak/react-progress-bar";
import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import GropoolVegetableImage from "../../../assets/gropoolVegetableImage.svg";
import { FaMinus, FaPlus } from "react-icons/fa";
import RecommendedSlots from "./recommended_slots/RecommendedSlots";
import PurchaseSummaryPopup from "./PurchaseSummaryPopup";
import CreatedNewGropoolSuccessfullyPopup from "./create_gropool/CreatedNewGropoolSuccessfullyPopup";
import DeadlineApproachingPopup from "./create_gropool/DeadlineApproachingPopup";

const GropoolDetailPage = () => {
  const navigate = useNavigate();
  const [count, setCount] = useState(1);
  const [openSummaryModal, setOpenSummaryModal] = useState(false);
  const [createdSuccessfully, setCreatedSuccessfully] = useState(false);
  return (
    <>
      <SignedInNavbarImproved />
      <div className="bg-white">
        <Container className="mb-40 @container">
          <div className="mt-10 flex flex-row items-center justify-between">
            <div className="flex items-center space-x-2">
              <div className="rounded-full p-2 hover:bg-gray-200 active:bg-gray-100">
                <BiArrowBack
                  onClick={() => navigate(-1)}
                  className="h-5 w-5 cursor-pointer text-gray-600"
                />
              </div>
              <h2 className="text-xl font-bold text-gray-700 sm:text-2xl">
                Red Bell Pepper -10 Slots
              </h2>
            </div>
            <Link to="/gropool/history">
              <button className="border-b-[1.5px] border-b-white text-base font-semibold text-[#73964C] hover:border-b-[#73964C]">
                Gropool History
              </button>
            </Link>
          </div>
          <div className="mt-6 grid grid-cols-1 gap-4 lg:grid-cols-2">
            <div
              className={`min-w-80 w-full flex-1 cursor-pointer rounded-lg border-[1px] border-gray-100 p-6 shadow-lg`}
            >
              <div className=" flex flex-shrink-0 flex-row items-center space-x-4">
                <div>
                  <img
                    src={GropoolVegetableImage}
                    alt="img"
                    className="aspect-auto h-36 w-36 flex-shrink-0 object-contain"
                  />
                </div>
                <div>
                  <p className="rounded-full bg-[#FDECDE] px-4 py-1.5 text-sm font-medium text-[#F48631]">
                    4 Slots Left
                  </p>
                  <p className="mt-1 text-sm font-semibold text-gray-500">
                    12th October 2024
                  </p>
                  <p className="mt-1 text-lg font-bold text-gray-700">
                    N100,000 <span className="font-medium">/ Slot</span>
                  </p>
                </div>
              </div>
              <p className="mt-2 text-sm font-medium text-gray-800">
                Slots- 2 Slots filled; 8 Slots Available
              </p>
              <div>
                <div className="mt-3">
                  <ProgressBar
                    height="12px"
                    width="100%"
                    bgColor={`#558223`}
                    maxCompleted={`100`}
                    completed={`50`}
                    isLabelVisible={false}
                    barContainerClassName={`rounded-full bg-[#EDEEEC] `}
                  />
                </div>
                <div className="mt-1 flex flex-row items-center justify-between text-xs font-normal text-gray-600">
                  <p>Slots</p>
                  <p>5/10</p>
                </div>
              </div>
              <div>
                <button className="mt-4 flex w-full items-center justify-center rounded bg-[#C7CBC3] px-8 py-3.5 transition-all duration-300 hover:bg-[#C7CBC3]/60">
                  <div>
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 22 22"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M17.8817 7.22659L7.89499 2.23325C1.18666 -1.12675 -1.56668 1.62659 1.79332 8.33492L2.80832 10.3649C3.09999 10.9599 3.09999 11.6483 2.80832 12.2433L1.79332 14.2616C-1.56668 20.9699 1.17499 23.7233 7.89499 20.3633L17.8817 15.3699C22.3617 13.1299 22.3617 9.46659 17.8817 7.22659ZM14.1133 12.1733H7.81332C7.33499 12.1733 6.93832 11.7766 6.93832 11.2983C6.93832 10.8199 7.33499 10.4233 7.81332 10.4233H14.1133C14.5917 10.4233 14.9883 10.8199 14.9883 11.2983C14.9883 11.7766 14.5917 12.1733 14.1133 12.1733Z"
                        fill="#558223"
                      />
                    </svg>
                  </div>
                  <span className="ml-2 text-sm font-medium text-[#558223]">
                    Share Group info
                  </span>
                </button>
              </div>
            </div>
            <div
              className={`min-w-80 w-full flex-1 cursor-pointer rounded-lg border-[1px] border-gray-100 p-6 shadow-lg`}
            >
              <h2 className="mt-4 text-2xl font-semibold text-gray-800">
                More Information
              </h2>
              <div className="my-4 h-[2px] w-full bg-gray-200" />
              <div className="grid gap-4 grid-auto-fit-sm">
                <div className="flex flex-shrink-0 flex-row items-center space-x-2">
                  <div className="flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-[#ECE3F7]">
                    <div>
                      <svg
                        width="23"
                        height="17"
                        viewBox="0 0 27 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M25 0.148438H1.9C1.46239 0.148438 1.04271 0.322276 0.733274 0.631711C0.423839 0.941146 0.25 1.36083 0.25 1.79844V18.2984C0.25 18.736 0.423839 19.1557 0.733274 19.4652C1.04271 19.7746 1.46239 19.9484 1.9 19.9484H25C25.4376 19.9484 25.8573 19.7746 26.1667 19.4652C26.4762 19.1557 26.65 18.736 26.65 18.2984V1.79844C26.65 1.36083 26.4762 0.941146 26.1667 0.631711C25.8573 0.322276 25.4376 0.148438 25 0.148438ZM14.275 13.3484H6.025C5.8062 13.3484 5.59635 13.2615 5.44164 13.1068C5.28692 12.9521 5.2 12.7422 5.2 12.5234C5.2 12.3046 5.28692 12.0948 5.44164 11.9401C5.59635 11.7854 5.8062 11.6984 6.025 11.6984H14.275C14.4938 11.6984 14.7036 11.7854 14.8584 11.9401C15.0131 12.0948 15.1 12.3046 15.1 12.5234C15.1 12.7422 15.0131 12.9521 14.8584 13.1068C14.7036 13.2615 14.4938 13.3484 14.275 13.3484ZM20.875 10.0484H6.025C5.8062 10.0484 5.59635 9.96152 5.44164 9.8068C5.28692 9.65208 5.2 9.44224 5.2 9.22344C5.2 9.00463 5.28692 8.79479 5.44164 8.64007C5.59635 8.48536 5.8062 8.39844 6.025 8.39844H20.875C21.0938 8.39844 21.3036 8.48536 21.4584 8.64007C21.6131 8.79479 21.7 9.00463 21.7 9.22344C21.7 9.44224 21.6131 9.65208 21.4584 9.8068C21.3036 9.96152 21.0938 10.0484 20.875 10.0484ZM20.875 6.74844H6.025C5.8062 6.74844 5.59635 6.66152 5.44164 6.5068C5.28692 6.35208 5.2 6.14224 5.2 5.92344C5.2 5.70463 5.28692 5.49479 5.44164 5.34007C5.59635 5.18536 5.8062 5.09844 6.025 5.09844H20.875C21.0938 5.09844 21.3036 5.18536 21.4584 5.34007C21.6131 5.49479 21.7 5.70463 21.7 5.92344C21.7 6.14224 21.6131 6.35208 21.4584 6.5068C21.3036 6.66152 21.0938 6.74844 20.875 6.74844Z"
                          fill="#7D4CC8"
                        />
                      </svg>
                    </div>
                  </div>
                  <span className="text-base font-semibold text-gray-600">
                    2/4 Slots Filled
                  </span>
                </div>
                <div className="flex flex-shrink-0 flex-row items-center space-x-2">
                  <div className="flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-[#E8EFE1]">
                    <div>
                      <svg
                        width="23"
                        height="17"
                        viewBox="0 0 27 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M25 0.148438H1.9C1.46239 0.148438 1.04271 0.322276 0.733274 0.631711C0.423839 0.941146 0.25 1.36083 0.25 1.79844V18.2984C0.25 18.736 0.423839 19.1557 0.733274 19.4652C1.04271 19.7746 1.46239 19.9484 1.9 19.9484H25C25.4376 19.9484 25.8573 19.7746 26.1667 19.4652C26.4762 19.1557 26.65 18.736 26.65 18.2984V1.79844C26.65 1.36083 26.4762 0.941146 26.1667 0.631711C25.8573 0.322276 25.4376 0.148438 25 0.148438ZM14.275 13.3484H6.025C5.8062 13.3484 5.59635 13.2615 5.44164 13.1068C5.28692 12.9521 5.2 12.7422 5.2 12.5234C5.2 12.3046 5.28692 12.0948 5.44164 11.9401C5.59635 11.7854 5.8062 11.6984 6.025 11.6984H14.275C14.4938 11.6984 14.7036 11.7854 14.8584 11.9401C15.0131 12.0948 15.1 12.3046 15.1 12.5234C15.1 12.7422 15.0131 12.9521 14.8584 13.1068C14.7036 13.2615 14.4938 13.3484 14.275 13.3484ZM20.875 10.0484H6.025C5.8062 10.0484 5.59635 9.96152 5.44164 9.8068C5.28692 9.65208 5.2 9.44224 5.2 9.22344C5.2 9.00463 5.28692 8.79479 5.44164 8.64007C5.59635 8.48536 5.8062 8.39844 6.025 8.39844H20.875C21.0938 8.39844 21.3036 8.48536 21.4584 8.64007C21.6131 8.79479 21.7 9.00463 21.7 9.22344C21.7 9.44224 21.6131 9.65208 21.4584 9.8068C21.3036 9.96152 21.0938 10.0484 20.875 10.0484ZM20.875 6.74844H6.025C5.8062 6.74844 5.59635 6.66152 5.44164 6.5068C5.28692 6.35208 5.2 6.14224 5.2 5.92344C5.2 5.70463 5.28692 5.49479 5.44164 5.34007C5.59635 5.18536 5.8062 5.09844 6.025 5.09844H20.875C21.0938 5.09844 21.3036 5.18536 21.4584 5.34007C21.6131 5.49479 21.7 5.70463 21.7 5.92344C21.7 6.14224 21.6131 6.35208 21.4584 6.5068C21.3036 6.66152 21.0938 6.74844 20.875 6.74844Z"
                          fill="#558223"
                        />
                      </svg>
                    </div>
                  </div>
                  <span className="text-base font-semibold text-gray-600">
                    40Kg Per slot
                  </span>
                </div>
                <div className="flex flex-shrink-0 flex-row items-center space-x-2">
                  <div className="flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-[#FAE2EB]">
                    <div>
                      <svg
                        width="20"
                        height="21"
                        viewBox="0 0 25 26"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M0.0749512 9.73906C0.0749512 7.40514 0.0749511 6.23941 0.800126 5.51424C1.5253 4.78906 2.69103 4.78906 5.02495 4.78906H19.875C22.2089 4.78906 23.3746 4.78906 24.0998 5.51424C24.8249 6.23941 24.825 7.40514 24.825 9.73906C24.825 10.3219 24.825 10.614 24.6443 10.7959C24.4624 10.9766 24.1691 10.9766 23.5875 10.9766H1.31245C0.729589 10.9766 0.437539 10.9766 0.255626 10.7959C0.0749511 10.614 0.0749512 10.3207 0.0749512 9.73906ZM0.0749512 20.8766C0.0749512 23.2105 0.0749511 24.3762 0.800126 25.1014C1.5253 25.8266 2.69103 25.8266 5.02495 25.8266H19.875C22.2089 25.8266 23.3746 25.8266 24.0998 25.1014C24.8249 24.3762 24.825 23.2105 24.825 20.8766V14.6891C24.825 14.1062 24.825 13.8142 24.6443 13.6322C24.4624 13.4516 24.1691 13.4516 23.5875 13.4516H1.31245C0.729589 13.4516 0.437539 13.4516 0.255626 13.6322C0.0749511 13.8142 0.0749512 14.1074 0.0749512 14.6891V20.8766Z"
                          fill="#E14D8B"
                        />
                        <path
                          d="M6.26245 2.31445V6.02695M18.6375 2.31445V6.02695"
                          stroke="#E14D8B"
                          stroke-width="3.3"
                          stroke-linecap="round"
                        />
                      </svg>
                    </div>
                  </div>
                  <span className="text-base font-semibold text-gray-600">
                    12 October 2024
                  </span>
                </div>
                <div className="flex flex-shrink-0 flex-row items-center space-x-2">
                  <div className="flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-[#E8EFE1]">
                    <div>
                      <svg
                        width="23"
                        height="17"
                        viewBox="0 0 27 21"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M25 0.550781H1.9C1.46239 0.550781 1.04271 0.72462 0.733274 1.03405C0.423839 1.34349 0.25 1.76317 0.25 2.20078V18.7008C0.25 19.1384 0.423839 19.5581 0.733274 19.8675C1.04271 20.1769 1.46239 20.3508 1.9 20.3508H25C25.4376 20.3508 25.8573 20.1769 26.1667 19.8675C26.4762 19.5581 26.65 19.1384 26.65 18.7008V2.20078C26.65 1.76317 26.4762 1.34349 26.1667 1.03405C25.8573 0.72462 25.4376 0.550781 25 0.550781ZM14.275 13.7508H6.025C5.8062 13.7508 5.59635 13.6639 5.44164 13.5091C5.28692 13.3544 5.2 13.1446 5.2 12.9258C5.2 12.707 5.28692 12.4971 5.44164 12.3424C5.59635 12.1877 5.8062 12.1008 6.025 12.1008H14.275C14.4938 12.1008 14.7036 12.1877 14.8584 12.3424C15.0131 12.4971 15.1 12.707 15.1 12.9258C15.1 13.1446 15.0131 13.3544 14.8584 13.5091C14.7036 13.6639 14.4938 13.7508 14.275 13.7508ZM20.875 10.4508H6.025C5.8062 10.4508 5.59635 10.3639 5.44164 10.2091C5.28692 10.0544 5.2 9.84458 5.2 9.62578C5.2 9.40698 5.28692 9.19713 5.44164 9.04242C5.59635 8.8877 5.8062 8.80078 6.025 8.80078H20.875C21.0938 8.80078 21.3036 8.8877 21.4584 9.04242C21.6131 9.19713 21.7 9.40698 21.7 9.62578C21.7 9.84458 21.6131 10.0544 21.4584 10.2091C21.3036 10.3639 21.0938 10.4508 20.875 10.4508ZM20.875 7.15078H6.025C5.8062 7.15078 5.59635 7.06386 5.44164 6.90914C5.28692 6.75443 5.2 6.54458 5.2 6.32578C5.2 6.10698 5.28692 5.89713 5.44164 5.74242C5.59635 5.5877 5.8062 5.50078 6.025 5.50078H20.875C21.0938 5.50078 21.3036 5.5877 21.4584 5.74242C21.6131 5.89713 21.7 6.10698 21.7 6.32578C21.7 6.54458 21.6131 6.75443 21.4584 6.90914C21.3036 7.06386 21.0938 7.15078 20.875 7.15078Z"
                          fill="#558223"
                        />
                      </svg>
                    </div>
                  </div>
                  <span className="text-base font-semibold text-gray-600">
                    40Kg Per slot
                  </span>
                </div>
              </div>
            </div>
            <div
              className={`min-w-80 w-full flex-1 cursor-pointer rounded-lg border-[1px] border-gray-100 p-6 shadow-lg`}
            >
              <h2 className="mt-4 text-2xl font-semibold text-gray-800">
                Select Purchase Options
              </h2>
              <div className="mb-8 mt-4 h-[2px] w-full bg-gray-200" />
              <div className="mb-10 flex flex-row items-center space-x-4">
                <div className="flex flex-row items-center">
                  <button
                    onClick={() => setCount((prev) => prev - 1)}
                    className="rounded-md border-[1px] border-gray-200 bg-[#E8EFE1] p-2 hover:bg-gray-200 active:bg-[#E8EFE1]"
                  >
                    <FaMinus className="h-4 w-4 text-gray-600" />
                  </button>
                  <div className="px-2 text-base font-medium text-gray-700">
                    {count} Slot
                  </div>
                  <button
                    onClick={() => setCount((prev) => prev + 1)}
                    className="rounded-md border-[1px] border-gray-200 bg-[#E8EFE1] p-2 hover:bg-gray-200 active:bg-[#E8EFE1]"
                  >
                    <FaPlus className="h-4 w-4 text-gray-600" />
                  </button>
                </div>
                <div className="rounded-md bg-[#EFEDED] px-2 py-1 text-lg font-bold text-gray-800">
                  N100,000.00
                </div>
              </div>
            </div>
          </div>
          <div className="mt-12 flex max-w-[565px] flex-col @sm:flex-row @sm:items-center @sm:justify-between">
            <button
              onClick={() => setOpenSummaryModal(true)}
              className="w-full transform cursor-pointer rounded-md bg-[#558223] px-4 py-3.5 text-sm font-semibold text-white transition-all duration-200 hover:bg-olivine-500/80 focus:outline-none active:bg-olivine-600 @sm:max-w-[200px]"
            >
              Add to Cart
            </button>
            <button className="mt-4 w-full transform cursor-pointer rounded-md border-[1px] border-[#558223] bg-white px-4 py-3.5 text-sm font-semibold text-[#558223] transition-all duration-200 focus:outline-none @sm:mt-0 @sm:max-w-[200px]">
              Gift this Order
            </button>
          </div>
          <div className="mt-32">
            <RecommendedSlots />
          </div>
          <div>
            <Transition appear show={openSummaryModal} as={Fragment}>
              <Dialog
                as="div"
                className="relative z-50"
                onClose={() => setOpenSummaryModal(false)}
              >
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="fixed inset-0 bg-black/25" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                  <div className="flex min-h-full items-center justify-center p-4 text-center">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-out duration-300"
                      enterFrom="opacity-0 scale-95"
                      enterTo="opacity-100 scale-100"
                      leave="ease-in duration-200"
                      leaveFrom="opacity-100 scale-100"
                      leaveTo="opacity-0 scale-95"
                    >
                      <Dialog.Panel className="w-full max-w-xl transform overflow-hidden rounded-xl bg-white text-left align-middle shadow-xl transition-all">
                        <div className="scrollbar-thumb-[#1a3517] scrollbar-track-[#265426] h-full max-h-[525px] overflow-y-scroll p-2 scrollbar-thin">
                          <div
                            onClick={() =>
                              setOpenSummaryModal(!openSummaryModal)
                            }
                            className="fixed right-4 top-2 w-fit cursor-pointer rounded-full bg-[#558223] p-1.5 text-white"
                          >
                            <IoClose className="h-5 w-5" />
                          </div>
                          <PurchaseSummaryPopup />
                        </div>
                      </Dialog.Panel>
                    </Transition.Child>
                  </div>
                </div>
              </Dialog>
            </Transition>
            <Transition appear show={createdSuccessfully} as={Fragment}>
              <Dialog
                as="div"
                className="relative z-50"
                onClose={() => setCreatedSuccessfully(false)}
              >
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="fixed inset-0 bg-black/25" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                  <div className="flex min-h-full items-center justify-center p-4 text-center">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-out duration-300"
                      enterFrom="opacity-0 scale-95"
                      enterTo="opacity-100 scale-100"
                      leave="ease-in duration-200"
                      leaveFrom="opacity-100 scale-100"
                      leaveTo="opacity-0 scale-95"
                    >
                      <Dialog.Panel className="w-full max-w-xl transform overflow-hidden rounded-xl bg-white text-left align-middle shadow-xl transition-all">
                        <div className="scrollbar-thumb-[#1a3517] scrollbar-track-[#265426] h-full max-h-[525px] overflow-y-scroll p-2 scrollbar-thin">
                          <div
                            onClick={() =>
                              setCreatedSuccessfully(!createdSuccessfully)
                            }
                            className="fixed right-4 top-2 w-fit cursor-pointer rounded-full bg-[#558223] p-1.5 text-white"
                          >
                            <IoClose className="h-5 w-5" />
                          </div>
                          {/* <CreatedNewGropoolSuccessfullyPopup /> */}
                          <DeadlineApproachingPopup />
                        </div>
                      </Dialog.Panel>
                    </Transition.Child>
                  </div>
                </div>
              </Dialog>
            </Transition>
          </div>
        </Container>
      </div>
    </>
  );
};

export default GropoolDetailPage;
