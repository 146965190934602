import React, { useContext, useEffect, useState } from "react";
import { HiHome } from "react-icons/hi";
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";
import { BiPlus, BiMinus } from "react-icons/bi";
import { Popover, Transition } from "@headlessui/react";
import { Fragment } from "react";
import BasketRecipePopover from "./BasketRecipePopOver";
import { useNavigate } from "react-router-dom";
import RxjsStoreContext from "../../../context/RxjsStoreContext";
import CurrencyFormat from "react-currency-format";
import Modal from "../../Ui";
import {
  fetchGrocedyForAll,
  getProducts,
  verifyCoupon,
} from "../../../store/actions";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import {
  setSubscriptionComment,
  setTotalBasketAmount,
} from "../../../store/slices/modalSlice";
import { authService } from "../../../services/authService";

function GiftMyBasketList() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { coupon } = useSelector((state) => state.cart);
  const { grocedyForAll } = useSelector((state) => state.masterData);
  const [count, setCount] = useState(3);
  const corporateId = authService.getCustomerCorporateId();
  const customerId = authService.getCurrentUserCustomerId();
  const [fetchedCouponCode, setFetchedCouponCode] = useState(0);
  const rxjsStore = useContext(RxjsStoreContext);
  const [basketProductMap, setBasketProductMap] = useState(new Map());
  const [showModal, setShowModal] = useState(false);
  const [couponCode, setCouponCode] = useState("");
  const [comment, setComment] = useState("");
  const [allProductList, setAllProductList] = useState([]);
  const [deliveryFees, setDeliveryFees] = useState(0);
  const [fetchedDeliveryCharge, setFetchedDeliveryCharge] = useState("");
  const [totalDeliveryFee, setTotalDeliveryFee] = useState(0);
  const [selectedModal, setSelectedModal] = useState("");

  const calcuTotalDelivery = (qty) => {
    setTotalDeliveryFee(qty * deliveryFees);
  };

  useEffect(() => {
    if (!grocedyForAll) {
      dispatch(fetchGrocedyForAll(customerId ? customerId : "0"))
        .unwrap()
        .catch((err) => {
          return err;
        });
    }

    if (grocedyForAll && grocedyForAll?.delivery_charge?.length) {
      setFetchedDeliveryCharge(grocedyForAll?.delivery_charge[0]);
    }
  }, [grocedyForAll, navigate, customerId]);

  useEffect(() => {
    rxjsStore.getAllBasketProductList().subscribe((allProduct) => {
      setAllProductList(allProduct);
    });
  }, []);

  useEffect(() => {
    rxjsStore.getUserSelectedBasket().subscribe((data) => {
      let productSelected = new Map(data);
      setBasketProductMap(productSelected);
    });

    return () => {
      updateBasketList();
    };
  }, []);

  useEffect(() => {
    if (basketProductMap?.size <= 0) {
      setDeliveryFees(0);
      setTotalDeliveryFee(0);
    }

    if (fetchedDeliveryCharge && basketProductMap?.size > 0) {
      setDeliveryFees(
        fetchedDeliveryCharge?.item_rate * basketProductMap?.size
      );
      setTotalDeliveryFee(
        fetchedDeliveryCharge?.item_rate * basketProductMap?.size
      );
    }
  }, [dispatch, basketProductMap?.size, fetchedDeliveryCharge]);

  // Fetch the coupon code
  useEffect(() => {
    if (coupon) {
      if (coupon?.Result && coupon?.discount_by === "percentage") {
        const calcCoupon =
          (+coupon?.discount_value / 100) * (subAddonsTotal() + subTotal());
        setFetchedCouponCode(calcCoupon);
      }

      if (coupon?.Result && coupon?.discount_by === "flat") {
        setFetchedCouponCode(+coupon?.discount_value);
      }

      if (!coupon?.Result) {
        setFetchedCouponCode(0);
      }
    }
  }, [coupon, setFetchedCouponCode]);

  const updateBasketList = () => {
    for (let [key, product] of basketProductMap) {
      calcuTotalDelivery(product.qty);
      rxjsStore.setUserSelectedBasket(product, product.qty);
    }
  };

  function removeQuantity(key) {
    basketProductMap.delete(key);
    rxjsStore.removeItemFromBasket(key);
    setCount((prev) => (prev += 1));

    if (coupon) {
      if (coupon?.discount_by === "percentage") {
        const calcCoupon =
          (+coupon?.discount_value / 100) * (subAddonsTotal() + subTotal());
        setFetchedCouponCode(calcCoupon);
      }
    }
  }

  function incrementQuantity(key, qty) {
    rxjsStore.incrementQuantity(key, qty);
    // setCount(prev => prev += 1);
  }

  function decrementProduct(key, qty) {
    rxjsStore.decrementQuantity(key, qty);
  }

  function displayProduct() {
    const comps = [];
    basketProductMap.forEach((product, key) =>
      comps.push(
        <>
          <div
            key={product?.item_id}
            className="flex justify-between font-semibold sm:grid sm:grid-cols-4 sm:items-center"
          >
            <div className="mt-4 grid justify-start sm:col-span-2">
              <div className="flex items-center space-x-2">
                <div className="rounded-md border-[1px] border-gray-300">
                  <img
                    src={product?.image_id}
                    className="aspect-auto h-9 w-9 rounded-md object-cover sm:h-12 sm:w-12"
                    alt=""
                  />
                </div>
                <div className="-space-y-[6px] text-[9px] font-semibold text-gray-500 sm:-space-y-1 sm:text-[10px]">
                  <div>{product?.name} </div>
                  <div className="flex items-center space-x-1">
                    <p>Grocedy Price:</p>
                    <p>₦{Number.parseFloat(product?.rate).toFixed(2)} </p>
                  </div>
                  {/* <div className="flex items-center space-x-1 text-red-400">
                    <p>Market Price:</p>
                    <p className="line-through">
                      ₦{getMarketPrice(product?.sku, product?.qty)}
                    </p>
                  </div> */}
                </div>
              </div>
            </div>
            <div className="mt-5 xsm:mt-4 xsm:flex xsm:items-center xsm:space-x-2 sm:col-span-1 sm:grid sm:justify-center">
              <div className="grid h-6 w-20 grid-cols-3 rounded border-[0.5px] border-gray-400 sm:w-24">
                <div className="col-span-1 grid items-center justify-center">
                  <BiMinus
                    o
                    onClick={() => {
                      decrementProduct(product?.sku, 1);
                      calcuTotalDelivery(product?.qty);
                    }}
                    className="h-4 w-4 cursor-pointer rounded-full text-gray-500 hover:bg-gray-100 active:bg-gray-200"
                  />
                </div>

                <div className="col-span-1 grid items-center justify-center text-gray-600">
                  {product?.qty}
                </div>
                <div className="col-span-1 grid items-center justify-center">
                  <BiPlus
                    onClick={() => {
                      incrementQuantity(product?.sku, 1);
                      calcuTotalDelivery(product?.qty);
                    }}
                    className="h-4 w-4 cursor-pointer rounded-full text-gray-500 hover:bg-gray-100 active:bg-gray-200"
                  />
                </div>
              </div>
              <div
                className="mt-[1px] grid cursor-pointer justify-center text-[10px]
                     text-red-400 hover:underline hover:underline-offset-1 xsm:mt-0 sm:mt-[1px]"
                onClick={() => removeQuantity(product?.sku)}
              >
                REMOVE
              </div>
            </div>
            <div className="mt-2 hidden sm:col-span-1 sm:grid sm:justify-end">
              <div className="text-xs font-medium text-gray-600">
                ₦
                {`${Number.parseFloat(
                  Number(product?.rate) * product?.qty
                ).toLocaleString()}`}
              </div>
            </div>
          </div>
        </>
      )
    );
    return comps;
  }

  const subAddonsTotal = () => {
    let total = 0;
    for (let [key, product] of basketProductMap) {
      const totalAddonPrice =
        product?.JSON_String?.addons?.length &&
        product?.JSON_String?.addons?.map(
          (addOn) => addOn?.price_brackets?.totalPrice
        );

      // calcuTotalDelivery(product.qty);
      const merged = [].concat.apply([], totalAddonPrice);

      const subtotalAddonPrice =
        merged && merged?.length && merged?.reduce((a, b) => a + b);
      total += subtotalAddonPrice;
    }

    return total;
  };

  const subTotal = () => {
    let total = 0;
    for (let [key, product] of basketProductMap) {
      total += Number(product?.rate) * product?.qty;
    }

    return total;
  };

  // Coupon verification
  const handleCouponVerify = (e) => {
    e.preventDefault();
    const toastId = toast.loading("Verifying Coupon");

    try {
      const data = {
        coupon_code: couponCode,
        product_id: "1",
      };

      dispatch(verifyCoupon(data))
        .unwrap()
        .then((res) => {
          if (res?.Result === false) {
            return toast.error("Coupon Could not be verified!", {
              id: toastId,
            });
          }
          setCouponCode("");
          toast.success("Coupon Verified!", { id: toastId });
        })
        .catch((err) => {
          toast.error("Coupon Could not be verified!", { id: toastId });

          return err;
        });
    } catch (e) {
      return e;
    }
  };

  const handleOnClose = () => {
    setShowModal(false);
    setSelectedModal("");
  };

  const handleTotalSaving = () => {
    let totalAmount = 0;

    if ((subAddonsTotal() || subTotal()) && coupon && fetchedCouponCode > 0) {
      totalAmount +=
        subAddonsTotal() + subTotal() - fetchedCouponCode + deliveryFees;

      return dispatch(setTotalBasketAmount(totalAmount));
    }

    totalAmount += subAddonsTotal() + subTotal() + deliveryFees;
    return dispatch(setTotalBasketAmount(totalAmount));
  };

  const handleRedirect = (location) => {
    setShowModal(true);

    switch (location) {
      case "profileSaved":
        handleTotalSaving();
        dispatch(setSubscriptionComment(comment));
        setSelectedModal("profileSaved");
        break;
      case "recurr":
        setSelectedModal("recurr");
        break;
      default:
        return;
    }
  };

  return (
    <div className=" px-3 pb-3 pt-8 xsm:px-4 sm:px-8 md:px-10 lg:px-16">
      <div className="flex items-center space-x-3 text-xs font-semibold text-gray-500">
        <div className="flex items-center space-x-1">
          <div>
            <HiHome
              onClick={() => navigate("/")}
              className="cursor h-3.5 w-3.5"
            />
          </div>
          <div>
            <IoIosArrowForward className="h-[11px] w-[11px]" />
          </div>
        </div>

        <div className="flex items-center space-x-1">
          <div onClick={() => navigate(-1)}>select product(s)</div>
          <div>
            <IoIosArrowForward className="cursor h-[11px] w-[11px]" />
          </div>
        </div>
        <div className="flex items-center space-x-1 text-olivine-500">
          <div>Basket list</div>
          <div>
            <IoIosArrowForward className="h-[11px] w-[11px]" />
          </div>
        </div>
      </div>
      <div>
        <div className="flex cursor-pointer items-center space-x-2 pt-4 text-sm font-semibold text-olivine-500">
          <div>
            <IoIosArrowBack className="h-4 w-4" />
          </div>
          <div onClick={() => navigate(-1)} className="cursor">
            Continue Shopping
          </div>
        </div>
      </div>
      <div className="flex items-center justify-center">
        <div className="w-full max-w-3xl pb-16">
          <div className="mt-6 text-xl font-semibold text-gray-600">
            My Basket
          </div>
          <div className="mt-4 text-sm">
            <div className="hidden grid-cols-4 font-semibold text-gray-500 sm:grid">
              <div className="col-span-2 grid justify-start">PRODUCT</div>
              <div className="col-span-1 grid justify-center">QUANTITY</div>
              <div className="col-span-1 grid justify-end">TOTAL</div>
            </div>
            <hr className="mt-[2px] h-[2px] bg-gray-400" />

            {displayProduct()}

            <div className="mt-8">
              <label className="pl-3 text-xs font-semibold text-gray-600">
                Additional Comments
              </label>
              <textarea
                placeholder="Write your message..."
                rows="4"
                value={comment}
                onChange={(e) => {
                  const limit = 50;

                  // 👇️ only take first N characters
                  setComment(e.target.value.slice(0, limit));
                }}
                className="w-full rounded-md border-gray-300 bg-[#F0F2E8] text-sm font-semibold text-gray-500 placeholder:text-sm placeholder:text-gray-400 focus:border-olivine-500 focus:ring-olivine-500"
              ></textarea>
            </div>
            <div>
              <div className="mt-4 grid justify-end">
                <form className="">
                  <label className="text-sm text-gray-500 opacity-75">
                    Coupon
                  </label>
                  <div className="mt-1 flex justify-between space-x-2">
                    <input
                      onChange={(e) => setCouponCode(e.target.value)}
                      value={couponCode}
                      type="text"
                      placeholder="Enter coupon code"
                      className="border-1 flex-1 rounded-md border-olivine-300 text-xs font-semibold text-gray-500 placeholder:text-sm placeholder:text-gray-300 focus:border-white focus:ring-olivine-500 xsm:text-sm"
                    />
                    <button
                      onClick={handleCouponVerify}
                      className="rounded-md border-[1px] border-olivine-500 bg-white px-2 py-2 text-xs font-medium text-olivine-500 hover:bg-olivine-100 xsm:px-4 xsm:text-sm sm:px-6"
                    >
                      APPLY
                    </button>
                  </div>
                  <div className="flex flex-col text-right">
                    <div className="mt-4 pr-3 text-xs tracking-wider text-gray-600">
                      Plans Subtotal:
                      <CurrencyFormat
                        className={"font-medium"}
                        value={Number.parseFloat(subTotal())?.toFixed(2)}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"₦"}
                      />
                    </div>
                    <div className="mt-1 pr-3 text-xs tracking-wider text-gray-600">
                      Addons Subtotal:
                      <CurrencyFormat
                        className={"font-medium"}
                        value={Number.parseFloat(subAddonsTotal())?.toFixed(2)}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"₦"}
                      />
                    </div>
                    <div className="mt-1 pr-3 text-xs tracking-wider text-gray-600">
                      Subtotal:
                      <CurrencyFormat
                        className={"font-medium"}
                        value={Number.parseFloat(
                          subTotal() + subAddonsTotal()
                        )?.toFixed(2)}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"₦"}
                      />
                    </div>
                    <div className="mt-1 pr-3 text-xs tracking-wider text-gray-600">
                      Delivery Fees:
                      <CurrencyFormat
                        className={"font-medium"}
                        value={Number.parseFloat(
                          totalDeliveryFee ? totalDeliveryFee : deliveryFees
                        )?.toFixed(2)}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"₦"}
                      />
                    </div>
                    <div className="mt-1 pr-3 text-xs tracking-wider text-gray-600">
                      Coupon: -
                      {fetchedCouponCode > 0 ? (
                        <CurrencyFormat
                          className={"font-medium text-rose-600"}
                          value={Number.parseFloat(fetchedCouponCode)?.toFixed(
                            2
                          )}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"₦"}
                        />
                      ) : (
                        <CurrencyFormat
                          className={"font-medium text-rose-600"}
                          value={Number.parseFloat(0)?.toFixed(2)}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"₦"}
                        />
                      )}
                    </div>
                    <div className="mt-2 pr-3 text-sm font-bold tracking-wider text-gray-600">
                      TOTAL:
                      {subAddonsTotal() || subTotal() ? (
                        fetchedCouponCode > 0 ? (
                          <CurrencyFormat
                            className={"font-bold "}
                            value={Number.parseFloat(
                              subAddonsTotal() +
                                subTotal() -
                                fetchedCouponCode +
                                totalDeliveryFee
                                ? totalDeliveryFee
                                : deliveryFees
                            )?.toFixed(2)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"₦"}
                          />
                        ) : totalDeliveryFee ? (
                          <CurrencyFormat
                            className={"font-bold "}
                            value={Number.parseFloat(
                              subAddonsTotal() + subTotal() + totalDeliveryFee
                            )?.toFixed(2)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"₦"}
                          />
                        ) : (
                          <CurrencyFormat
                            className={"font-bold "}
                            value={Number.parseFloat(
                              subAddonsTotal() + subTotal() + deliveryFees
                            )?.toFixed(2)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"₦"}
                          />
                        )
                      ) : (
                        <CurrencyFormat
                          className={"font-bold "}
                          value={Number.parseFloat(0)?.toFixed(2)}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"₦"}
                        />
                      )}
                      {/* <CurrencyFormat
                            className={"font-bold "}
                            value={Number.parseFloat(total())?.toFixed(2)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"₦"}
                          /> */}
                    </div>
                    <div className="mt-1 pr-3 text-[9px] font-semibold tracking-wider text-gray-500">
                      *Shipping and to be calculated at checkout
                    </div>
                    <div className="">
                      <Popover>
                        {({ open }) => (
                          <>
                            <Popover.Button
                              className={`
                                                                ${
                                                                  open
                                                                    ? ""
                                                                    : "text-opacity-90"
                                                                }
                                                                w-full focus:text-olivine-700 focus:outline-none lg:w-auto`}
                            >
                              <div className="mt-[1px] rounded-md bg-olivine-500 px-10 py-3 text-sm font-semibold tracking-wider text-white transition duration-300 hover:bg-olivine-600">
                                <div>PROCEED TO CHECKOUT</div>
                              </div>
                            </Popover.Button>
                            <Popover.Overlay className="fixed inset-0 bg-black opacity-30" />
                            <Transition
                              as={Fragment}
                              enter="transition ease-out duration-200"
                              enterFrom="opacity-0 translate-y-1"
                              enterTo="opacity-100 translate-y-0"
                              leave="transition ease-in duration-150"
                              leaveFrom="opacity-100 translate-y-0"
                              leaveTo="opacity-0 translate-y-1"
                            >
                              <Popover.Panel className="absolute inset-x-0 top-28 z-30 mx-auto max-w-md px-2 xsm:px-4">
                                {({ close }) => (
                                  <div>
                                    <BasketRecipePopover
                                      closer={close}
                                      handleRedirect={handleRedirect}
                                      totalDeliveryFee={totalDeliveryFee}
                                    />
                                  </div>
                                )}
                              </Popover.Panel>
                            </Transition>
                          </>
                        )}
                      </Popover>
                    </div>
                  </div>
                </form>
              </div>

              <div></div>
              <div></div>
            </div>
            <div></div>

            <Modal
              visible={showModal}
              onClose={handleOnClose}
              selected={selectedModal}
              // switcher={switchToMonthly}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default GiftMyBasketList;
