import React, { useState } from "react";
import GropoolNoteImage from "../../../../assets/gropoolNoteImage.svg";
import { FaMinus, FaPlus } from "react-icons/fa";
import { RadioGroup } from "@headlessui/react";

const SelectSlotPurchaseOptions = () => {
  const [count, setCount] = useState(1);
  const [purchasingFor, setPurchasingFor] = useState("");
  return (
    <div className="mb-6 px-2 sm:px-6">
      <h2 className="mt-6 text-xl font-semibold text-gray-700">
        Select Slot Purchase Options
      </h2>
      <div className="mt-6 rounded-lg border-[1px] border-[#DFE5D1] bg-[#F9F9F9] px-4 py-8 shadow-xl shadow-[#F6F6F5]">
        <div className="flex flex-row items-center space-x-4">
          <div className="flex flex-row items-center">
            <button
              onClick={() => setCount((prev) => prev - 1)}
              className="rounded-md border-[1px] border-gray-200 bg-[#E8EFE1] p-2 hover:bg-gray-200 active:bg-[#E8EFE1]"
            >
              <FaMinus className="h-3 w-3 text-gray-600" />
            </button>
            <div className="px-2 text-sm font-medium text-gray-700">
              {count} Slot
            </div>
            <button
              onClick={() => setCount((prev) => prev + 1)}
              className="rounded-md border-[1px] border-gray-200 bg-[#E8EFE1] p-2 hover:bg-gray-200 active:bg-[#E8EFE1]"
            >
              <FaPlus className="h-3 w-3 text-gray-600" />
            </button>
          </div>
          <div className="rounded-md bg-[#EFEDED] px-4 py-1 text-base font-bold text-gray-800">
            N100,000.00
          </div>
        </div>

        <div className="mt-5 @container">
          <RadioGroup value={purchasingFor} onChange={setPurchasingFor}>
            <RadioGroup.Label className="text-sm font-semibold text-gray-700">
              Who are you purchasing this slot for?
            </RadioGroup.Label>
            <div className=" mt-2 flex flex-col @sm:flex-row @sm:items-center">
              <RadioGroup.Option value="myself">
                {({ checked }) => (
                  <p
                    className={`mr-1 w-fit cursor-pointer rounded-full px-3 py-1.5 text-xs  ${
                      checked
                        ? "bg-[#558223] text-white"
                        : "bg-[#E5E3E3] text-gray-600"
                    }`}
                  >
                    I am Purchasing for myself
                  </p>
                )}
              </RadioGroup.Option>
              <RadioGroup.Option
                value="gift"
                className="mt-2 rounded-full px-2 text-xs @sm:mt-0"
              >
                {({ checked }) => (
                  <p
                    className={`w-fit cursor-pointer rounded-full px-3 py-1.5 text-xs  ${
                      checked
                        ? "bg-[#558223] text-white"
                        : "bg-[#E5E3E3] text-gray-600"
                    }`}
                  >
                    I am Purchasing as a Gift
                  </p>
                )}
              </RadioGroup.Option>
            </div>
          </RadioGroup>
        </div>
      </div>
      <div className="mt-8 flex items-center justify-between">
        <button className="w-fit transform cursor-pointer rounded-md border-[1px] border-gray-300 bg-white px-10 py-3 text-sm font-semibold text-gray-600 transition-all duration-200 focus:outline-none">
          Cancel
        </button>
        <button className="w-fit transform cursor-pointer rounded-md bg-[#558223] px-10 py-3 text-sm font-semibold text-white transition-all duration-200 hover:bg-olivine-500/80 focus:outline-none active:bg-olivine-600">
          Proceed
        </button>
      </div>
    </div>
  );
};

export default SelectSlotPurchaseOptions;
