import React from "react";
import GropoolFruitImage from "../../../../assets/gropoolFruitImage.png";

const RecommendedSlotsComponent = () => {
  return (
    <div
      className={`min-w-80 w-full cursor-pointer rounded-xl border-[1px] border-gray-200 bg-[#D5EBBB] p-4 hover:border-gray-300`}
    >
      <div className="flex h-6 w-[85px] items-center justify-center rounded-full bg-[#EDEEEC]">
        <p className="text-[11px] font-medium text-gray-500">12 November</p>
      </div>
      <div className="mt-2">
        <img
          src={GropoolFruitImage}
          alt="img"
          className="aspect-auto h-20 w-20 object-contain"
        />
      </div>
      <p className="mt-2 text-lg font-medium text-gray-600">Fruits Sharing</p>
      <div className="mt-1 flex flex-row items-center space-x-1">
        <p className="text-base font-bold text-gray-700">
          N12,000<span className="text-xs font-medium">/slot</span>
        </p>
        <p className="w-fit rounded-full bg-[#9BAA88] px-2 py-1 text-xs font-normal text-white">
          12 Slots Left
        </p>
      </div>
    </div>
  );
};

export default RecommendedSlotsComponent;
