import baseAxios from "../core/api/axios/baseAxios";

class ProductSharingService {
  static async howItWorks() {
    const res = await baseAxios.get("product-sharing/how-it-works");

    return res?.data || res;
  }

  static async faq() {
    const res = await baseAxios.get("product-sharing/faq");

    return res?.data || res;
  }
}

export default ProductSharingService;
